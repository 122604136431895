import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import DOMPurify from 'dompurify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core';
import Loader from '../../common/Loader';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CommonStyles from '../../../utils/CommonStyles';
import { ApiGet } from '../../../Api';

const styles = theme => ({
  ...CommonStyles(theme),
  dividerStyle: {
    margin: 10,
  },
});

function WidgetInfo({ classes, onClose, widgetId }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const getWidgetInfo = () => {
    setLoading(true);
    ApiGet(`/api/services/widgets/${widgetId}`).then(response => {
      if (response) {
        setData({
          name: response.name,
          url: response.url,
          shortDescription: response.description,
          longDescription: response.long_description
            ? response.long_description
            : 'Widget documentation is not available. Please contact your administrator.',
          fieldsReturned: response.config.columns.returning
            ? response.config.columns.returning
            : [],
          lastDocumentationUpdate: response.last_documentation_update,
          dataAvailable: true,
        });
      } else {
        setData({
          dataAvailable: false,
        });
      }
      setLoading(false);
    });
  };

  useEffect(getWidgetInfo, [widgetId]);

  return (
    <div>
      <Dialog aria-labelledby="customized-dialog-title" open onClose={onClose}>
        {loading ? (
          <Loader />
        ) : (
          <div>
            {data.dataAvailable ? (
              <div>
                <DialogTitle id="customized-dialog-title" onClose={onClose}>
                  {data.name}
                </DialogTitle>
                <DialogContent dividers>
                  <Typography variant="h6">Link:</Typography>
                  <Typography>
                    <a href={data.url}>{data.url}</a>
                  </Typography>
                  <Divider className={classes.dividerStyle} />
                  <Typography variant="h6">Description:</Typography>
                  <Typography>{data.shortDescription}</Typography>
                  <Divider className={classes.dividerStyle} />
                  <Typography variant="h6">Long Description:</Typography>
                  <Typography>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data.longDescription),
                      }}
                    />
                  </Typography>
                  <Divider className={classes.dividerStyle} />
                  <Typography variant="h6">Fields Returned:</Typography>
                  <Typography>
                    {data.fieldsReturned.map((item, index) => (
                      <div key={index}>{item}</div>
                    ))}
                  </Typography>
                  <Divider className={classes.dividerStyle} />
                  <Typography variant="h6">
                    Last Documentation Update:
                  </Typography>
                  <Typography>
                    {Moment(data.lastDocumentationUpdate).format('MM/DD/YYYY')}
                  </Typography>
                </DialogContent>
              </div>
            ) : (
              <DialogContent dividers>
                <Typography>
                  Documentation page is not available. Please contact your
                  administrator.
                </Typography>
              </DialogContent>
            )}
          </div>
        )}
        <DialogActions>
          <Button autoFocus onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withRouter(withStyles(styles)(WidgetInfo));

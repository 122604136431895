import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import DOMPurify from 'dompurify';
import moment from 'moment-timezone';
import {
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinkIcon from '@material-ui/icons/Link';
import {
  Check,
  Code,
  ErrorOutline,
  FirstPage,
  NavigateBefore,
  NavigateNext,
} from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import CommonStyles from '../../utils/CommonStyles';
import Loader from '../common/Loader';
import Statistics from './Statistics';
import CheckboxList from '../common/CheckboxList';
import HighlightedTextField from './HighlightedTextField';
import { ApiPost } from '../../Api';
import ExportDataSearch from './ExportDataSearch';
import LANGUAGES from './Languages';
import COUNTRIES from './Countries';

const PAGINATION = 50;

const styles = theme => ({
  ...CommonStyles(theme),
  root: {
    ...CommonStyles(theme).root,
    display: 'flex',
    padding: '20px',
  },
  container: {
    height: '100%',
  },
  leftSide: {
    width: '25%',
    height: 'calc(100vh - 117px)',
    padding: '1em',
    marginRight: '16px',
  },
  leftSideWrapper: {
    height: '100%',
  },
  rightSide: {
    width: 'calc(75% - 16px)',
  },
  results: {
    height: '100%',
  },
  resultsHeader: {
    marginBottom: '20px',
    height: '80px',
    display: 'flex',
    padding: '10px 2em',
    background: '#fff',
  },
  resultsContainer: {
    height: 'calc(100vh - 117px)',
    width: '100%',
    background: '#fff',
  },
  resultsList: {
    width: '25%',
    padding: '1em',
    height: '100%',
  },
  resultsListWild: {
    width: '100%',
  },
  articleContainer: {
    width: '75%',
    padding: '1em',
    height: '100%',
  },
  articleContainerShort: {
    width: 0,
    padding: 0,
  },
  button: {
    margin: '0.75em 0',
  },
  linkButton: {
    margin: '0.75em 0',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  linkTitle: {
    display: 'flex',
    alignItems: 'center',
    color: '#000',
  },
  datesLabel: {
    margin: '1.5em 0 1em 0',
  },
  successMessage: {
    display: 'block',
    verticalAlign: 'middle',
  },
  linkIcon: {
    position: 'relative',
    marginRight: '5px',
    transform: 'rotate(45deg)',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  successIcon: {
    marginRight: '5px',
    color: theme.palette.info.main,
  },
  errorIcon: {
    marginRight: '5px',
    color: theme.palette.primary.main,
  },
  icon: {
    marginRight: '5px',
    color: theme.palette.neutral.text,
  },
  validationMessage: {
    padding: '20px 14px',
    display: 'flex',
    alignItems: 'center',
  },
  articleCard: {
    width: '100%',
    cursor: 'pointer',
    height: '100%',
  },
  pageSizeInfo: {
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingRight: '7%',
  },
  articleTitle: {
    fontSize: '1.1em',
    fontWeight: '600',
    lineHeight: '1.1em',
    display: 'flex',
    height: '2.2em',
    overflow: 'hidden',
  },
  articleDate: {
    fontWeight: '300',
  },
  listItem: {
    padding: '0 5px 8px 5px !important',
  },
  listItemActive: {
    '& > div': {
      position: 'relative',

      '&::before': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        content: '""',
        height: '2px',
        width: '100%',
        background: '#d32f2f',
      },
    },
  },
  articlesListContainer: {
    flex: '1',
    overflowY: 'auto',
    width: '100%',
  },
  articlesList: {
    height: '100%',
    overflowY: 'overlay',
    paddingRight: '20px',
  },
  articleShortContent: {
    height: '4.5em',
    lineHeight: '1.5em',
    overflow: 'hidden',
    display: 'flex',
    textAlign: 'justify',
  },
  article: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  articleDetails: {
    color: theme.palette.neutral.text,
  },
  articleHeaderItem: {
    width: '100%',
  },
  articleHeader: {
    paddingLeft: '1em',
  },
  articleContent: {
    height: '83%',
    whiteSpace: 'pre-line',
    marginTop: '0.5em',
    fontSize: '1.2em',
    lineHeight: '1.6em',
    textAlign: 'justify',
    overflowX: 'overlay',
  },
  articleCardTitle: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  cancelIcon: {
    position: 'absolute',
    top: '0',
    right: '0',
    cursor: 'pointer',
    fill: '#d32f2f',
  },
});

const SEARCH_FIELD = ['content', 'content_title', 'content_source_name'];

const initialFiltersState = {
  query: '',
  language: 'All',
  country: 'Global',
  date_from: moment().subtract(1, 'days').format('YYYY-MM-DD'),
  date_to: moment().format('YYYY-MM-DD'),
  whitelist: '',
  blacklist: '',
  search_field: 'content',
};

const DataSearch = ({ classes }) => {
  const location = useLocation();
  const queryString = require('qs');
  const [validationError, setValidationError] = useState('');
  const [results, setResults] = useState([]);
  const [resultsNumber, setResultsNumber] = useState(0);
  const [uniqueNumber, setUniqueNumber] = useState(0);
  const [currentArticle, setCurrentArticle] = useState(undefined);
  const [currentArticleIndex, setCurrentArticleIndex] = useState(-1);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [resultsTab, setResultsTab] = useState(0);
  const [loadingResults, setLoadingResults] = useState(true);
  const [statistics, setStatistics] = useState(null);
  const [filters, setFilters] = useState(initialFiltersState);
  const [projectId, setProjectId] = useState(undefined);
  const [newsCategory, setNewsCategory] = useState({
    selectAll: true,
    allOther: true,
    options: {
      Local: true,
      National: true,
      Trade: true,
      Consumer: true,
    },
  });
  const [newsRank, setNewsRank] = useState({
    selectAll: true,
    allOther: true,
    options: {
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
    },
  });
  const [newsGenre, setNewsGenre] = useState({
    selectAll: true,
    allOther: true,
    options: {
      General: true,
      Opinion: true,
      Blog: true,
      PressRelease: true,
    },
  });
  const history = useHistory();

  const nextPageRef = useRef();
  const prevPageRef = useRef();

  const handleFilterChange = e => {
    const { name, value } = e.target;

    setFilters(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleFilterAutocompleteChange = (
    e,
    value,
    name,
    defaultValue,
    reason
  ) => {
    if (reason === 'clear') {
      setFilters(prevState => {
        return {
          ...prevState,
          [name]: defaultValue,
        };
      });
    } else {
      setFilters(prevState => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };

  const fetchData = () => {
    ApiPost('/api/datasearch/elastic-search/', {
      ...filters,
      limit: PAGINATION,
      offset: page * PAGINATION,
      category: newsCategory,
      rank: newsRank,
      genre: newsGenre,
      date_from: filters.date_from + 'T00:00',
      date_to: filters.date_to + 'T00:00',
      whitelist: filters.whitelist ? filters.whitelist.split(/\r?\n/) : [],
      blacklist: filters.blacklist ? filters.blacklist.split(/\r?\n/) : [],
    }).then(res => {
      if (!res || !res.ok) {
        setValidationError(res ? res.error : 'Query error');
        setResults([]);
        setResultsNumber(0);
        setUniqueNumber(0);
        setCurrentArticleIndex(-1);
        setMaxPage(0);
        setLoading(false);
      } else {
        setResultsNumber(res.articles_count);
        setUniqueNumber(res.unique_count);
        setMaxPage(Math.floor(res.unique_count / PAGINATION));
        setResults(res.results);
        setCurrentArticleIndex(-1);
        setStatistics(res.statistics);
        setLoadingResults(false);
      }
    });
  };

  const exportToNewProject = () => {
    ApiPost('/api/projects/datasearch/', {
      ...filters,
      date_from: filters.date_from + 'T00:00',
      date_to: filters.date_to + 'T00:00',
      whitelist: filters.whitelist ? filters.whitelist.split(/\r?\n/) : [],
      blacklist: filters.blacklist ? filters.blacklist.split(/\r?\n/) : [],
      project_id: projectId,
    }).then(res => {
      history.push(
        `/project/${res.project_id}/project-workflow/${res.project_workflow_id}/`
      );
    });
  };

  useEffect(() => {
    filters.query && fetchData();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => setLoading(false), [results]);

  useEffect(() => {
    if (history.location.search) {
      let queryParamsObject = queryString.parse(
        history.location.search.substring(1)
      );

      setNewsRank(queryParamsObject.rank);
      delete queryParamsObject.rank;
      setNewsGenre(queryParamsObject.genre);
      delete queryParamsObject.genre;
      setNewsCategory(queryParamsObject.category);
      delete queryParamsObject.category;

      setFilters(queryParamsObject);
    }

    return function cleanup() {
      history.replace({ search: '' });
    };
  }, [history.location.pathname]); // eslint-disable-line

  useEffect(() => {
    if (location.state) {
      let passedData = location.state;
      setNewsGenre(passedData.genre);
      delete passedData.genre;
      setNewsCategory(passedData.category);
      delete passedData.category;
      setNewsRank(passedData.rank);
      delete passedData.rank;
      const tempFilters = {
        ...passedData,
        date_to: moment(passedData.date_to).format('YYYY-MM-DD'),
        date_from: moment(passedData.date_from).format('YYYY-MM-DD'),
      };
      setFilters(tempFilters);
    }
  }, []); // eslint-disable-line

  const search = () => {
    setValidationError('');
    setLoading(true);
    history.push(
      `?${queryString.stringify({
        ...filters,
        category: newsCategory,
        genre: newsGenre,
        rank: newsRank,
      })}`
    );
    if (page === 0 && filters.query) fetchData(page);
    else setPage(0);
  };

  const reset = () => {
    history.push('/data-search');
    setFilters(initialFiltersState);
    setValidationError('');
    setResults([]);
    setResultsNumber(0);
    setUniqueNumber(0);
    setCurrentArticleIndex(-1);
    setLoading(false);
    setMaxPage(0);
    setPage(0);
  };

  const changePage = newPage => {
    setCurrentArticleIndex(-1);
    setLoadingResults(true);
    setPage(newPage);
  };

  const statusMessage = useCallback(() => {
    if (results.length)
      return (
        <div className={classes.validationMessage}>
          <Check className={classes.successIcon} />
          <Typography variant="h6">Validation successful!</Typography>
        </div>
      );
    else if (validationError)
      return (
        <div className={classes.validationMessage}>
          <ErrorOutline className={classes.errorIcon} />
          <Typography variant="h6">{`Error - ${validationError}`}</Typography>
        </div>
      );
    else
      return (
        <div className={classes.validationMessage}>
          <Code className={classes.icon} />
          <Typography variant="h6">Run a query.</Typography>
        </div>
      ); // eslint-disable-next-line
  }, [results]);

  // Scroll to active element on the list
  //
  const scrollToActiveElementRef = useRef([]);

  const executeScroll = useCallback(
    idx => {
      currentArticle &&
        scrollToActiveElementRef.current.length > 0 &&
        scrollToActiveElementRef.current[idx] &&
        scrollToActiveElementRef.current[idx].scrollIntoView({
          behavior: 'smooth',
        });
    },
    [currentArticle, scrollToActiveElementRef]
  );

  // Keyboard handler
  //
  useEffect(() => {
    setCurrentArticle(results[currentArticleIndex]);
    currentArticleIndex >= 0 && executeScroll(currentArticleIndex);
  }, [results, currentArticleIndex, executeScroll]);

  const dataSearchKeyboardHandler = useCallback(
    e => {
      const maxIndex = results.length - 1;
      switch (e.key) {
        case 'Escape':
          setCurrentArticleIndex(-1);
          break;
        case 'j': // Next article in the list
          if (currentArticleIndex <= maxIndex) {
            setCurrentArticleIndex(currentArticleIndex + 1);
          }
          break;
        case 'f': // Previous article in list
          if (currentArticleIndex >= 0) {
            setCurrentArticleIndex(currentArticleIndex - 1);
          }
          break;
        case 'k': // Next 50 articles
          nextPageRef.current && nextPageRef.current.click();
          break;
        case 'd': // Prev 50 articles
          prevPageRef.current && prevPageRef.current.click();
          break;
        case 's': // Start of the article list
          setCurrentArticleIndex(0);
          // setCurrentArticle(results[0]);
          break;
        case 'l': // End of the article list
          setCurrentArticleIndex(maxIndex);
          // setCurrentArticle(results[maxIndex]);
          break;
        case 'v': // Visit the article url in pop up
          currentArticle &&
            currentArticle.content_url &&
            window.open(
              currentArticle.content_url,
              '_blank',
              'noopener,noreferrer'
            );
          break;
        default:
          break;
      }
    },
    [results, currentArticle, currentArticleIndex]
  );

  useEffect(() => {
    document.addEventListener('keydown', dataSearchKeyboardHandler);
    return () => {
      document.removeEventListener('keydown', dataSearchKeyboardHandler);
    };
  }, [dataSearchKeyboardHandler]);

  return (
    <Grid container className={classes.root} justifyContent="center">
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="space-around"
          className={classes.container}
        >
          <Grid item component={Paper} className={classes.leftSide}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              wrap="nowrap"
              className={classes.leftSideWrapper}
            >
              <Grid
                item
                style={{ height: '70vh', overflow: 'auto', padding: '1em' }}
              >
                <HighlightedTextField
                  setQuery={handleFilterChange}
                  label="Enter search query here"
                  value={filters.query}
                  name="query"
                />
                <Grid container justifyContent="space-between">
                  <Typography
                    style={{ width: '100%' }}
                    align="center"
                    classes={{ root: classes.datesLabel }}
                  >
                    Date range
                  </Typography>
                  <Grid item style={{ width: '45%' }}>
                    <TextField
                      className={'dataSearchDatePicker'}
                      label="From"
                      type="date"
                      name="date_from"
                      value={filters.date_from}
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={e => {
                        if (e.target.value) handleFilterChange(e);
                        else
                          handleFilterChange({
                            target: {
                              ...e.target,
                              value: moment()
                                .subtract(1, 'days')
                                .format('YYYY-MM-DD'),
                            },
                          });
                      }}
                      placeholder=""
                    />
                  </Grid>
                  <Grid item style={{ width: '45%' }}>
                    <TextField
                      className={'dataSearchDatePicker'}
                      label="To"
                      type="date"
                      value={filters.date_to}
                      variant="outlined"
                      name="date_to"
                      size="small"
                      fullWidth
                      onChange={e => {
                        if (e.target.value) handleFilterChange(e);
                        else
                          handleFilterChange({
                            target: {
                              ...e.target,
                              value: moment().format('YYYY-MM-DDThh:mm'),
                            },
                          });
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider style={{ margin: '10px' }} variant="middle" />
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <Autocomplete
                        options={LANGUAGES}
                        defaultValue="All"
                        value={filters.language}
                        getOptionLabel={option => option}
                        onChange={(e, newValue, reason) =>
                          handleFilterAutocompleteChange(
                            e,
                            newValue,
                            'language',
                            'All',
                            reason
                          )
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Language"
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <Autocomplete
                        options={COUNTRIES}
                        defaultValue="Global"
                        value={filters.country}
                        getOptionLabel={option => option}
                        onChange={(e, newValue, reason) =>
                          handleFilterAutocompleteChange(
                            e,
                            newValue,
                            'country',
                            'Global',
                            reason
                          )
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Country"
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider style={{ margin: '10px' }} variant="middle" />
                <Grid container justifyContent="space-between">
                  <Grid item style={{ width: '100%' }}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel id="content-label">Field</InputLabel>
                      <Select
                        label="content"
                        value={filters.search_field}
                        name="search_field"
                        onChange={handleFilterChange}
                      >
                        {SEARCH_FIELD.map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider style={{ margin: '10px' }} variant="middle" />
                <Grid container justifyContent="space-between">
                  <Grid item style={{ width: '100%' }}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <TextField
                        id="whitelist"
                        label="Whitelist"
                        multiline
                        rows={6}
                        variant="outlined"
                        name="whitelist"
                        onChange={handleFilterChange}
                        value={filters.whitelist}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider style={{ margin: '10px' }} variant="middle" />
                <Grid container justifyContent="space-between">
                  <Grid item style={{ width: '100%' }}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <TextField
                        id="blacklist"
                        label="Blacklist"
                        multiline
                        rows={6}
                        variant="outlined"
                        name="blacklist"
                        onChange={handleFilterChange}
                        value={filters.blacklist}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider style={{ margin: '10px' }} variant="middle" />
                <Grid item>
                  <CheckboxList
                    name="News Category"
                    checkboxObject={newsCategory}
                    onChange={setNewsCategory}
                  />
                  <Divider style={{ margin: '10px' }} variant="middle" />
                  <CheckboxList
                    name="News Rank"
                    checkboxObject={newsRank}
                    onChange={setNewsRank}
                  />
                  <Divider style={{ margin: '10px' }} variant="middle" />
                  <CheckboxList
                    name="News Genre"
                    checkboxObject={newsGenre}
                    onChange={setNewsGenre}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={search}
                  classes={{ root: classes.button }}
                  disabled={!filters.query}
                >
                  Search
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={reset}
                  classes={{ root: classes.button }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.rightSide}>
            <Grid container direction="column" className={classes.results}>
              <Grid item component={Paper} className={classes.resultsContainer}>
                <Grid container justifyContent="space-between">
                  {statusMessage()}
                  {results.length ? (
                    <ExportDataSearch
                      classes={{ root: classes.linkButton }}
                      setProjectId={setProjectId}
                      projectId={projectId}
                      exportToNewProject={exportToNewProject}
                    />
                  ) : null}
                </Grid>
                {loading ? (
                  <Grid container alignItems="center">
                    <Loader />
                  </Grid>
                ) : (
                  <>
                    {results.length ? (
                      <Grid
                        container
                        style={{
                          height: 'calc(100vh - 195px)',
                          overflowY: 'hidden',
                        }}
                      >
                        <Grid item>
                          <Tabs
                            value={resultsTab}
                            onChange={(event, newValue) =>
                              setResultsTab(newValue)
                            }
                          >
                            <Tab label="Results" />
                            <Tab label="Statistics" />
                          </Tabs>
                        </Grid>
                        {(() => {
                          let element = null;
                          switch (resultsTab) {
                            case 0:
                              element = (
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  style={{
                                    height: 'calc(100% - 42px)',
                                    overflowY: 'hidden',
                                    flexWrap: 'nowrap',
                                  }}
                                >
                                  <Grid
                                    item
                                    className={classNames(classes.resultsList, {
                                      [classes.resultsListWild]:
                                        !currentArticle,
                                    })}
                                  >
                                    <Grid
                                      container
                                      direction="column"
                                      style={{ height: '100%' }}
                                    >
                                      <Grid
                                        item
                                        className={classes.pageSizeInfo}
                                      >
                                        <Typography variant="caption">
                                          {`Showing ${page * PAGINATION} to ${
                                            (page + 1) * PAGINATION
                                          } of ${uniqueNumber} unique entries (${resultsNumber} total entries)`}
                                        </Typography>
                                      </Grid>
                                      <Grid item className={classes.pagination}>
                                        <Grid
                                          container
                                          style={{ width: '100%' }}
                                          justifyContent="space-evenly"
                                        >
                                          <IconButton
                                            aria-label="first-page"
                                            onClick={() => changePage(0)}
                                            disabled={page === 0}
                                          >
                                            <FirstPage />
                                          </IconButton>
                                          <IconButton
                                            aria-label="previous-page"
                                            onClick={() => changePage(page - 1)}
                                            disabled={page === 0}
                                            ref={prevPageRef}
                                          >
                                            <NavigateBefore />
                                          </IconButton>
                                          <IconButton
                                            aria-label="next-page"
                                            onClick={() => changePage(page + 1)}
                                            disabled={page === maxPage}
                                            ref={nextPageRef}
                                          >
                                            <NavigateNext />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                      {loadingResults ? (
                                        <Loader />
                                      ) : (
                                        <Grid
                                          item
                                          className={
                                            classes.articlesListContainer
                                          }
                                        >
                                          <List
                                            className={classes.articlesList}
                                          >
                                            {results.map((result, idx) => (
                                              <ListItem
                                                key={idx}
                                                ref={el =>
                                                  (scrollToActiveElementRef.current[
                                                    idx
                                                  ] = el)
                                                }
                                                className={classNames(
                                                  classes.listItem,
                                                  {
                                                    [classes.listItemActive]:
                                                      currentArticle &&
                                                      result.content_title ===
                                                        currentArticle.content_title,
                                                  }
                                                )}
                                              >
                                                <Card
                                                  className={
                                                    classes.articleCard
                                                  }
                                                  variant="outlined"
                                                  onClick={() =>
                                                    setCurrentArticleIndex(idx)
                                                  }
                                                >
                                                  <CardContent
                                                    style={{
                                                      padding: '1em 1.5em',
                                                    }}
                                                  >
                                                    <Grid
                                                      container
                                                      justifyContent="space-between"
                                                      style={{
                                                        marginBottom: '0.5em',
                                                      }}
                                                    >
                                                      <Grid item>
                                                        <span
                                                          className={
                                                            classes.articleTitle
                                                          }
                                                        >
                                                          <div
                                                            className="content"
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                DOMPurify.sanitize(
                                                                  result.content_title
                                                                ),
                                                            }}
                                                          />
                                                        </span>
                                                      </Grid>
                                                      <Grid item>
                                                        <span
                                                          className={
                                                            classes.articleDate
                                                          }
                                                        >
                                                          {moment(
                                                            result.content_publish_date
                                                          ).format(
                                                            'MM/DD/YYYY'
                                                          )}
                                                        </span>
                                                      </Grid>
                                                    </Grid>
                                                    <span
                                                      className={
                                                        classes.articleShortContent
                                                      }
                                                    >
                                                      <div
                                                        className="content"
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            DOMPurify.sanitize(
                                                              result.content
                                                            ),
                                                        }}
                                                      />
                                                    </span>
                                                  </CardContent>
                                                </Card>
                                              </ListItem>
                                            ))}
                                          </List>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    className={classNames(
                                      classes.articleContainer,
                                      {
                                        [classes.articleContainerShort]:
                                          !currentArticle,
                                      }
                                    )}
                                  >
                                    {currentArticle && (
                                      <Card
                                        variant="outlined"
                                        className={classes.article}
                                      >
                                        <CancelIcon
                                          size="20"
                                          className={classes.cancelIcon}
                                          onClick={() => {
                                            setCurrentArticleIndex(-1);
                                          }}
                                        />
                                        <CardContent
                                          style={{
                                            height: '100%',
                                            overflowY: 'hidden',
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction="column"
                                            style={{
                                              height: 'fit-content',
                                              marginBottom: '20px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              className={
                                                classes.articleHeaderItem
                                              }
                                            >
                                              <Typography
                                                variant="h5"
                                                gutterBottom
                                              >
                                                <Link
                                                  href={
                                                    currentArticle.content_url
                                                  }
                                                  target="_blank"
                                                  className={classes.linkTitle}
                                                >
                                                  <LinkIcon
                                                    size={15}
                                                    className={classes.linkIcon}
                                                  />
                                                  <div
                                                    className={
                                                      classes.articleCardTitle
                                                    }
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        DOMPurify.sanitize(
                                                          currentArticle.content_title
                                                        ),
                                                    }}
                                                  />
                                                </Link>
                                              </Typography>
                                              <div
                                                className={
                                                  classes.articleDetails
                                                }
                                              >
                                                {moment(
                                                  currentArticle.content_publish_date
                                                ).format('MM/DD/YYYY')}
                                              </div>
                                            </Grid>
                                            <Grid item>
                                              <Divider
                                                variant="middle"
                                                style={{ margin: '1em 0' }}
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              className={classes.articleHeader}
                                            >
                                              <div
                                                className={
                                                  classes.articleDetails
                                                }
                                              >
                                                ID: {currentArticle.content_id}
                                              </div>
                                              <div
                                                className={
                                                  classes.articleDetails
                                                }
                                              >
                                                Article publisher:{' '}
                                                {currentArticle.author_name}
                                              </div>
                                              <div
                                                className={
                                                  classes.articleDetails
                                                }
                                              >
                                                Language:{' '}
                                                {
                                                  currentArticle.content_language
                                                }
                                              </div>
                                              <div
                                                className={
                                                  classes.articleDetails
                                                }
                                              >
                                                Richness:{' '}
                                                {currentArticle.richness}
                                              </div>
                                            </Grid>
                                          </Grid>
                                          <Grid
                                            item
                                            className={classes.articleContent}
                                          >
                                            <div
                                              className="content"
                                              dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                  currentArticle.content
                                                ),
                                              }}
                                            />
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                    )}
                                  </Grid>
                                </Grid>
                              );
                              break;
                            case 1:
                              element = (
                                <Grid
                                  container
                                  style={{
                                    height: '93%',
                                    overflow: 'auto',
                                  }}
                                >
                                  <Statistics
                                    data={statistics}
                                    totalEntries={resultsNumber}
                                  />
                                </Grid>
                              );
                              break;
                            default:
                              element = <div>No results</div>;
                          }
                          return element;
                        })()}
                      </Grid>
                    ) : null}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(DataSearch);

export default function DynamicShape(color) {
  const svg = [];

  svg.push(
    '<svg width="1440px" height="1296px" viewBox="0 0 1440 1296" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">'
  );
  svg.push(
    '<g id="flow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">'
  );
  svg.push(
    '<g id="01-01-01-Playground-Copy" transform="translate(0.000000, -420.000000)">'
  );
  svg.push(
    '<g id="Background" transform="translate(0.000000, 520.000000)" fill="' +
      color +
      '" opacity="0.900000036">'
  );
  svg.push(
    '<polygon id="Rectangle" points="1.13686838e-12 335 1440 1.36424205e-12 1440 3588.96771 1.13686838e-12 3588.96771"></polygon>'
  );
  svg.push("</g>");
  svg.push("</g>");
  svg.push("</g>");
  svg.push("</svg>");

  return svg;
}

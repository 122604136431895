import React, { useState, useContext } from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import ProjectProperties from './ProjectProperties';
import CommonStyles from '../../../utils/CommonStyles';
import { ApiPost } from '../../../Api';
import { NotificationContext } from '../../../NotificationContext';

const styles = theme => ({
  ...CommonStyles(theme),
  ProjectForm: {
    width: '30%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  ProjectGroup: {
    width: '100%',
    flexDirection: 'row',
  },
  ProjectPropertiesContainer: {
    minWidth: '400px',
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  TopContainer: {
    marginBottom: '20px',
  },
});

function NewProject({ history, classes }) {
  const { notification } = useContext(NotificationContext);
  const [newProject, setNewProject] = useState({
    name: '',
    notes: '',
    project_groups: [],
    language: 'All',
  });

  const onSubmit = data => {
    ApiPost(`/api/projects/projects/`, data).then(res => {
      if (res) {
        notification({
          type: 'success',
          message: 'Successfully created new project',
        });
        history.push(`/project/${res.id}`);
        window.location.reload();
      } else {
        notification({
          type: 'error',
          message: 'There was some problem when creating new project',
        });
      }
    });
  };

  return (
    <Grid component={Paper} className={classes.ProjectContainer}>
      <Grid className={classes.ProjectPropertiesContainer}>
        <Typography className={classes.TopContainer} variant="h5">
          New Project {newProject.name}
        </Typography>
        <ProjectProperties
          project={newProject}
          setProject={setNewProject}
          onSubmit={onSubmit}
          isNewProject
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(NewProject);

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Paper, TableCell, TableRow } from '@material-ui/core';
import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { ApiGet } from '../../Api';
import CommonStyles from '../../utils/CommonStyles';
import ListTable from '../Project/Project/ListTable';
import Loader from '../common/Loader';

const styles = theme => ({
  ...CommonStyles(theme),
  actionLink: {
    color: theme.palette.primary.main,
  },
  table: {
    tableLayout: 'auto',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  sortable: {
    display: 'flex',
    alignItems: 'center',
  },
});

function Overview({ classes }) {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [textSort, setTextSort] = useState('asc');

  const firstLoad = useRef(true);

  const { al_session_id } = useParams();

  useEffect(() => {
    ApiGet(`/api/activelearning/session/${al_session_id}/actions/`).then(
      res => {
        setResults(res);
        setIsLoading(false);
        firstLoad.current = false;
      }
    );
  }, [al_session_id]);

  // eslint-disable-next-line
  const sortHandler = sortType => {
    let resTmp;
    if (!firstLoad.current && sortType === 'asc') {
      resTmp = results.sort(function (a, b) {
        if (a.action.text.toLowerCase() < b.action.text.toLowerCase())
          return -1;
        if (a.action.text.toLowerCase() > b.action.text.toLowerCase()) return 1;
        return 0;
      });
    } else if (!firstLoad.current && sortType === 'desc') {
      resTmp = results.sort(function (a, b) {
        if (b.action.text.toLowerCase() < a.action.text.toLowerCase())
          return -1;
        if (b.action.text.toLowerCase() > a.action.text.toLowerCase()) return 1;
        return 0;
      });
    }
    setResults([...resTmp]);
  };

  const sortColumn = useCallback(() => {
    textSort === 'asc' ? setTextSort('desc') : setTextSort('asc');
  }, [textSort]);

  useEffect(() => {
    !firstLoad.current && sortHandler(textSort);
    // eslint-disable-next-line
  }, [textSort]);

  const projectWorkflowTableRow = (row, classes) => {
    return (
      <>
        <TableRow hover key={row.id}>
          <TableCell scope="row" align="center">
            {row.id}
          </TableCell>
          <TableCell scope="row">{row.action.text}</TableCell>
          <TableCell scope="row" align="center">
            {row.action.label}
          </TableCell>
          <TableCell align="center">{row.action.confidence}</TableCell>
          <TableCell>{row.username}</TableCell>
        </TableRow>
      </>
    );
  };

  const projectWorkflowTableHeaders = () => {
    return (
      <TableRow>
        <TableCell align="center">Id</TableCell>
        <TableCell
          sortDirection={textSort}
          className={classes.sortable}
          onClick={sortColumn}
        >
          Text
          {!firstLoad.current && (
            <>
              {textSort === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </>
          )}
        </TableCell>
        <TableCell align="center">Label</TableCell>
        <TableCell align="center">Confidence</TableCell>
        <TableCell classes={{ head: classes.nowrap }}>Created by</TableCell>
      </TableRow>
    );
  };

  return (
    <Grid className={classes.ProjectContainer} component={Paper}>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          <ListTable
            classes={{ marginBot: classes.table }}
            data={results}
            title="Modal data overview"
            customTableRow={projectWorkflowTableRow}
            customTableHeaders={projectWorkflowTableHeaders}
            colSpan={6}
            pagination={50}
          />
        </div>
      )}
    </Grid>
  );
}

export default withStyles(styles)(Overview);

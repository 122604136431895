import DynamicShape from './DynamicShape';

let backgroundColor;
if (process.env.NODE_ENV === 'development') backgroundColor = '#e68e8e';
else {
  switch (process.env.REACT_APP_STAGE) {
    case 'staging':
    case 'staging-us-east-2':
      backgroundColor = '#e6ba8e';
      break;
    case 'testing':
      backgroundColor = '#a4e68e';
      break;
    case 'govcloud':
      backgroundColor = '#d2848e';
      break;
    case 'production':
      backgroundColor = '#84D0E4';
      break;
    default:
      backgroundColor = '#000000';
  }
}

const encodedSvg = window.btoa(DynamicShape(backgroundColor));
const backgroundUrl = 'data:image/svg+xml;base64,' + encodedSvg;

export default theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'auto',
    background: `url(${backgroundUrl}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    padding: '40px 0',

    '& > div': {
      zIndex: 1,
    },
  },
  paper: {
    padding: theme.spacing(3),
    paddingBottom: 10,
    textAlign: 'left',
    width: '100%',
    height: 'fit-content',
    color: theme.palette.text.secondary,
    flexBasis: '0',
  },
  tableRow: {
    textDecoration: 'none',
    color: 'black',
  },
  pagination: {
    borderBottom: 'none',
  },
  inputField: {
    margin: '4px 0',
    paddingRight: '10px',
    width: '100% !important',
  },
  marginBot: {
    marginBottom: '25px',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: theme.spacing(100),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '25px',
    paddingTop: '10px',
    outline: 'none',
    minWidth: '500px',

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      minWidth: '500px',
    },
  },
  ProjectContainer: {
    flex: '1',
    padding: '20px',
  },
});

const MenuRoutes = [
  {
    label: "Home",
    pathname: "/"
  },
  {
    label: "Projects",
    pathname: "/project"
  },
  {
    label: "Templates",
    pathname: "/templates"
  },
  {
    label: 'Data search',
    pathname: '/data-search',
  },
  {
    label: 'Data search history',
    pathname: '/data-search-history',
  },
  {
    label: "Profile",
    pathname: "/profile"
  },
  {
    label: "Alive",
    pathname: "/alive"
  },
];

export default MenuRoutes;
import React from 'react';
import { Inbox } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Typography } from '@material-ui/core';

const styles = theme => ({
  noDataWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  noData: {
    fontSize: '1.2em',
    color: theme.palette.neutral.medium,
  },
  emptyIcon: {
    color: theme.palette.neutral.medium,
  },
});

const NoData = ({ classes }) => (
  <Grid container justifyContent="center">
    <Grid item className={classes.noDataWrapper}>
      <Inbox className={classes.emptyIcon} fontSize="large" />
      <Typography component={'span'} className={classes.noData}>
        No data.
      </Typography>
    </Grid>
  </Grid>
);

export default withStyles(styles)(NoData);

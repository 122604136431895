import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactS3Uploader from 'react-s3-uploader';
import withStyles from '@material-ui/core/styles/withStyles';
import { CircularProgress, TextField } from '@material-ui/core';
import { ApiGet, API_URL } from '../../Api';
import CommonStyles from '../../utils/CommonStyles';

const styles = theme => ({
  ...CommonStyles(theme),
  cont: {
    marginTop: 0,
  },
  loader: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '25px',
    height: '25px',
    fontSize: '10px',

    '& >div': {
      position: 'absolute',
      left: 0,
      top: 0,
    },
  },
});

const WidgetInput = ({
  id = '',
  classes,
  format,
  toggleUploading,
  notification,
  value,
  onChange,
  onFinish,
  onFileUploadProgress,
  showS3FilePath = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);

  const onUploadStart = (file, next) => {
    setLoading(true);
    toggleUploading(true);
    return next(file);
  };

  const onUploadError = message => {
    notification({
      type: 'success',
      message: message,
    });
    setLoading(false);
    toggleUploading(false);
  };

  const onUploadFinish = (signResult, file) => {
    onChange(signResult.s3Url);
    try {
      onFinish({ urls: signResult, file: file });
    } catch (err) {}

    setLoading(false);
    toggleUploading(false);
  };

  const onProgress = value => {
    setProgress(value);
    try {
      onFileUploadProgress(value);
    } catch (err) {}
  };

  const getSignedUrl = (file, callback) => {
    const params = {
      objectName: file.name,
      contentType: file.type,
    };
    ApiGet('/s3-sign-in/', params)
      .then(data => callback(data))
      .catch(error => console.error(error));
  };

  const errorHandler = value => (value ? setError(false) : setError(true));

  const generateInput = format => {
    switch (format) {
      case 's3file':
        return (
          <>
            <ReactS3Uploader
              id={id}
              signingUrl="/s3-sign-in/"
              signingUrlMethod="GET"
              s3path="/workflows_upload/"
              preprocess={onUploadStart.bind(this)}
              onError={onUploadError.bind(this)}
              onFinish={onUploadFinish.bind(this)}
              signingUrlWithCredentials={false}
              getSignedUrl={getSignedUrl.bind(this)}
              onProgress={onProgress.bind(this)}
              uploadRequestHeaders={{}}
              contentDisposition="auto"
              scrubFilename={filename => filename.replace(/[^\w\d_\-.]+/gi, '')}
              server={API_URL}
              autoUpload={true}
              onBlur={() => errorHandler(value)}
            />
            <small>
              {loading && (
                <span className={classes.loader}>
                  <CircularProgress size={25} />
                  <span>{progress}</span>
                </span>
              )}
              {!loading && showS3FilePath && (
                <TextField
                  classes={{ root: classes.inputField }}
                  value={value}
                  onChange={onChange}
                  label="S3 file path *"
                  multiline={true}
                  onBlur={e => errorHandler(e.target.value)}
                  error={error}
                />
              )}
            </small>
          </>
        );
      case 'text':
      case 'lucene':
        return (
          <TextField
            classes={{ root: classes.inputField }}
            value={value || ''}
            label="Input"
            onChange={onChange}
            multiline={true}
          />
        );
      default:
        return null;
    }
  };

  return <>{generateInput(format)}</>;
};

export default withRouter(withStyles(styles)(WidgetInput));

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ProjectContextWrapper } from '../ProjectContext';
import CommonStyles from '../../../utils/CommonStyles';
import Dataset from '../../Dataset';
import DatasetRun from '../../DatasetRun';
import EditProject from './EditProject';
import NewProject from './NewProject';
import Overview from '../../ActiveLearning/Overview';
import ProjectDatasetList from './ProjectDatasetList';
import ProjectDetails from './ProjectDetails';
import ProjectFiles from './ProjectFiles';
import ProjectList from './ProjectList';
import ProjectMain from './ProjectMain';
import ProjectWorkflow from '../../Workflows/ProjectWorkflow';
import ProjectWorkflowList from '../../Workflows/ProjectWorkflowList';
import Session from '../../ActiveLearning/Session';
import Tagging from '../../ActiveLearning/Tagging';
import TrainingStats from '../../ActiveLearning/TrainingStats';

const styles = theme => ({
  ...CommonStyles(theme),
  root: {
    ...CommonStyles(theme).root,
    display: 'flex',
    padding: '20px',
  },
  newProjectLeftSide: {
    position: 'relative',
    padding: '10px 0 0',
    height: 'calc(100vh - 106px)',
    overflow: 'hidden',
  },
  newProjectLeftSideCollapse: {
    width: '20px',
    overflow: 'hidden',
    position: 'relative',
  },
  newProjectRightSide: {
    padding: '0 10px',
    paddingLeft: '16px',
    display: 'flex',
    flex: '1',
    overflow: 'hidden',
    overflowY: 'auto',
    background: 'transparent',
    boxShadow: 'none',
  },
});

function Project({ classes, match }) {
  return (
    <ProjectContextWrapper>
      <Grid container className={classes.root}>
        <Grid
          item
          id="NewProjectLeftSide"
          component={Paper}
          className={classes.newProjectLeftSide}
        >
          <Route path={`${match.path}`} component={ProjectList} />
        </Grid>
        <Grid item component={Paper} className={classes.newProjectRightSide}>
          <Switch>
            <Route path={`${match.path}/new`} component={NewProject} />
            <Route
              path={`${match.path}/dataset-run/:tableName`}
              component={DatasetRun}
            />
            <Route
              path={`${match.path}/:project_id/project-workflow/:project_workflow_id/dataset/:id`}
              component={Dataset}
            />
            <Route
              path={`${match.path}/:project_id/project-workflow/fork/:project_workflow_id`}
              component={ProjectWorkflow}
              fork={true}
            />
            <Route
              path={`${match.path}/:project_id/project-workflow/:project_workflow_id`}
              component={ProjectWorkflow}
              fork={false}
            />
            <Route
              path={`${match.path}/:project_id/al/:al_session_id/session`}
              component={Session}
            />
            <Route
              path={`${match.path}/:project_id/al/:al_session_id/tagging`}
              component={Tagging}
            />
            <Route
              path={`${match.path}/:project_id/al/:al_session_id/overview`}
              component={Overview}
            />
            <Route
              path={`${match.path}/:project_id/al/:al_session_id/training-stats`}
              component={TrainingStats}
            />
            <Route
              path={`${match.path}/:project_id/project-workflow`}
              component={ProjectWorkflowList}
            />
            <Route
              path={`${match.path}/:project_id/datasets`}
              component={ProjectDatasetList}
            />
            <Route
              path={`${match.path}/:project_id/edit`}
              component={EditProject}
            />
            <Route
              path={`${match.path}/:project_id/project-files`}
              component={ProjectFiles}
            />
            <Route
              path={`${match.path}/:project_id`}
              component={ProjectDetails}
            />
            <Route path={`${match.path}/`} component={ProjectMain} />
          </Switch>
        </Grid>
      </Grid>
    </ProjectContextWrapper>
  );
}

export default withStyles(styles)(Project);

import React from 'react';
import { Grid, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CommonStyles from '../../../../utils/CommonStyles';
import EditProject from '../EditProject';

const styles = theme => ({
  ...CommonStyles(theme),
  modal: {
    ...CommonStyles(theme).modal,
    width: '95vw',
    maxHeight: '95vh',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },
  modalFooter: {
    padding: '20px 0',
    textAlign: 'right',
  },
  cancelIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    cursor: 'pointer',
    fill: '#d32f2f',
  },
});

const ProjectEditModal = ({ classes, modalOpen, onClose, projectId }) => (
  <Modal className={classes.modalSearch} open={modalOpen} onClose={onClose}>
    <Grid className={classes.modal}>
      <div onClick={onClose}>
        <CancelIcon className={classes.cancelIcon} />
      </div>
      <EditProject match={projectId} isModal={true} reloadData={onClose} />
    </Grid>
  </Modal>
);

export default withStyles(styles)(ProjectEditModal);

import React from 'react';
import CommonStyles from '../../utils/CommonStyles';
import { Toolbar, Typography, withStyles } from '@material-ui/core';

const styles = theme => ({
  ...CommonStyles(theme),
  projectToolbar: {
    padding: 0,
    marginBottom: '20px',
    display: 'block',

    '& button': {
      fontSize: '12px',
      margin: '10px 10px 0 0',
    },

    '& button:first-child': {
      marginLeft: 0,
    },
  },
  projectTitle: {
    marginBottom: '20px',
    color: `rgba(0, 0, 0, 0.87)`,
  },
});

const ProjectToolbar = props => {
  const classes = props.classes;
  const project = props.project || {};

  return (
    <>
      <Toolbar className={classes.projectToolbar}>
        <Typography variant="h6" className={classes.projectTitle}>
          {project.name ? project.name : 'New Workflow'}
        </Typography>
        <div>{props.children}</div>
      </Toolbar>
    </>
  );
};

export default withStyles(styles)(ProjectToolbar);

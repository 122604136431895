import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { useLocation } from 'react-router-dom';

const styles = theme => ({
  birthdayContainer: {
    padding: theme.spacing(1, 1, 1, 5),
    background:
      'linear-gradient(90deg, #ff5353, #ffb958, #f4ff58, #85ff58, #58ffaf, #58fff9, #5888ff, #a558ff, #ff58f1)',
    backgroundSize: '1800% 1800%',
    textAlign: 'center',
    animation: '$bg-animation 30s ease infinite',
    fontSize: 20,
  },
  '@keyframes bg-animation': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' },
  },
});

const addOrdinalSuffix = i => {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

const Birthday = ({ classes }) => {
  const [show, setShow] = useState(false);
  const [years, setYears] = useState('1st');
  let location = useLocation();

  useEffect(() => {
    const now = new Date();
    let correctLocation = location.pathname !== '/login';
    let correctDate = now.getMonth() === 0 && now.getDate() <= 14;
    setShow(correctDate && correctLocation);
    setYears(addOrdinalSuffix(now.getFullYear() - 2019));
  }, [location]);

  return (
    <>
      {show && (
        <div className={classes.birthdayContainer}>
          Workflows {years} birthday! 🥳
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(Birthday);

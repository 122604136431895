import React, { useState, useCallback } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CommonStyles from '../../utils/CommonStyles';
import {
  TextField,
  List,
  ListItem,
  Popper,
  Fade,
  ClickAwayListener,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

const styles = theme => ({
  ...CommonStyles(theme),
  selectorWrapper: {
    position: 'relative',
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    cursor: 'pointer',
  },
  list: {
    background: 'white',
    maxHeight: '200px',
    overflowY: 'auto',
    borderRadius: '0 0 5px 5px',
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey['100'],
    },
  },
});

const Selector = ({
  field,
  choices,
  value,
  onChange,
  classes,
  error,
  helperText,
}) => {
  const [listAnchor, setListAnchor] = useState(null);

  const handleClick = useCallback(
    e => setListAnchor(listAnchor ? null : e.currentTarget),
    [listAnchor]
  );

  const handleClickArrow = e => {
    !open && handleClick(e);
  };

  const open = Boolean(listAnchor);

  const label = field.charAt(0).toUpperCase() + field.slice(1);

  return (
    <div className={classes.selectorWrapper}>
      <TextField
        label={label.replace(/_/g, ' ')}
        className={classes.inputField}
        value={value || ''}
        onChange={e => onChange(field, e.target.value)}
        fullWidth
        error={error}
        helperText={helperText}
      />
      {value && typeof value === 'object' && (
        <ArrowDropDown className={classes.arrow} onClick={handleClickArrow} />
      )}
      <Popper
        open={open}
        anchorEl={listAnchor}
        placement="bottom-end"
        style={{ width: '20%' }}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={e => handleClick(e)}>
            <Fade {...TransitionProps} timeout={350}>
              <List dense={true} className={classes.list}>
                {choices.map(choice => (
                  <ListItem
                    key={choice}
                    className={classes.listItem}
                    onClick={() => onChange(field, choice)}
                  >
                    {choice}
                  </ListItem>
                ))}
              </List>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

export default withStyles(styles)(Selector);

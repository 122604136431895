import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import CommonStyles from '../../../utils/CommonStyles';
import { ApiGet } from '../../../Api';

const styles = theme => ({
  ...CommonStyles(theme),
});

const WidgetOptionAutocomplete = ({
  label,
  provider,
  onChange,
  error,
  value,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = () => {
      ApiGet(`/api/services/autocomplete/${provider}/`).then(response => {
        setOptions(response);
        setLoading(false);
      });
    };
    getData();
  }, [provider]);

  const getOptionByValue = () => {
    let result = options.find(opt => opt.value === value);
    return result;
  };

  const loadingPlaceholder = (
    <TextField
      label={label}
      fullWidth
      disabled={loading}
      InputProps={{
        endAdornment: <CircularProgress color="inherit" size={20} />,
      }}
    />
  );

  return loading ? (
    <>{loadingPlaceholder}</>
  ) : (
    <Autocomplete
      freeSolo
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => (value ? onChange(value.value) : null)}
      getOptionSelected={(option, value) => option.value === value.value}
      getOptionLabel={option => option.label || ''}
      options={options}
      value={getOptionByValue() || { label: value, value: value }}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label={label}
          error={error}
          helperText={error}
          value={value}
          onChange={e => onChange(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default withStyles(styles)(WidgetOptionAutocomplete);

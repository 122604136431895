import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CheckCircle, Error, Info, Close, Warning } from '@material-ui/icons';
import { green, amber } from '@material-ui/core/colors';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  margin: {
    marginTop: 8,
  },
  position: {
    top: '65px',
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, key, ...other } =
    props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], classes[className])}
      aria-describedby="client-snackbar"
      key={key}
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <Close className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  key: PropTypes.string,
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={5000}
        resumeHideDuration={5000}
        onClose={this.props.handleClose}
        className={this.props.classes.position}
      >
        <div>
          {this.props.items.map((item, id) => {
            return (
              <MySnackbarContentWrapper
                onClose={this.props.handleClose.bind(null, item)}
                variant={item.type}
                message={item.message}
                className={'margin'}
                key={id}
              />
            );
          })}
        </div>
      </Snackbar>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notifications);

import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Typography,
  Toolbar,
  AppBar,
  Tabs,
  Tab,
  IconButton,
  Button,
  SwipeableDrawer,
  Divider,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from 'classnames';
import MenuRoutes from './MenuRoutes';
import AutocompleteSearch from './AutocompleteSearch';
import Cookies from 'universal-cookie';

const logo = require('../images/logo.png');

const styles = theme => ({
  appBar: {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey['100']}`,
    backgroundColor: 'white',
    zIndex: 100,
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    color: 'inherit',
    marginLeft: '10px',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  tagline: {
    display: 'none',
    marginLeft: 10,
    [theme.breakpoints.up('lg')]: {
      display: 'inline-block',
    },
  },
  environment: {
    marginLeft: 4,
    fontSize: 14,
  },
  iconButton: {
    marginLeft: '15px',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: 'auto',
  },
  tabs: {
    marginLeft: '25px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tabsMd: {
    '& a': {
      padding: '20px 5px',
    },
  },
  tabsContainer: {
    width: '40vw',
  },
  leftIndicator: {
    right: 'unset !important',
  },
  logoutButton: {
    color: theme.palette.grey['600'],
    width: '100%',
  },
  logout: {
    '&:hover': {
      backgroundColor: 'unset',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  autocompleteWrapper: {
    position: 'relative',
    width: 'auto',
    height: '100%',
  },
});

const Topbar = ({ classes }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [environment, setEnvironment] = useState('production');
  const cookies = new Cookies();
  const location = useLocation();
  const history = useHistory();

  const customMQ = useMediaQuery('(min-width:960px) and (max-width: 1090px)');

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const currentTab = () => {
    switch (location.pathname) {
      case '/project':
        return 1;
      case '/templates':
        return 2;
      case '/data-search':
        return 3;
      case '/data-search-history':
        return 4;
      case '/profile':
        return 5;
      case '/alive':
        return 6;
      case '/all-workflows':
        return 7;
      default:
        if (location.pathname.startsWith('/project')) return 1;
        return 0;
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') setEnvironment('local');
    else {
      switch (process.env.REACT_APP_STAGE) {
        case 'staging':
        case 'staging-us-east-2':
          setEnvironment('staging');
          break;
        case 'testing':
          setEnvironment('');
          break;
        case 'govcloud':
          setEnvironment('GovCloud');
          break;
        case 'production':
          setEnvironment('production');
          break;
        default:
          setEnvironment('unknown env');
      }
    }
  }, [setEnvironment]);

  useEffect(
    () => setSelectedTab(currentTab()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  useEffect(() => {
    if (cookies.get('token')) setSelectedTab(currentTab());
    if (cookies.get('status') === 'admin') setIsAdmin(true);
    else setIsAdmin(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  const handleLogout = () => {
    cookies.remove('token');
    cookies.remove('status');
    history.push('/');
  };

  if (!cookies.get('token')) return null;
  return (
    <AppBar position="static" color="default" className={classes.appBar}>
      <Toolbar>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.logo}
          noWrap
        >
          <Link to="/" className={classes.link}>
            <img width={20} src={logo} alt={''} />
            <span className={classes.tagline}>
              Workflows
              {environment !== 'production' && (
                <sup className={classes.environment}>{environment}</sup>
              )}
            </span>
          </Link>
        </Typography>
        <SwipeableDrawer
          anchor="right"
          open={drawerOpen}
          onOpen={() => toggleDrawer()}
          onClose={() => toggleDrawer()}
        >
          <div className={classes.tabsContainer}>
            <Tabs
              orientation="vertical"
              variant="fullWidth"
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              classes={{ indicator: classes.leftIndicator }}
            >
              {MenuRoutes.map((item, index) => (
                <Tab
                  component={Link}
                  to={{
                    pathname: item.pathname,
                    search: location.search,
                  }}
                  key={index}
                  label={item.label}
                />
              ))}
              {isAdmin && (
                <Tab
                  component={Link}
                  to={{
                    pathname: '/all-workflows',
                    search: location.search,
                  }}
                  key={MenuRoutes.length}
                  label="all workflows"
                />
              )}
            </Tabs>
            <Divider variant="middle" />
            <Button
              className={classes.logoutButton}
              onClick={() => handleLogout()}
            >
              LOGOUT
            </Button>
          </div>
        </SwipeableDrawer>
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          className={classNames(classes.tabs, { [classes.tabsMd]: customMQ })}
        >
          {MenuRoutes.map((item, index) => (
            <Tab
              key={index}
              component={Link}
              to={{ pathname: item.pathname, search: location.search }}
              classes={{ root: classes.tabItem }}
              label={item.label}
            />
          ))}
          {isAdmin && (
            <Tab
              key={MenuRoutes.length}
              component={Link}
              to={{
                pathname: '/all-workflows',
                search: location.search,
              }}
              classes={{ root: classes.tabItem }}
              label="all workflows"
            />
          )}
        </Tabs>
        <div className={classes.grow} />
        <div className={classes.autocompleteWrapper}>
          <AutocompleteSearch />
        </div>
        <IconButton
          onClick={() => toggleDrawer()}
          className={classes.iconButton}
        >
          <MenuIcon />
        </IconButton>
        <Button
          className={classes.logout}
          onClick={() => handleLogout()}
          size="large"
        >
          LOGOUT
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Topbar);

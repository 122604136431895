import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import {
  Avatar,
  Button,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Checkbox,
  Input,
  InputLabel,
  Paper,
  Typography,
  LinearProgress,
  SnackbarContent,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import GoogleLogin from 'react-google-login';
// TODO: import when it works
// import GoogleLogin from './common/GoogleLogin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import API_URL, { ApiGet } from '../Api';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  error: {
    marginTop: 20,
  },
});

const cookies = new Cookies();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      loading: false,
      loginError: false,
      auth: {
        username: '',
        password: '',
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.doLogin = this.doLogin.bind(this);
    this.googleLoginSuccess = this.googleLoginSuccess.bind(this);
  }

  handleChange(event) {
    this.setState({
      auth: {
        ...this.state.auth,
        [event.target.id]: event.target.value,
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.doLogin(this.state.auth, false);
  }

  doLogin(credentials, google = false) {
    this.setState({ loading: true });
    let endpoint = google ? 'api-token-auth-google' : 'api-token-auth';
    fetch(`${API_URL}/${endpoint}/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(credentials),
    })
      .then(r => {
        if (r.ok) return r.json();
        else throw Error(r.status);
      })
      .then(response => {
        cookies.set('token', response.token, { path: '/' });
        let domain = '';
        switch (process.env.REACT_APP_STAGE) {
          case 'testing':
            domain = 'protagonist.io';
            break;
          case 'govcloud':
            domain = 'gc.protagonist.io';
            break;
          default:
            domain = 'protagonist-io.com';
            break;
        }
        cookies.set('altoken', response.token, {
          path: '/',
          domain: domain,
        }); // al worker cookie
        ApiGet('/users/me').then(profile => {
          if (profile.is_staff) cookies.set('status', 'admin', { path: '/' });
          this.setState({ redirect: true, loginError: false, loading: false });
        });
      })
      .catch(error => this.setState({ loginError: true, loading: false }));
  }

  googleLoginSuccess(response) {
    let { accessToken } = response;
    this.doLogin({ access_token: accessToken }, true);
  }

  render() {
    let { from } = this.props.location.state || { from: { pathname: '/' } };
    let { redirect, loginError, loading } = this.state;
    const { classes } = this.props;

    if (redirect) return <Redirect to={from} />;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">Username</InputLabel>
              <Input
                id="username"
                name="username"
                autoComplete="username"
                autoFocus
                onChange={this.handleChange.bind(this)}
                error={loginError}
                disabled={loading}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.handleChange.bind(this)}
                error={loginError}
                disabled={loading}
              />
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign in
            </Button>
            {/* <GoogleLogin
              // TODO: new google login methods
              clientId="547788469242-s9euqu2jvt5f3piirk0eqp13nfhj4cdu.apps.googleusercontent.com"
              onSuccess={this.googleLoginSuccess}
            /> */}
            <GoogleLogin
              clientId="547788469242-s9euqu2jvt5f3piirk0eqp13nfhj4cdu.apps.googleusercontent.com"
              buttonText="Sign in with Google"
              onSuccess={this.googleLoginSuccess}
              onFailure={r => console.log(r)}
              render={renderProps => (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={renderProps.onClick}
                >
                  Sign in with Google
                </Button>
              )}
            />
            {loading && <LinearProgress />}
            {this.state.loginError && (
              <SnackbarContent
                className={classes.error}
                aria-describedby="client-snackbar"
                message={
                  <span id="client-snackbar" className={classes.message}>
                    {'Username or password invalid.'}
                  </span>
                }
              />
            )}
          </form>
        </Paper>
      </main>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Login));

import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ApiGet } from '../../../Api';
import { LANGUAGES } from '../../common/Contstants';
import CommonStyles from '../../../utils/CommonStyles';
import Loader from '../../common/Loader';
import ProjectUsers from '../ProjectUsers';

const styles = theme => ({
  ...CommonStyles(theme),
  ProjectForm: {
    display: 'flex',
    flexDirection: 'column',
    '& > .MuiFormControl-root': {
      margin: '10px 0px',
    },
  },
  ProjectGroup: {
    display: 'flex',
    flex: '1',
  },
  ManageGroupsButton: {
    marginTop: '5px',
  },
  UsersButton: {
    marginBottom: '5px',
  },
  GroupsForm: {
    display: 'flex',
    flexDirection: 'row',
  },
  SubmitButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '10px',
  },
  ButtonsContainer: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  linearProgress: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
});
function ProjectProperties({
  classes,
  project,
  setProject,
  onSubmit,
  isNewProject,
}) {
  const match = useRouteMatch();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      project_groups: project.project_groups,
      language: project.language,
    },
  });
  const [allGlobalGroups, setAllGlobalGroups] = useState(undefined);
  const [errors, setError] = useState({ name: false, notes: false });

  useEffect(() => {
    ApiGet('/api/projects/project-groups/').then(res => {
      setAllGlobalGroups(res);
    });
  }, []);

  const handleProjectFormChange = e => {
    setProject({
      ...project,
      [e.target.name]: e.target.value,
    });
    errorHandler(e);
  };

  const handleProjectGroupChange = e => {
    setProject({
      ...project,
      [e.target.name]: e.target.value,
    });
  };

  const errorHandler = e => {
    e.target.value
      ? setError({ ...errors, [e.target.name]: false })
      : setError({ ...errors, [e.target.name]: true });
  };

  return (
    <div>
      {allGlobalGroups ? (
        <form
          className={classes.ProjectForm}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            {...register('name')}
            value={project.name}
            label="Project Name*"
            variant="outlined"
            error={errors.name}
            autoFocus
            onFocus={errorHandler}
            onChange={handleProjectFormChange}
          />
          <TextField
            {...register('notes')}
            value={project.notes}
            label="Notes*"
            multiline
            rows={4}
            variant="outlined"
            error={errors.notes}
            onFocus={errorHandler}
            onChange={handleProjectFormChange}
          />

          <FormControl variant="outlined" className={classes.GroupsForm}>
            <Grid className={classes.ProjectGroup}>
              <InputLabel id="groups-label">Groups</InputLabel>
              <Select
                {...register('project_groups')}
                id="groups-select"
                labelId="groups-label"
                value={project.project_groups}
                onChange={handleProjectGroupChange}
                renderValue={selected => {
                  return Array.isArray(selected)
                    ? selected
                        .map(x => allGlobalGroups[x] || 'Default')
                        .join(', ')
                    : allGlobalGroups[selected];
                }}
                fullWidth
                multiple
                label="Groups"
                name="project_groups"
              >
                <MenuItem disabled value="Default">
                  <Checkbox checked />
                  Default
                </MenuItem>
                {Object.keys(allGlobalGroups).map(id => (
                  <MenuItem key={id} value={id}>
                    <Checkbox
                      key={id}
                      checked={project.project_groups.includes(id)}
                    />
                    {allGlobalGroups[id]}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid className={classes.ButtonsContainer}>
              <ProjectUsers
                projectId={match.params.project_id}
                disabled={!!!match.params.project_id}
              />
              <Button
                disabled
                className={classes.ManageGroupsButton}
                variant="outlined"
              >
                Manage Groups
              </Button>
            </Grid>
          </FormControl>
          <TextField
            {...register('language')}
            id="standard-select-currency"
            select
            variant="outlined"
            label="Language"
            value={project.language}
            name="language"
            onChange={handleProjectFormChange}
          >
            {LANGUAGES.map((value, idx) => (
              <MenuItem key={idx} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
          <div className={classes.SubmitButton}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!project.name || !project.notes}
            >
              {isNewProject ? 'Create New Project' : 'Save changes'}
            </Button>
          </div>
        </form>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(ProjectProperties);

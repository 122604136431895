import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loader: {
    margin: '20px 0',
  },
});

const Loader = ({ size = 40 }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justifyContent="center">
      <CircularProgress size={size} classes={{ root: classes.loader }} />
    </Grid>
  );
};

export default Loader;

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Toc } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import Loader from '../../common/Loader';
import CommonStyles from '../../../utils/CommonStyles';
import { ApiGet } from '../../../Api';

const styles = theme => ({
  ...CommonStyles(theme),
  cancelIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
    fill: '#d32f2f',
  },
});

function DataDictionaryTable({ classes, projectId, disabled }) {
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState(null);

  const getData = () => {
    if (dialogOpen) {
      setLoading(true);
      ApiGet(`/api/projects/validate/${projectId}`).then(response => {
        setData(response.columns);
        setLoading(false);
      });
    }
  };

  useEffect(getData, [projectId, dialogOpen]);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  return (
    <>
      <Button
        onClick={openDialog}
        disabled={disabled}
        startIcon={<Toc />}
        variant="outlined"
      >
        Data dictionary table
      </Button>
      {dialogOpen && (
        <Dialog
          fullWidth
          open
          aria-labelledby="project-columns-mapping"
          onClose={closeDialog}
          maxWidth="sm"
        >
          <DialogTitle id="project-columns-mapping" onClose={closeDialog}>
            Data Dictionary Table
            <CancelIcon
              size="20"
              className={classes.cancelIcon}
              onClick={closeDialog}
            />
          </DialogTitle>
          {loading ? (
            <DialogContent dividers>
              <Loader />
            </DialogContent>
          ) : (
            <DialogContent dividers>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Source</TableCell>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody key="const-rows">
                    <TableRow key="workflows-const">
                      <TableCell key="workflows-internal">
                        Workflows Internal
                      </TableCell>
                      <TableCell key="workflows-index">Index</TableCell>
                    </TableRow>
                  </TableBody>
                  {data.map((item, index) => {
                    return (
                      <TableBody key={index}>
                        {item.columns.map((column, column_index) => {
                          return (
                            <TableRow key={index + '-' + column_index}>
                              <TableCell
                                key={item.name + index + '-' + column_index}
                              >
                                {item.name}
                              </TableCell>
                              <TableCell
                                key={column + index + '-' + column_index}
                              >
                                {column}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    );
                  })}
                </Table>
              </TableContainer>
            </DialogContent>
          )}
          <DialogActions>
            <Button autoFocus onClick={closeDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default withRouter(withStyles(styles)(DataDictionaryTable));

export default class Validator {
  constructor(validators, context, stateName, notification) {
    this.original_validators = validators;
    this.validators = this.initValidators();
    this.context = context;
    this.showError = this.showError.bind(this.context);
    this.stateName = stateName || 'validator';
    this.notification = notification;
  }

  initValidators() {
    function initValidity(v, original_validators) {
      for (const key in v) {
        if (typeof v[key] === 'object') {
          v[key].isValid = true;
          if (!v[key].name) v[key].name = key;
          if (v[key].rules) v[key].rules = [...original_validators[key].rules];
          if (v[key].fields)
            initValidity(v[key].fields, original_validators[key].fields);
        }
      }
    }
    // Deep copy
    let validators = JSON.parse(JSON.stringify(this.original_validators));
    initValidity(validators, this.original_validators);
    return validators;
  }

  deepCopy(original) {
    function fixFunctionRules(v, original_validators) {
      for (const key in v) {
        if (typeof v[key] === 'object') {
          if (v[key].rules) v[key].rules = [...original_validators[key].rules];
          if (v[key].fields)
            fixFunctionRules(v[key].fields, original_validators[key].fields);
        }
      }
    }
    // Deep copy
    if (typeof original === 'undefined') return {};
    let copy = JSON.parse(JSON.stringify(original));
    fixFunctionRules(copy, original);
    return copy;
  }

  showError(message) {
    this.notification({
      type: 'error',
      message: message,
    });
  }

  validate(validator, value) {
    if (value !== 0 && !value) {
      if (validator.isRequired) {
        this.showError(validator.name + ' required.');
        validator.isValid = false;
      }
      return validator;
    }
    if (validator.isValid === undefined) validator.isValid = true;
    for (let rule in validator.rules) {
      rule = validator.rules[rule];
      if (typeof rule === 'function') {
        if (!rule(value)) {
          if (rule.message) this.showError(rule.message);
          else this.showError(validator.name + ' invalid.');
          validator.isValid = false;
          break;
        }
      } else if (typeof rule === 'object') {
        if (!rule.validator(value)) {
          if (rule.errorMessage)
            this.showError(rule.errorMessage(validator.name));
          else this.showError(validator.name + ' invalid.');
          validator.isValid = false;
          break;
        }
      } else if (typeof rule === 'string') {
        if (rule === 'isString' && value.constructor !== String) {
          this.showError(validator.name + ' has to be a string.');
          validator.isValid = false;
          break;
        } else if (rule === 'isNotEmpty' && value.length === 0) {
          this.showError(validator.name + " can't be empty.");
          validator.isValid = false;
          break;
        } else if (rule === 'isNumber' && value.constructor !== Number) {
          this.showError(validator.name + ' has to be a number.');
          validator.isValid = false;
          break;
        } else if (rule === 'isArray' && value.constructor !== Array) {
          this.showError(validator.name + ' has to be an array.');
          validator.isValid = false;
          break;
        }
      } else console.log('Wrong validation rule ' + rule);
    }
    if (validator.isValid !== false) {
      if (validator.forceArray || value.constructor === Array) {
        let nestedValidators = validator.fields;
        validator.fields = [];
        for (let key in value) {
          validator.fields[key] = this.validate(
            { fields: this.deepCopy(nestedValidators) },
            value[key]
          );
          validator.isValid =
            validator.isValid && validator.fields[key].isValid;
        }
      } else if (value.constructor !== Array) {
        for (let field in validator.fields) {
          validator.fields[field] = this.validate(
            validator.fields[field],
            value[field]
          );
          validator.isValid =
            validator.isValid && validator.fields[field].isValid;
        }
      }
    }
    return validator;
  }

  checkValidity() {
    let validators = this.initValidators();
    let isValid = true;
    for (const key in validators) {
      validators[key] = this.validate(validators[key], this.context.state[key]);
      isValid = isValid && validators[key].isValid;
    }
    this.validators = validators;
    this.context.setState({ [this.stateName]: this });
    return isValid;
  }
}

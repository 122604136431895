import React, { useState } from 'react';

export const ProjectContext = React.createContext(null);

export const ProjectContextWrapper = props => {
  const [store, setStore] = useState({
    reloadProjectList: false,
    menuCollapsed: false,
    pageLoading: false,
  });

  // eslint-disable-next-line
  const [actions, setActions] = useState({
    reloadProjectList: isReload =>
      setStore({ ...store, reloadProjectList: isReload }),
    menuCollapsed: collapsed =>
      setStore({ ...store, menuCollapsed: collapsed }),
    pageLoading: loading => setStore({ ...store, pageLoading: loading }),
  });

  return (
    <ProjectContext.Provider value={{ store, actions }}>
      {props.children}
    </ProjectContext.Provider>
  );
};

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import {
  Grid,
  Link as MuiLink,
  Paper,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { ApiGet } from '../../../Api';
import { NotificationContext } from '../../../NotificationContext';
import { withStyles } from '@material-ui/core/styles';
import CommonStyles from '../../../utils/CommonStyles';
import ListTable from './ListTable';
import Loader from '../../common/Loader';

const styles = theme => ({
  ...CommonStyles(theme),
  splitDate: {
    display: 'block',
    paddingLeft: 10,
  },
  status: {
    '&.FAILED': {
      color: theme.palette.error.main,
    },

    '&.SUCCEEDED': {
      color: theme.palette.info.main,
    },
  },
});

const datasetTableHeaders = () => {
  return (
    <TableRow>
      <TableCell>Name</TableCell>
      <TableCell>Workflow Name</TableCell>
      <TableCell>Last run</TableCell>
      <TableCell></TableCell>
      <TableCell>Created by</TableCell>
      <TableCell align="right">Updated at</TableCell>
    </TableRow>
  );
};

function ProjectDatasetList({ classes, match }) {
  const { notification } = useContext(NotificationContext);
  const [projectDatasets, setProjectDatasets] = useState();

  const status = status =>
    status && (
      <span className={classNames(classes.status, status)}>{status}</span>
    );

  const datasetTableRow = row => {
    return (
      <TableRow hover key={row.id} className={classes.tableRow}>
        <TableCell scope="row">
          <Link
            component={MuiLink}
            to={{
              pathname: `/project/${match.params.project_id}/project-workflow/${row.project_workflow.id}/dataset/${row.id}/`,
              state: { from: 'internal' },
            }}
          >
            {row.name}
          </Link>
        </TableCell>
        <TableCell scope="row">
          <Link
            component={MuiLink}
            to={{
              pathname: `/project/${match.params.project_id}/project-workflow/${row.project_workflow.id}/`,
              state: { from: 'internal' },
            }}
          >
            {row.project_workflow.name}
          </Link>
        </TableCell>
        <TableCell scope="row">
          {!row.last_run && 'no runs'}
          {row.last_run &&
            moment(row.last_run.created_at).format('MMM Do YYYY h:mm A')}
        </TableCell>
        <TableCell>{row.last_run && status(row.last_run.status)}</TableCell>
        <TableCell scope="row">
          {row.created_by
            ? `${row.created_by.first_name} ${row.created_by.last_name}`
            : 'Unknown'}
        </TableCell>
        <TableCell align="right">
          {moment(row.updated_at).format('MMM Do YYYY')} <br />
          {moment(row.updated_at).format('h:mm A')}
        </TableCell>
      </TableRow>
    );
  };

  useEffect(() => {
    ApiGet(`/api/projects/projects/${match.params.project_id}/datasets/`).then(
      res => {
        if (res) {
          setProjectDatasets(res);
        } else {
          notification({
            type: 'error',
            message: 'There was some problem when fetching Workflows',
          });
        }
      }
    );
  }, []); // eslint-disable-line

  return (
    <Grid className={classes.ProjectContainer} component={Paper}>
      {projectDatasets ? (
        <ListTable
          data={projectDatasets}
          title="Datasets List"
          customTableRow={datasetTableRow}
          customTableHeaders={datasetTableHeaders}
          colSpan={5}
        />
      ) : (
        <Loader />
      )}
    </Grid>
  );
}

export default withStyles(styles)(ProjectDatasetList);

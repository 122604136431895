import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import {
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  Paper,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Archive as ArchiveIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { ApiGet, ApiPost, ApiPatch } from '../../../Api';
import CommonStyles from '../../../utils/CommonStyles';
import ListTable from './ListTable';
import WidgetInput from '../../WidgetInput/WidgetInput';
import CircularProgressWithLabel from '../../common/CircularProgressWithLabel';
import Loader from '../../common/Loader';
import NoData from '../../common/NoData';

const styles = theme => ({
  ...CommonStyles(theme),
  projectContainer: {
    ...CommonStyles(theme).ProjectContainer,
  },
  marginLeft20: {
    marginLeft: 20,
  },
  uploadBtn: {
    marginRight: 20,
  },
  searchClear: {
    cursor: 'pointer',
  },
  s3Uploader: {
    display: 'none',
  },
  inputLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  fileUploadProgress: {
    marginRight: 20,
  },
});

function ProjectFiles({ classes, match }) {
  const [allFiles, setAllFiles] = useState();
  const [files, setFiles] = useState();
  const [selected, setSelected] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [fileProgress, setFileProgress] = useState();
  const [disabled, setDisabled] = useState(false);
  const [path, setPath] = useState();
  const [processing, setProcessing] = useState(false);

  const PAGINATION = 25;
  const GetEndpoint = `/api/projects/projects/${match.params.project_id}/project-files/`;
  const PatchEndpoint = id =>
    `/api/projects/projects/${match.params.project_id}/project-files/${id}`;

  const loadData = useCallback(() => {
    ApiGet(GetEndpoint).then(res => {
      const tmp = res.sort((a, b) => b.id - a.id);
      setFiles(tmp);
      setAllFiles(tmp);
    });
  }, [GetEndpoint]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const searchFiles = useCallback(() => {
    allFiles &&
      setFiles(allFiles.filter(item => item.filename.includes(inputSearch)));
  }, [inputSearch, allFiles]);

  useEffect(() => {
    searchFiles();
  }, [searchFiles]);

  const s3GetFile = file => {
    const s3Url = file.urls.s3Url;

    ApiPost(GetEndpoint, {
      file: s3Url,
    }).then(() => {
      loadData();
      setFileProgress();
    });
  };

  const archiveProject = () => {
    setProcessing(true);
    if (selected.length > 0) {
      let archive = Promise.all(
        selected.map(
          async item =>
            await ApiPatch(PatchEndpoint(item.id), {
              archived: true,
            })
        )
      );
      archive.then(() => {
        loadData();
        setProcessing(false);
        setSelected([]);
      });
    }
  };

  const selectHandler = useCallback(
    value => {
      if (selected.includes(value)) {
        const filtered = selected.filter(item => item.id !== value.id);
        setSelected(filtered);
      } else {
        setSelected(selected => [...selected, value]);
      }
    },
    [selected]
  );

  const selectAllToggle = useCallback(() => {
    files.length === selected.length
      ? setSelected([])
      : setSelected(files.map(item => item));
  }, [files, selected]);

  /* Table */
  const projectTableHeaders = () => (
    <TableRow>
      <TableCell>
        <Checkbox
          color="default"
          checked={files.length === selected.length}
          onChange={selectAllToggle}
          // disabled={processing}
        />
      </TableCell>
      <TableCell>Filename</TableCell>
      <TableCell>Updated by</TableCell>
      <TableCell align="right">Updated at</TableCell>
    </TableRow>
  );

  const projectTableRow = row => {
    return (
      <>
        <TableRow hover key={row.id}>
          <TableCell>
            <Checkbox
              color="default"
              checked={selected.includes(row)}
              value={row}
              onClick={() => selectHandler(row)}
              // disabled={processing}
            />
          </TableCell>
          <TableCell>
            <Link
              className={classes.link}
              href={row.file_url}
              target="_blank"
              download
            >
              {row.filename}
            </Link>
          </TableCell>
          <TableCell>
            {row.updated_by?.first_name} {row.updated_by?.last_name}
          </TableCell>
          <TableCell align="right">
            {moment(row.updated_at).tz('UTC').format('MMM Do, YYYY')}
          </TableCell>
        </TableRow>
      </>
    );
  };
  /* end: Table */

  return (
    <>
      {!files && <Loader />}
      {files && (
        <Grid component={Paper} className={classes.projectContainer}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h6">Project files</Typography>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              alignItems="center"
              justify="space-between"
              className={classes.toolbar}
            >
              <Grid item xs={6} className={classes.toolbarItem}>
                {allFiles && allFiles.length > 0 && (
                  <Tooltip title="select file">
                    <span className={classes.tooltipWrapper}>
                      <Button
                        variant="outlined"
                        startIcon={<ArchiveIcon />}
                        onClick={archiveProject}
                        disabled={selected.length === 0 || processing}
                      >
                        Archive
                      </Button>
                    </span>
                  </Tooltip>
                )}
                <InputLabel
                  className={classes.inputLabel}
                  htmlFor="upload-files"
                >
                  <Button
                    className={classNames(classes.uploadBtn, {
                      [classes.marginLeft20]: allFiles && allFiles.length > 0,
                    })}
                    disabled={disabled}
                    component="div"
                    variant="contained"
                    color="primary"
                  >
                    Add file
                  </Button>
                  {disabled && fileProgress > 0 && (
                    <div className={classes.fileUploadProgress}>
                      <CircularProgressWithLabel
                        value={fileProgress}
                        size={25}
                      />
                    </div>
                  )}
                </InputLabel>
                {!disabled && (
                  <div className={classes.s3Uploader}>
                    <WidgetInput
                      id="upload-files"
                      format={'s3file'}
                      value={path}
                      onChange={path => {
                        setPath(path);
                      }}
                      onFinish={s3GetFile}
                      onFileUploadProgress={setFileProgress}
                      toggleUploading={setDisabled}
                      showS3FilePath={false}
                    />
                  </div>
                )}
              </Grid>
              {allFiles && allFiles.length > 0 && (
                <Grid item container xs={6} justifyContent="flex-end">
                  <TextField
                    size="small"
                    variant="outlined"
                    value={inputSearch}
                    onChange={e => setInputSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          className={classes.searchClear}
                          position="end"
                          onClick={() => setInputSearch('')}
                        >
                          <CloseIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              {files && files.length === 0 && <NoData />}
              {files && files.length > 0 && (
                <ListTable
                  classes={{ marginBot: classes.table }}
                  data={files}
                  customTableRow={projectTableRow}
                  customTableHeaders={projectTableHeaders}
                  colSpan={6}
                  pagination={PAGINATION}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default withStyles(styles)(ProjectFiles);

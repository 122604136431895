const LANGUAGES = [
  'All',
  'English',
  'Afrikaans',
  'Albanian',
  'Amharic',
  'Arabic',
  'Armenian',
  'Azerbaijani',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bosnian',
  'Breton',
  'Bulgarian',
  'Burmese',
  'Catalan',
  'Central Khmer',
  'Cherokee',
  'Chinese',
  'Croatian',
  'Czech',
  'Danish',
  'Dhivehi',
  'Dutch',
  'Esperanto',
  'Estonian',
  'Faroese',
  'Filipino',
  'Finnish',
  'French',
  'Gaelic',
  'Galician',
  'Georgian',
  'German',
  'Greek',
  'Guaraní',
  'Gujarati',
  'Haitian Creole',
  'Hausa',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Icelandic',
  'Indonesian',
  'Irish',
  'Italian',
  'Japanese',
  'Kannada',
  'Kazakh',
  'Kinyarwanda',
  'Korean',
  'Kurdish',
  'Kyrgyz',
  'Lao',
  'Latin',
  'Latvian',
  'Lithuanian',
  'Luxembourgish',
  'Macedonian',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Maori',
  'Marathi',
  'Mixed',
  'Mongolian',
  'Nepali',
  'Northern Sami',
  'Norwegian',
  'Oriya',
  'Papiamento',
  'Persian',
  'Polish',
  'Portuguese',
  'Punjabi',
  'Pushto',
  'Romanian',
  'Romansh',
  'Russian',
  'Samoan',
  'Sanskrit',
  'Serbian',
  'Serbo-Croatian',
  'Shona',
  'Sindhi',
  'Sinhala',
  'Slovak',
  'Slovenian',
  'Somali',
  'Southern Sotho',
  'Spanish',
  'Swahili',
  'Swedish',
  'Tajik',
  'Tamil',
  'Telugu',
  'Thai',
  'Tibetan',
  'Turkish',
  'Ukrainian',
  'Unassigned',
  'Urdu',
  'Uyghur',
  'Uzbek',
  'Vietnamese',
  'Welsh',
  'Western Frisian',
  'Xhosa',
  'Zulu',
];

export default LANGUAGES;

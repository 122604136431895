import React, { useState } from "react";
import Editor from "react-simple-code-editor";
import { highlight } from "prismjs/components/prism-core";
import "./HighlightedTextField.css";
import Prism from "prismjs";
import { Card } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import CommonStyles from "../../utils/CommonStyles";

Prism.languages.sql = {
  string: {
    pattern: /(^|[^@\\])("|')(?:\\[\s\S]|(?!\2)[^\\]|\2\2)*\2/,
    greedy: true,
    lookbehind: true,
  },
  number: /\b0x[\da-f]+\b|\b\d+\.?\d*|\B\.\d+\b/i,
  operator: /[/:^~]|&&?|\|\|?|!=?|<(?:=>?|<|>)?|>[>=]?|\b(?:AND|NOT|OR)\b/i,
  punctuation: /[;[\]()]/,
};

const styles = (theme) => ({
  ...CommonStyles(theme),
  textEditor: {
    fontSize: 16,
    minHeight: 200,
    "& pre": {
      outline: "none",
    },
  },
});

function HighlightedTextField({ classes, label, value, setQuery, name }) {
  const [style, setStyle] = useState({
    border: "1px solid #c4c4c4",
    padding: 2,
  });
  const [focus, setFocus] = useState(false);

  const onMouseOver = () => {
    if (!focus) {
      setStyle({ ...style, border: "1px solid" });
    }
  };

  const onMouseOut = () => {
    if (!focus) {
      setStyle({ ...style, border: "1px solid #c4c4c4" });
    }
  };

  const onFocus = () => {
    setStyle({ ...style, border: "2px solid #c44536", padding: 1 });
    setFocus(true);
  };

  const onBlur = () => {
    setStyle({ ...style, border: "1px solid #c4c4c4", padding: 2 });
    setFocus(false);
  };

  return (
    <Card variant="outlined" style={style}>
      <Editor
        className={classes.textEditor}
        name={name}
        placeholder={label}
        value={value}
        onValueChange={(e) => setQuery({ target: { name: name, value: e } })}
        highlight={(query) => highlight(query, Prism.languages.sql)}
        padding={10}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
    </Card>
  );
}

export default withRouter(withStyles(styles)(HighlightedTextField));

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  OpenInNew,
  Close,
  Check,
  Replay,
  ArrowBackIos as ArrowBackIosIcon,
} from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import { ApiGet, ApiPost, ApiPatch, ApiDelete } from '../Api';
import CommonStyles from '../utils/CommonStyles';
import Loader from './common/Loader';
import NoData from './common/NoData';
import Notifications from './common/Notifications';
import Pagination from './common/Pagination';
import Validator from '../utils/Validator';

const styles = theme => ({
  ...CommonStyles(theme),
  container: {
    display: 'flex',
    flex: '1',
  },
  saveButton: {
    marginTop: 8,
  },
  linkHover: {
    '&:hover': {
      textDecoration: 'unset',
    },
  },
  runLink: {
    color: theme.palette.neutral.text,
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
  },
  success: {
    display: 'flex',
    justifyContent: 'center',
    color: '#388E3C',
  },
  fail: {
    display: 'flex',
    justifyContent: 'center',
    color: '#d32f2f',
  },
  running: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.neutral.text,
  },
});

const intervals = [
  [3600, '1 Hour'],
  [7200, '2 Hours'],
  [14400, '4 Hours'],
  [21600, '6 Hours'],
  [43200, '12 Hours'],
  [86400, '1 Days'],
  [172800, '2 Days'],
  [259200, '3 Days'],
  [345600, '4 Days'],
  [432000, '5 Days'],
  [518400, '6 Days'],
  [604800, '1 Week'],
  [1209600, '2 Weeks'],
  [1814400, '3 Weeks'],
  [2419200, '1 Month'],
  [4838400, '2 Months'],
  [7257600, '3 Months'],
  [9676800, '4 Months'],
  [14515200, '6 Months'],
  [29030400, '1 Year'],
];

const PAGINATION = 10;

class Dataset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      dataset: {
        run_interval: 3600,
        start_date: moment().format('YYYY-MM-DDTHH:mm'),
        stop_date: moment().format('YYYY-MM-DDTHH:mm'),
        interval_hour: moment().format('HH:mm'),
        run_indefinitely: false,
      },
      runs: null,
      loading: true,
      loadingRuns: true,
      saving: false,
      running: false,
      interval: 0,
      page: 0,
      maxPage: 0,
      allRows: 0,
      timezone: 'UTC',
      notifications: [],
    };
  }

  statuses = {
    FAILED: (
      <Typography className={this.props.classes.fail}>
        <Close /> FAILED
      </Typography>
    ),
    SUCCEEDED: (
      <Typography className={this.props.classes.success}>
        <Check /> SUCCEEDED
      </Typography>
    ),
    RUNNING: (
      <Typography className={this.props.classes.running}>
        <CircularProgress
          color="grey"
          size={15}
          thickness="3"
          style={{ marginRight: '3px' }}
        />{' '}
        RUNNING
      </Typography>
    ),
  };

  componentDidMount() {
    this.setState({ loading: true, loadingDatasets: true });
    this.setValidators();
    ApiGet('/users/me').then(me => {
      this.setState({
        timezone: me.settings.timezone,
      });
    });
    ApiGet(
      `/api/projects/projects/${this.props.match.params.project_id}/project-workflows/${this.props.match.params.project_workflow_id}`
    )
      .then(project => {
        this.setState({ project });
        return project;
      })
      .then(project => {
        if (this.props.match.params.id !== 'new') {
          ApiGet(`${project.url}datasets/${this.props.match.params.id}/`).then(
            dataset => {
              let interval = intervals.findIndex(item => {
                return item[0] === dataset.run_interval;
              });
              if (interval === -1) interval = 0;
              this.setState(
                {
                  interval,
                  dataset: {
                    ...dataset,
                    start_date: dataset.start_date.split('Z')[0],
                    stop_date: dataset.stop_date
                      ? dataset.stop_date.split('Z')[0]
                      : null,
                  },
                },
                () => this.setState({ loading: false })
              );
              this.loadRuns(dataset);
            }
          );
        } else this.setState({ loading: false });
      });
  }

  loadRuns(dataset) {
    dataset = dataset || this.state.dataset;
    ApiGet(`${dataset.url}runs/`, {
      limit: PAGINATION,
      offset: this.state.page * PAGINATION,
    }).then(response => {
      this.setState({
        runs: response.results,
        page: 0,
        allRows: response.count,
        maxPage: Math.floor(response.count / PAGINATION),
        loadingRuns: false,
      });
    });
  }

  save() {
    this.setState({
      notifications: [],
    });
    let check = this.state.validator.checkValidity();
    if (!this.state.dataset.run_indefinitely && !this.state.dataset.stop_date) {
      this.setState({
        notifications: [
          {
            type: 'error',
            message: 'Check run indefinitely or provide end date!',
          },
        ],
      });
      return;
    }
    if (!check) {
      return;
    }
    this.setState({ saving: true });
    let p = null;
    let dataset = this.state.dataset.run_indefinitely
      ? {
          ...this.state.dataset,
          stop_date: undefined,
        }
      : this.state.dataset;
    if (this.props.match.params.id === 'new') {
      p = ApiPost(`${this.state.project.url}datasets/`, dataset);
    } else {
      p = ApiPatch(this.state.dataset.url, dataset);
    }
    p.then(dataset => {
      this.setState(
        {
          loadingRuns: true,
          dataset: {
            ...dataset,
            start_date: dataset.start_date.split('Z')[0],
            stop_date: dataset.stop_date
              ? dataset.stop_date.split('Z')[0]
              : null,
          },
          saving: false,
        },
        this.loadRuns.bind(this)
      );
      this.notification({
        type: 'success',
        message: 'Saved',
      });
      this.props.history.push({
        pathname: `/project/${this.props.match.params.project_id}/project-workflow/${this.props.match.params.project_workflow_id}/dataset/${dataset.id}/`,
        state: {
          from: `/project/${this.props.match.params.project_id}/project-workflow/${this.props.match.params.project_workflow_id}/`,
        },
      });
    });
  }

  setValidators() {
    let validators = {
      dataset: {
        isRequired: true,
        fields: {
          name: {
            rules: ['isString'],
            isRequired: true,
          },
          run_interval: {
            rules: ['isInteger'],
            isRequired: true,
          },
          start_date: {
            rules: ['isString'],
            isRequired: true,
          },
          stop_date: {
            rules: ['isString'],
            isRequired: false,
          },
        },
      },
    };
    let validator = new Validator(
      validators,
      this,
      this.notification.bind(this)
    );
    this.setState({
      validator: validator,
    });
  }

  changeDataset(field, e) {
    this.setState({
      dataset: {
        ...this.state.dataset,
        [field]: e.target ? e.target.value : e,
      },
    });
  }

  changeIntervalDay(n, event, value) {
    let l = [];
    if (this.state.dataset.interval_day)
      l = this.state.dataset.interval_day.split(',');
    let index = l.indexOf(n.toString());
    if (index !== -1) l.splice(index, 1);
    if (value) l.push(n);
    this.setState({
      dataset: {
        ...this.state.dataset,
        interval_day: l.join(','),
      },
    });
  }

  runDataset() {
    this.setState(
      {
        running: true,
      },
      () => {
        ApiPost(`${this.state.dataset.url}runs/`, { nothisn: 'adssda' }).then(
          () => {
            this.setState({ running: false, loadingRuns: true });
            this.loadRuns();
          }
        );
      }
    );
  }

  cancelRun(runId) {
    this.setState({ running: false, loadingRuns: true });
    ApiDelete(`${this.state.dataset.url}runs/${runId}`, { d: 0 }).then(() =>
      this.loadRuns()
    );
  }

  recoverRun(runId) {
    this.setState({ running: false, loadingRuns: true });
    ApiGet(`/api/projects/recovery/${runId}`).then(res => {
      if (res) {
        this.props.history.push(
          `/project/${res.project_id}/project-workflow/${res.project_workflow_id}`
        );
      } else {
        console.log('something went wrong');
        this.setState({ loadingRuns: false });
      }
    });
  }

  changePage(newPage) {
    this.setState({ page: newPage }, () => {
      this.loadRuns();
    });
  }

  notification(notification) {
    this.setState({
      notifications: [...this.state.notifications, notification],
    });
    setTimeout(() => {
      this.closeNotification(notification);
    }, 5000);
  }

  closeNotification(item) {
    this.setState({
      notifications: this.state.notifications.filter((value, i) => {
        return value !== item;
      }),
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Notifications
          items={this.state.notifications}
          handleClose={this.closeNotification.bind(this)}
        />
        <Grid
          item
          direction="column"
          alignItems="center"
          className={classes.container}
        >
          <Grid
            item
            component={Paper}
            className={classes.paper}
            style={{ paddingBottom: '45px' }}
          >
            <Grid container justify="space-between">
              <Grid item>
                <Button
                  onClick={() => {
                    const backUrl = `/project/${this.props.match.params.project_id}/project-workflow/${this.props.match.params.project_workflow_id}`;
                    this.props.history.push(backUrl);
                  }}
                >
                  <ArrowBackIosIcon fontSize="small" /> {'Back'}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={this.save.bind(this)}
                  classes={{ root: classes.saveButton }}
                  variant="contained"
                  color="primary"
                >
                  {this.state.saving ? <CircularProgress size={26} /> : 'Save'}
                </Button>
              </Grid>
            </Grid>
            {this.state.loading ? (
              <Loader />
            ) : (
              <Grid
                container
                spacing={1}
                justify="center"
                className={classes.grid}
              >
                <Grid item md={3}>
                  <TextField
                    classes={{ root: classes.inputField }}
                    value={this.state.dataset && this.state.dataset.name}
                    onChange={this.changeDataset.bind(this, 'name')}
                    label={'Dataset name'}
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label="Start date"
                    type="datetime-local"
                    margin="normal"
                    value={this.state.dataset.start_date}
                    InputLabelProps={{ shrink: true }}
                    onChange={e =>
                      this.changeDataset.bind(
                        this,
                        'start_date'
                      )(e.target.value)
                    }
                    classes={{ root: classes.inputField }}
                    required
                    fullWidth
                  />
                </Grid>
                &nbsp;
                <Grid item md={3}>
                  <TextField
                    label="End date"
                    type="datetime-local"
                    margin="normal"
                    value={this.state.dataset.stop_date}
                    InputLabelProps={{ shrink: true }}
                    onChange={e =>
                      this.changeDataset.bind(this, 'stop_date')(e.target.value)
                    }
                    fullWidth
                    required
                    classes={{ root: classes.inputField }}
                    inputProps={{ step: 300 }}
                    disabled={this.state.dataset.run_indefinitely}
                  />
                  <Checkbox
                    checked={this.state.dataset.run_indefinitely}
                    onChange={e => {
                      const dataset = this.state.dataset;
                      dataset.run_indefinitely = e.target.checked;
                      this.setState({ dataset });
                    }}
                  />
                  <Typography display="inline">Run indefinitely</Typography>
                </Grid>
                <Grid item md={9}>
                  <Typography className={classes.inputField}>
                    Interval
                  </Typography>
                  <Grid container>
                    {[1, 2, 3, 4, 5, 6, 7].map(n => {
                      return (
                        <Grid item md={3} key={n}>
                          <Checkbox
                            checked={
                              !!this.state.dataset.interval_day &&
                              this.state.dataset.interval_day
                                .split(',')
                                .indexOf('' + n) >= 0
                            }
                            onChange={this.changeIntervalDay.bind(this, n)}
                          />
                          {moment().day(n).format('dddd')}
                        </Grid>
                      );
                    })}
                    <Grid item md={3}>
                      <TextField
                        label="Run time"
                        type="time"
                        margin="normal"
                        value={this.state.dataset.interval_hour}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 300 }}
                        onChange={e =>
                          this.changeDataset.bind(this, 'interval_hour')(e)
                        }
                        classes={{ root: classes.inputField }}
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {this.state.dataset && this.state.dataset.id && (
            <Grid
              item
              component={Paper}
              className={classes.paper}
              style={{ marginTop: '15px' }}
            >
              <Grid container justify={'space-between'}>
                <h3>Runs</h3>
                <Typography style={{ textAlign: 'right' }}>
                  <Button
                    onClick={this.runDataset.bind(this)}
                    variant="outlined"
                  >
                    {this.state.running ? (
                      <CircularProgress size={26} />
                    ) : (
                      'Run a dataset'
                    )}
                  </Button>
                </Typography>
              </Grid>
              {this.state.loadingRuns ? (
                <Loader />
              ) : (
                <Table size="small">
                  <colgroup>
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '10%' }} />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell align="center">Execution</TableCell>
                      <TableCell align="center">Table</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center"> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.runs.length ? (
                      this.state.runs.map(row => (
                        <TableRow key={row.id}>
                          <TableCell>
                            {moment(row.created_at)
                              .tz(this.state.timezone)
                              .format('MMM Do YYYY h:mm A')}
                          </TableCell>
                          <TableCell align="center">
                            <Link
                              href={row.execution_link}
                              target={'_blank'}
                              className={classes.linkHover}
                            >
                              <Typography className={classes.runLink}>
                                Execution <OpenInNew fontSize="small" />
                              </Typography>
                            </Link>
                          </TableCell>
                          <TableCell align="center">
                            <span
                              onClick={() =>
                                this.props.history.push(
                                  `/dataset-run/${row.table_name}`
                                )
                              }
                              className={classes.linkHover}
                              style={{ cursor: 'pointer' }}
                            >
                              <Typography className={classes.runLink}>
                                table <OpenInNew fontSize="small" />
                              </Typography>
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            {this.statuses[row.status]
                              ? this.statuses[row.status]
                              : row.status}
                          </TableCell>
                          <TableCell align="center">
                            {row.status === 'RUNNING' && (
                              <Button
                                color="primary"
                                onClick={this.cancelRun.bind(this, row.id)}
                                variant="outlined"
                                style={{ padding: '5px 10px' }}
                              >
                                <Close /> Cancel
                              </Button>
                            )}
                            {row.status === 'FAILED' && (
                              <Button
                                color="info"
                                onClick={this.recoverRun.bind(
                                  this,
                                  row.table_name
                                )}
                                variant="outlined"
                                style={{ padding: '5px 10px' }}
                              >
                                <Replay /> Recover
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <NoData />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        colSpan={6}
                        classes={{ root: classes.pagination }}
                        count={this.state.allRows}
                        rowsPerPage={PAGINATION}
                        rowsPerPageOptions={[]}
                        page={this.state.page}
                        onChangePage={newPage => this.changePage(newPage)}
                        ActionsComponent={Pagination}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              )}
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Dataset));

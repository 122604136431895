import React from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: 12,
  },
}))(MuiTooltip);

const Tooltip = props => {
  const { title } = props;

  return (
    <CustomTooltip title={title} aria-label={title} placement="bottom-end">
      <span>{props.children}</span>
    </CustomTooltip>
  );
};

export default Tooltip;

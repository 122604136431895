import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Link, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ApiGet } from '../../../Api';
import CommonStyles from '../../../utils/CommonStyles';
import Loader from '../../common/Loader';

const styles = theme => ({
  ...CommonStyles(theme),
  TopContainer: {
    marginBottom: '30px',
  },
  TopContainerTypography: {
    marginRight: '10px',
  },
  EditProjectLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    cursor: 'pointer',

    '& svg': {
      marginRight: '5px',
      width: '20px',
    },
  },
  ButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  Button: {
    marginBottom: '15px',
    width: '100%',
  },
  Link: {
    padding: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  Loader: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});
function ProjectDetails({ classes, match, history }) {
  const [projectDetails, setProjectDetails] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ApiGet(`/api/projects/projects/${match.params.project_id}`).then(res => {
      setProjectDetails({ ...res, groups: [] });
      setLoading(false);
    });
  }, [match.params.project_id]);

  return (
    <Grid className={classes.ProjectContainer} component={Paper}>
      {!loading && projectDetails ? (
        <>
          <Grid
            container
            alignItems="baseline"
            className={classes.TopContainer}
          >
            <Grid item>
              <Typography
                className={classes.TopContainerTypography}
                variant="h5"
              >
                {projectDetails.name}
              </Typography>
            </Grid>
            <Grid item>
              <Link>
                <Typography
                  className={classes.EditProjectLabel}
                  variant="h5"
                  onClick={() => history.push(`${match.url}/edit`)}
                >
                  Edit project
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.BottomContainer}>
            <Grid item xs={4} className={classes.ButtonContainer}>
              <Button
                variant="contained"
                className={classes.Button}
                onClick={() =>
                  history.push(`${match.url}/project-workflow/new`)
                }
              >
                Add a new Workflow
              </Button>
              <Button
                variant="contained"
                className={classes.Button}
                onClick={() => history.push(`${match.url}/al/new/session`)}
              >
                Add new Active Learning Model
              </Button>
            </Grid>
            <Grid item xs={4} className={classes.ButtonContainer}>
              <Button
                variant="contained"
                className={classes.Button}
                onClick={() => history.push(`${match.url}/project-workflow/`)}
              >
                View Workflows
              </Button>
              <Button
                variant="contained"
                className={classes.Button}
                onClick={() => history.push(`${match.url}/datasets/`)}
              >
                View all Datasets
              </Button>
              <Button variant="contained" disabled className={classes.Button}>
                View lexicons from Footprint tool
              </Button>
            </Grid>
            <Grid item xs={4} className={classes.ButtonContainer}>
              <Button
                variant="contained"
                className={classes.Button}
                onClick={() => history.push(`${match.url}/project-files/`)}
              >
                Project Files Management
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <div className={classes.Loader}>
          <Loader />
        </div>
      )}
    </Grid>
  );
}

export default withStyles(styles)(ProjectDetails);

import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Add, History } from '@material-ui/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import classNames from 'classnames';
import { ApiGet } from '../../../Api';
import CancelIcon from '@material-ui/icons/Cancel';
import CommonStyles from '../../../utils/CommonStyles';

const styles = theme => ({
  ...CommonStyles(theme),
  table: {
    tableLayout: 'fixed',

    '& button': {
      cursor: 'pointer',
    },
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowActive: {
    position: 'relative',
    borderLeft: `6px solid ${theme.palette.info.main}`,
  },
  changesList: {
    padding: 0,
    margin: 0,
  },
  listText: {
    margin: 0,
    display: 'flex',
    alignItems: 'flex-start',
  },
  projectDescription: {
    marginLeft: '5px',
    fontSize: '14px',
  },
  addItem: {
    color: theme.palette.info.main,
  },
  removeItem: {
    color: theme.palette.error.main,
  },
  changes: {
    padding: 0,
    cursor: 'pointer',

    '&:last-child': {
      marginBottom: 0,
    },
  },
  tag: {
    cursor: 'pointer',
  },
  cancelIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
    fill: '#d32f2f',
  },
});

const WorkflowVersionPicker = ({
  classes,
  projectId,
  workflows,
  current,
  onChange,
  disabled,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [diffData, setDiffData] = useState(undefined);

  const getDiffData = () => {
    if (dialogOpen && diffData === undefined) {
      ApiGet(`/api/projects/diff/${projectId}`).then(response => {
        setDiffData(response);
      });
    }
  };

  useEffect(getDiffData, [dialogOpen]);

  const handleChange = value => {
    setDialogOpen(false);
    onChange(value);
  };

  const renderDiffChips = data => (
    <>
      <List className={classes.changesList}>
        {data.added.map((value, idx) => (
          <ListItem
            key={idx}
            label={value}
            icon={<Add />}
            variant={'outlined'}
            className={classNames(classes.changes, classes.addItem)}
          >
            <ListItemText
              className={classes.listText}
              primary={<AddIcon fontSize="small" />}
              secondary={
                <Typography
                  component="span"
                  className={classes.projectDescription}
                >
                  {value}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      {data.removed.map((value, idx) => (
        <ListItem
          key={idx}
          label={value}
          icon={<Add />}
          variant={'outlined'}
          className={classNames(classes.changes, classes.removeItem)}
        >
          <ListItemText
            className={classes.listText}
            primary={<RemoveIcon fontSize="small" />}
            secondary={
              <Typography
                component="span"
                className={classes.projectDescription}
              >
                {value}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </>
  );

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const renderVersionRow = workflow => (
    <TableRow
      key={workflow.id}
      hover
      onClick={() => handleChange(workflow.url)}
      className={classNames(classes.tableRow, {
        [classes.tableRowActive]: current === workflow.id,
      })}
    >
      <TableCell>
        {moment(workflow.date).format('MMM Do YYYY h:mm:ss A')}
      </TableCell>
      <TableCell>{workflow.user}</TableCell>
      <TableCell>
        {diffData && diffData[workflow.id]
          ? renderDiffChips(diffData[workflow.id])
          : null}
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <Button
        variant="outlined"
        aria-controls="workflow-versions"
        aria-haspopup="true"
        startIcon={<History />}
        onClick={openDialog}
        disabled={disabled}
      >
        Project versions
      </Button>

      {dialogOpen && (
        <Dialog
          open
          fullWidth
          aria-labelledby="project-versions"
          maxWidth="md"
          onClose={closeDialog}
        >
          <DialogTitle id="project-versions" onClose={closeDialog}>
            Project versions
            <CancelIcon
              size="20"
              className={classes.cancelIcon}
              onClick={closeDialog}
            />
          </DialogTitle>
          <DialogContent dividers>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Author</TableCell>
                  <TableCell>Changes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{workflows.map(renderVersionRow)}</TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default withStyles(styles)(WorkflowVersionPicker);

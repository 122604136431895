import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  TextField,
} from '@material-ui/core';
import { InfoOutlined, GridOnOutlined } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import CreateIcon from '@material-ui/icons/Create';
import { withStyles } from '@material-ui/core/styles';
import CommonStyles from '../../../utils/CommonStyles';

const styles = theme => ({
  ...CommonStyles(theme),
  margin: {
    margin: 5,
  },
  stepType: {
    fontSize: '0.9em',
    color: '#696969',
    fontWeight: 300,
  },
  sourceDivider: {
    height: 3,
    background:
      'linear-gradient(to right, rgba(168, 143, 214, 1), rgba(168, 143, 214, 0))',
    marginBottom: 5,
  },
  appendDivider: {
    height: 3,
    background:
      'linear-gradient(to right, rgba(60, 187, 246, 1), rgba(60, 187, 246, 0))',
    marginBottom: 5,
  },
  exportDivider: {
    height: 3,
    background:
      'linear-gradient(to right, rgba(0, 204, 102, 1), rgba(0, 204, 102, 0))',
    marginBottom: 5,
  },
  stepName: {
    fontSize: '1.2em',
    fontWeight: 500,
  },
  stepDescription: {
    minHeight: 19,
    fontSize: '0.9em',
    fontWeight: 400,
  },
  stepTesting: {
    fontSize: '0.9em',
    color: '#696969',
    fontWeight: 500,
    textTransform: 'uppercase',
    position: 'absolute',
    right: 0,
  },
  inline: {
    display: 'flex',
    position: 'relative',
  },
  relative: {
    position: 'relative',
  },
  edit: {
    position: 'absolute',
    color: '#6d6d6d',
    right: 15,
    bottom: 15,
    cursor: 'pointer',
  },
  test: {
    border: '2px solid red !important',
  },
  button: {
    width: '100%',
    color: 'white',
    background: theme.palette.primary.main,
    transition: theme.transitions.create('background', { duration: '0.5s' }),
    '&:disabled': {
      background: 'lightgray !important',
    },
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  tooltip: {
    fontSize: '1.2em',
  },
  divider: {
    margin: '15px 16px',
  },
});

function Widget({
  classes,
  item,
  openWidgetInfoPage,
  openSpreadSheet,
  isStep,
  isMultiInput,
  isFirstInput,
  idx,
  onClick,
  changeStepNameHandler,
}) {
  let nestedMultiInput = isMultiInput && !isFirstInput;

  return (
    <div onClick={onClick}>
      {!nestedMultiInput && (
        <div>
          <div className={classes.inline}>
            <Typography className={classes.stepType}>{item.type}</Typography>
            {item.testing && (
              <Typography className={classes.stepTesting}>testing</Typography>
            )}
          </div>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography className={classes.stepName}>{item.name}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                onClick={() => openWidgetInfoPage(item.id)}
              >
                <InfoOutlined fontSize="small" color="action" />
              </IconButton>
            </Grid>
          </Grid>
          <Divider className={classes[`${item.type}Divider`]} />
          <Typography className={classes.stepDescription}>
            {item.description}
          </Typography>
        </div>
      )}

      {isStep && (
        <div>
          <Grid container>
            <Grid
              container
              justifyContent="space-between"
              alignItems={'flex-end'}
            >
              {item.name.includes('Spreadsheet') && (
                <IconButton
                  size="small"
                  onClick={() => openSpreadSheet(item.name)}
                >
                  <GridOnOutlined fontSize="small" color="action" />
                </IconButton>
              )}
              <Grid item>
                {(Object.keys(item.configErrors).length > 0 ||
                  Object.keys(item.mappingErrors).length > 0 ||
                  Object.keys(item.generalErrors).length > 0) && (
                  <ErrorIcon color="primary" />
                )}
              </Grid>

              {isMultiInput && (
                <Grid
                  item
                  style={
                    !nestedMultiInput ? { marginTop: 10 } : { marginTop: 10 }
                  }
                >
                  <TextField
                    label={'Name'}
                    autoComplete={'off'}
                    value={item.multi_input_name ? item.multi_input_name : ''}
                    onChange={e => changeStepNameHandler(idx, e.target.value)}
                  />
                </Grid>
              )}

              <Grid item>
                <Tooltip
                  title="Click card to edit widget options"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <CreateIcon className={classes.edit} />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default withRouter(withStyles(styles)(Widget));

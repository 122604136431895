import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  InputBase,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { ApiGet } from '../Api';
import NoData from './common/NoData';

const styles = theme => ({
  searchInput: {
    padding: theme.spacing(1, 1, 1, 5),
    transition: theme.transitions.create('width'),
    width: 'auto',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      width: 0,
    },
  },
  message: {
    position: 'absolute',
    top: '100%',
    right: 0,
    background: '#f5f5f5',
    width: '100%',
    padding: '50px 5px',
    boxShadow: '0 3px 13px -7px #000000',
  },
  autocomplete: {
    borderRadius: '0 0 5px 5px',
    background: theme.palette.grey['50'],
    boxShadow: '0 3px 13px -7px #000000',
    width: '100%',
    right: 0,
    top: '100%',
    position: 'absolute',
    overflowY: 'scroll',
  },
  projectDescription: {
    wordWrap: 'break-word',
    maxHeight: '3em',
    fontSize: '12px',
    lineHeight: '1.3em',
    overflowY: 'hidden',
    color: theme.palette.grey['700'],
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    fontSize: '14px',
    color: 'black',
  },
  loading: {
    position: 'absolute',
    top: '15%',
    right: 10,
  },
  search: {
    display: 'flex',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translate(0, -50%)',
    borderRadius: 4,
    marginRight: 2,
    marginLeft: 0,
    width: '40vw',
    backgroundColor: theme.palette.grey['100'],
    zIndex: 199,
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
      width: 'auto',

      '&:focus-within input': {
        width: 450,
      },
    },
    [theme.breakpoints.up('sm')]: {
      '&:focus-within input': {
        backgroundColor: theme.palette.grey['100'],
        cursor: 'auto',
      },
    },
  },
  searchMd: {
    marginRight: '-10px',
  },
  searchIcon: {
    margin: '0 10px',
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
});

const SUGGESTIONS_NUMBER = 5;

const AutocompleteSearch = ({ classes }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const searchInputRef = useRef(null);

  const customMQ = useMediaQuery('(min-width:960px) and (max-width: 1090px)');

  useEffect(() => setLoading(false), [suggestions]);

  const handleSearch = text => {
    setSearchText(text);
    if (text) {
      setLoading(true);
      ApiGet('/api/projects/search-projects', { search: text }).then(
        response => {
          setSuggestions(response);
        }
      );
    }
  };

  const debouncedSearch = debounce(handleSearch, 700);

  const renderSuggestions = () => {
    if (loading)
      return <CircularProgress className={classes.loading} size={20} />;
    if (!suggestions.length)
      return (
        <Typography component={'div'} className={classes.message}>
          <NoData />
        </Typography>
      );
    const height = 85;
    return (
      <List
        className={classes.autocomplete}
        dense={true}
        style={{ maxHeight: `calc(${SUGGESTIONS_NUMBER * height}px + 12px)` }}
      >
        {suggestions.map((item, index) => [
          <ListItem
            component={Link}
            to={{
              pathname: `/project/${item.project_id}/project-workflow/${item.id}`,
              state: { from: 'internal' },
            }}
            key={`${item.project_id}-${item.id}`}
            style={{ maxHeight: `${height}px` }}
          >
            <ListItemText
              component="span"
              primary={
                <Typography component="span" className={classes.link}>
                  {item.name}
                </Typography>
              }
              secondary={
                <Typography
                  component="span"
                  className={classes.projectDescription}
                >
                  {item.description}
                </Typography>
              }
            />
          </ListItem>,
          index < suggestions.length - 1 && <Divider />,
        ])}
      </List>
    );
  };

  const debouncedBlur = debounce(() => {
    setShowAutocomplete(false);
  }, 300);

  return (
    <div
      className={classNames(classes.search, { [classes.searchMd]: customMQ })}
    >
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search"
        classes={{
          input: classes.searchInput,
        }}
        onFocus={() => setShowAutocomplete(true)}
        onBlur={debouncedBlur}
        onChange={e => debouncedSearch(e.target.value)}
        inputRef={searchInputRef}
      />
      {showAutocomplete && searchText.length ? renderSuggestions() : null}
    </div>
  );
};

export default withStyles(styles)(AutocompleteSearch);

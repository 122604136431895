import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  List,
  ListItem,
  TextField,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import Loader from '../common/Loader';
import { ApiGet } from '../../Api';
import CommonStyles from '../../utils/CommonStyles';

const styles = theme => ({
  ...CommonStyles(theme),
  modal: {
    ...CommonStyles(theme).modal,
    maxHeight: '80%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  paper: {
    minHeight: '500px',
  },
  projectsList: {
    padding: 0,
    marginTop: '20p',
    overflow: 'auto',
  },
  button: {
    width: '100%',
    color: 'white',
    background: theme.palette.primary.main,
    transition: theme.transitions.create('background', { duration: '0.5s' }),
    '&:disabled': {
      background: 'lightgray !important',
    },
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  list: {
    padding: '10px 0',
  },
  cancelIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
    fill: '#d32f2f',
  },
  searchClear: {
    cursor: 'pointer',
  },
});

function ProjectListModal({
  classes,
  onClose,
  handleClick,
  setProjectId,
  projectId,
  buttonName,
}) {
  const [projectsList, setProjectsList] = useState();
  const [searchList, setSearchList] = useState();
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    ApiGet('/api/projects/projects/').then(res => {
      setProjectsList(res);
      setSearchList(res);
    });
  }, []);

  // eslint-disable-next-line
  useEffect(() => search(), [inputText]);

  const search = useCallback(() => {
    setSearchList(
      projectsList?.filter(item =>
        item.name.toLowerCase().includes(inputText.toLowerCase())
      )
    );
  }, [inputText, projectsList]);

  return (
    <Dialog
      open
      fullWidth
      aria-labelledby="project-versions"
      maxWidth="sm"
      className={classes.dialog}
      onClose={onClose}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="project-versions" onClose={onClose}>
        Please choose project
        <CancelIcon
          size="20"
          className={classes.cancelIcon}
          onClick={onClose}
        />
      </DialogTitle>
      <DialogContent dividers>
        {searchList ? (
          <>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={inputText}
              onChange={e => setInputText(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    className={classes.searchClear}
                    position="end"
                    onClick={() => setInputText('')}
                  >
                    <CloseIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
            <List className={classes.projectsList}>
              {searchList.map((value, index) => {
                return (
                  <ListItem
                    button
                    key={index}
                    className={classes.list}
                    selected={projectId === value.id}
                    onClick={() => setProjectId(value.id)}
                  >
                    {value.name}
                  </ListItem>
                );
              })}
            </List>
          </>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={!!!projectId}
          className={classes.button}
          onClick={handleClick}
        >
          {buttonName}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(ProjectListModal);

import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CommonStyles from '../../../utils/CommonStyles';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import Selector from '../../common/Selector';
import React from 'react';

const styles = theme => ({
  ...CommonStyles(theme),
  formControl: {
    margin: '8px 0',
    paddingRight: '10px',
  },
  textField: { paddingRight: '10px' },
});

function WidgetFilter({
  classes,
  filters,
  changeFilterState,
  previousReturnFields,
}) {
  return (
    <div>
      <Typography className={classes.sectionTitle}>Filter</Typography>
      <Grid>
        <Selector
          id={'ColumnSelector'}
          field="column"
          value={
            filters && filters[0] && filters[0].column ? filters[0].column : ''
          }
          onChange={changeFilterState}
          choices={previousReturnFields}
        />
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel>Operator</InputLabel>
          <Select
            value={
              filters && filters[0] && filters[0].operator
                ? filters[0].operator
                : ''
            }
            onChange={e => changeFilterState('operator', e.target.value)}
          >
            {['=', '!=', '>', '<', '>=', '<='].map((value, index) => {
              return (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          className={classes.textField}
          value={
            filters && filters[0] && filters[0].value ? filters[0].value : ''
          }
          onChange={e => changeFilterState('value', e.target.value)}
          label={'Value'}
          fullWidth
        />
      </Grid>
    </div>
  );
}

export default withRouter(withStyles(styles)(WidgetFilter));

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  ExpandMore,
  Power,
  Storage,
  Search as SearchIcon,
} from '@material-ui/icons';
import { ApiGet, ApiPost } from '../../Api';
import { KeyboardMap } from './KeyboardMap';
import CommonStyles from '../../utils/CommonStyles';
import Loader from '../common/Loader';
import TaggingSearchModal from './TaggingSearchModal';

const styles = theme => ({
  ...CommonStyles(theme),
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  select: {
    background: 'red',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  green: {
    backgroundColor: theme.palette.info.main,
    color: '#fff',
  },
  primaryBtn: {
    width: '100%',
  },
  learnBtn: {
    width: '100%',
    margin: '5px 0',

    '&.containd': {
      border: '1px solid transparent',
    },

    [theme.breakpoints.up('lg')]: {
      width: 'auto',
      margin: '5px',
    },
  },
  modelSubList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',

    '& li': {
      padding: '5px 0',
    },
  },
  modelSubListLabel: {
    fontSize: '12px',
  },
  modelSubListValue: {
    fontSize: '16px',
  },
  divider: {
    margin: '20px 0',
  },
  positionEnd: {
    marginRight: '-12px',
  },
  tabBtnContainer: {
    transition: 'all .2s linear',

    '&:focus-within': {
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
      padding: '10px',
      margin: '-10px -10px -5px',
    },
  },
  badge: {
    position: 'inherit',
    '& span': {
      top: 0,
      right: 0,
      border: '1px solid',
    },
  },
  dataset: {
    padding: '20px',
  },
  topDataset: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  btnDataset: {
    padding: '20px',
  },
});

const Tagging = ({ classes }) => {
  const [status, setStatus] = useState(null);
  const [statusLabel, setStatusLabel] = useState(null);
  const [wsStatus, setWsStatus] = useState('Disconnected');
  const [queries, setQueries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [stats, setStats] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [addLabelLoading, setAddLabelLoading] = useState(false);
  const [addLabelValue, setAddLabelValue] = useState('');
  const [pickedLabels, setPickedLabels] = useState([]);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [session, setSession] = useState([]);

  let ipAddress = undefined;
  const localhostArr = ['localhost', '127.0.0.1'];
  if (process.env.NODE_ENV === 'development') ipAddress = 'localhost:5000';
  else {
    switch (process.env.REACT_APP_STAGE) {
      case 'staging-us-east-2':
        ipAddress = 'alworker-staging.protagonist-io.com';
        break;
      case 'govcloud':
        ipAddress = 'alworker-staging.protagonist-io.com';
        break;
      case 'testing':
        ipAddress = 'alworker-testing.protagonist.io';
        break;
      case 'production':
      default:
        ipAddress = 'alworker-prod.protagonist-io.com';
        break;
    }
  }
  const wssCheck = localhostArr.includes(window.location.hostname)
    ? 'ws'
    : 'wss';
  const { project_id, al_session_id } = useParams();
  const history = useHistory();
  const ws = useRef(null);
  const timer = useRef(null);

  const initWebSocket = () => {
    setWsStatus('Connecting');
    ws.current = new WebSocket(
      `${wssCheck}://${ipAddress}/ws/session/1/?project_id=${al_session_id}`
    );

    ws.current.onerror = e => {
      setTimeout(initWebSocket, 1000);
    };

    ws.current.onopen = e => {
      setWsStatus('Connected');
      ws.current.send(JSON.stringify({ type: 'query', message: null }));
      ws.current.send(JSON.stringify({ type: 'labels', message: null }));
      ws.current.send(JSON.stringify({ type: 'stats', message: null }));
    };

    ws.current.onmessage = e => {
      let data = JSON.parse(e.data);
      switch (data.type) {
        case 'query':
          setQueries(data.message);
          break;
        case 'labels':
          setLabels(data.message);
          setAddLabelLoading(false);
          setAddLabelValue('');
          break;
        case 'info':
          if (data.message === 'model saved') setSaveLoading(false);
          break;
        case 'stats':
          updateChartData(data.message);
          break;
        default:
          break;
      }
    };

    return () => ws.current.close();
  };

  const getSessionStatus = () => {
    ApiGet(`/api/activelearning/session/${al_session_id}/`).then(response => {
      setStatus(response.status);
      setStatusLabel(response.status_label);
      setSession(response);
    });
  };

  const setTimer = () => {
    getSessionStatus();
    timer.current = setInterval(getSessionStatus, 3000);

    return () => clearInterval(timer.current);
  };

  useEffect(setTimer, [project_id, al_session_id]);

  const checkAndInitWebSocket = () => {
    if (status === 2) {
      initWebSocket();
      clearInterval(timer.current);
    }
  };

  useEffect(checkAndInitWebSocket, [project_id, al_session_id, status]);

  const updateChartData = newItem => setStats(newItem);

  const shiftQuery = () => {
    let newQueries = [...queries];
    newQueries.shift();
    setQueries(newQueries);

    if (newQueries.length === 6) {
      ws.current.send(JSON.stringify({ type: 'query', message: null }));
    }

    setPickedLabels([]);
  };

  // Teach model
  const teach = (queryIdx, queryValue, labels) => {
    const labelsIdx = labels.map(l => l.idx);
    const labelsValue = labels.map(l => l.label);

    ws.current.send(
      JSON.stringify({
        type: 'teach',
        message: {
          value: queryValue,
          idx: queryIdx,
          label: labelsIdx,
          labelValue: labelsValue,
        },
      })
    );
    shiftQuery();
  };

  const addLabel = (queryIdx, queryValue) => {
    setAddLabelLoading(true);

    ws.current.send(
      JSON.stringify({
        type: 'add_label',
        message: {
          value: queryValue,
          idx: queryIdx,
          label: addLabelValue,
        },
      })
    );

    shiftQuery();
  };

  const save = () => {
    setSaveLoading(true);
    ws.current.send(
      JSON.stringify({
        type: 'save',
        message: null,
      })
    );
  };

  const terminate = () =>
    ApiPost('/api/activelearning/session/terminate/', {
      session_id: al_session_id,
    }).then(response => {
      history.push(`/project/${project_id}/al/${al_session_id}/session`);
    });

  // Keyboard handler
  //
  const tagBtnRefs = useRef({});
  const skipBtn = useRef(null);
  const submitBtn = useRef(null);
  const globalKeyPressHandler = useCallback(
    e => {
      switch (e.key) {
        case 'Enter':
          submitBtn.current && submitBtn.current.click();
          break;
        case 'Delete':
          skipBtn.current && skipBtn.current.click();
          break;
        default:
          break;
      }

      if (e.ctrlKey && e.key === 'f') {
        e.preventDefault();
        setSearchModalOpen(true);
      }
    },
    [submitBtn]
  );

  useEffect(() => {
    document.addEventListener('keyup', globalKeyPressHandler);
    return () => {
      document.removeEventListener('keyup', globalKeyPressHandler);
    };
  }, [globalKeyPressHandler]);

  // Generate keys and connect them to labels
  const keyLabels = labels.map((item, i) => {
    return { symbol: KeyboardMap[i], item: item };
  });

  // Select labels for model
  const pickedLabelsToggle = useCallback(
    label => {
      switch (session.classifier_type) {
        case 0:
          setPickedLabels([label]);
          break;
        default:
          pickedLabels.includes(label)
            ? setPickedLabels(
                pickedLabels.filter(item => item.idx !== label.idx)
              )
            : setPickedLabels([label, ...pickedLabels]);
      }
    },
    [pickedLabels, session.classifier_type]
  );

  // highlight tag
  const keyboardHandler = event => {
    event.preventDefault();
    const keySymbol = event.key;
    const tag = keyLabels.find(key => key.symbol === keySymbol);
    tag && pickedLabelsToggle(tag.item);
  };

  // update Queries from modal search
  const updateQueries = data => {
    setQueries([...data, ...queries]);
    setSearchModalOpen(false);
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item container direction={'column'} xs={6} lg={4} xl={3}>
        <Grid item>
          <Paper className={classes.paper}>
            <div className={classes.statusContainer}>
              <Chip
                icon={<Power />}
                label={wsStatus}
                color={wsStatus === 'Connected' ? 'primary' : 'default'}
                variant={'outlined'}
              />
              <Chip
                icon={<Storage />}
                label={statusLabel}
                color={status === 2 ? 'primary' : 'default'}
                variant={'outlined'}
              />
              <Divider className={classes.divider} />
              <Button
                className={`${classes.marginBottom} ${classes.primaryBtn}`}
                onClick={save}
                variant={'contained'}
                color={'primary'}
                disabled={wsStatus !== 'Connected' || saveLoading}
              >
                {saveLoading ? <CircularProgress size={15} /> : 'Save model'}
              </Button>
              <Button
                className={`${classes.marginBottom} ${classes.primaryBtn}`}
                onClick={terminate}
                variant={'contained'}
                color={'primary'}
                disabled={wsStatus !== 'Connected' || saveLoading}
              >
                Save and terminate
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            {stats &&
              stats.map((stat, i) => (
                <Accordion key={`${stats.name}-${i}`}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>{stat.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.modelSubList}>
                      <li>
                        <Box
                          component="span"
                          fontWeight="fontWeightBold"
                          className={classes.modelSubListLabel}
                        >
                          Accuracy
                        </Box>
                        <Box
                          component="span"
                          display="block"
                          className={classes.modelSubListValue}
                        >
                          {stat.accuracy}
                        </Box>
                      </li>
                      <li>
                        <Box
                          component="span"
                          fontWeight="fontWeightBold"
                          className={classes.modelSubListLabel}
                        >
                          F1
                        </Box>
                        <Box
                          component="span"
                          display="block"
                          className={classes.modelSubListValue}
                        >
                          {stat.f1}
                        </Box>
                      </li>
                      <li>
                        <Box
                          component="span"
                          fontWeight="fontWeightBold"
                          className={classes.modelSubListLabel}
                        >
                          Loss
                        </Box>
                        <Box
                          component="span"
                          display="block"
                          className={classes.modelSubListValue}
                        >
                          {stat.loss}
                        </Box>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Paper>
        </Grid>
      </Grid>
      <Grid item container direction={'column'} xs={6} lg={8} xl={9}>
        <Grid item>
          <Paper className={classes.topDataset}>
            {queries && queries.length > 0 ? (
              <>
                <Typography className={classes.dataset}>
                  {queries[0].value}
                </Typography>
                <Button
                  className={classes.btnDataset}
                  onClick={() => setSearchModalOpen(true)}
                >
                  <SearchIcon />
                </Button>
              </>
            ) : (
              <Loader size={20} />
            )}
          </Paper>
          <TaggingSearchModal
            buttonName="Save & close"
            modalOpen={searchModalOpen}
            websocket={ws}
            queryCallback={updateQueries}
            onClose={() => setSearchModalOpen(false)}
          />
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <div className={classes.tabBtnContainer}>
              {queries && queries.length > 0 && (
                <>
                  {labels.map((label, i) => (
                    <Button
                      key={i}
                      onClick={() => pickedLabelsToggle(label)}
                      onKeyDown={keyboardHandler}
                      variant={
                        pickedLabels.filter(item => item.idx === label.idx)
                          .length > 0
                          ? 'contained'
                          : 'outlined'
                      }
                      className={classes.learnBtn}
                      data-keysymbol={KeyboardMap[i]}
                      autoFocus={i === 0}
                      focusRipple={false}
                      ref={e => (tagBtnRefs.current[i] = e)}
                    >
                      <Badge
                        badgeContent={KeyboardMap[i]}
                        color="primary"
                        className={classes.badge}
                      >
                        {label.label}
                      </Badge>
                    </Button>
                  ))}
                  {queries && queries.length <= 5 && <Loader size={20} />}
                  <Divider className={classes.divider} />
                  <Grid container justifyContent={'space-between'}>
                    <Button
                      onClick={shiftQuery}
                      variant={'contained'}
                      color={'primary'}
                      ref={skipBtn}
                    >
                      <Badge
                        badgeContent="del"
                        color="primary"
                        className={classes.badge}
                      >
                        Skip
                      </Badge>
                    </Button>
                    <TextField
                      variant={'outlined'}
                      label={'New label'}
                      onChange={e => setAddLabelValue(e.target.value)}
                      disabled={addLabelLoading}
                      value={addLabelValue}
                      size={'small'}
                      className={classes.adornedEnd}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            classes={{ positionEnd: classes.positionEnd }}
                          >
                            <Button
                              onClick={() =>
                                addLabel(queries[0].idx, queries[0].value)
                              }
                              disabled={addLabelLoading || !!!addLabelValue}
                              variant={'contained'}
                              className={classes.green}
                            >
                              Add and submit
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      onClick={() =>
                        teach(queries[0].idx, queries[0].value, pickedLabels)
                      }
                      className={classes.green}
                      variant={'contained'}
                      disabled={pickedLabels.length < 1}
                      ref={submitBtn}
                    >
                      <Badge badgeContent="enter" color="primary">
                        Submit
                      </Badge>
                    </Button>
                  </Grid>
                </>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(Tagging));

import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import {
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Link as MuiLink,
  Paper,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ArchiveIcon from '@material-ui/icons/Archive';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import { ApiGet, ApiPatch } from '../../../Api';
import { ProjectContext } from '../ProjectContext';
import CommonStyles from '../../../utils/CommonStyles';
import ListTable from './ListTable';
import Loader from '../../common/Loader';
import Tooltip from '../../common/Tooltip';
import ProjectEditModal from './Modal/ProjectEditModal';

const styles = theme => ({
  ...CommonStyles(theme),
  projectContainer: {
    ...CommonStyles(theme).ProjectContainer,
  },
  toolbarItem: {
    '& button': {
      marginRight: 10,
    },
  },
  searchClear: {
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'color .2s linear',
    willChange: 'color',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  tableCall300: {
    width: '300px',
  },
  tableCall105: {
    width: '105px',
  },
  editIcon: {
    cursor: 'pointer',
    transition: 'color .2s linear',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  arrowMergeDir: {
    display: 'inline-block',
    verticalAlign: 'middle',
    transform: 'rotate(180deg)',
  },
  mergeInfoWrapper: {
    color: 'rgba(0,0,0,.6)',
  },
  tooltipWrapper: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

function ProjectMain({ classes, match }) {
  const [allProjects, setAllProjects] = useState();
  const [projects, setProjects] = useState();
  const [editProjectId, setEditProjectId] = useState();
  const [selected, setSelected] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);

  const projectContext = useContext(ProjectContext);

  const PAGINATION = 25;

  const loadData = () => {
    ApiGet('/api/projects/projects', {
      // limit: PAGINATION,
      // offset: 0,
    }).then(response => {
      setProjects(response);
      setAllProjects(response);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const searchProject = useCallback(() => {
    allProjects &&
      setProjects(allProjects.filter(item => item.name.includes(inputSearch)));
  }, [inputSearch, allProjects]);

  useEffect(() => {
    searchProject();
  }, [searchProject]);

  const selectHandler = useCallback(
    value => {
      if (selected.includes(value)) {
        const filtered = selected.filter(item => item.id !== value.id);
        setSelected(filtered);
      } else {
        setSelected(selected => [...selected, value]);
      }
    },
    [selected]
  );

  const selectAllToggle = useCallback(() => {
    projects.length === selected.length
      ? setSelected([])
      : setSelected(projects.map(item => item));
  }, [projects, selected]);

  const closeModal = data => {
    data?.reloadData && loadData();
    setOpenModal(false);
  };

  const archiveProject = () => {
    setProcessing(true);
    if (selected.length > 0) {
      let archive = Promise.all(
        selected.map(
          async item =>
            await ApiPatch(`/api/projects/projects/${item.id}`, {
              archived: true,
            })
        )
      );
      archive.then(() => {
        loadData();
        projectContext.actions.reloadProjectList(true);
        setProcessing(false);
        setSelected([]);
      });
    }
  };

  const mergedProjects = () => {
    setProcessing(true);
    const mainProject = selected[0];

    // add suffix to project name
    const changeProjectName = () => {
      ApiPatch(`/api/projects/projects/${mainProject.id}`, {
        name: `${mainProject.name} (merged)`,
      }).then(res => {
        if (res?.status === 'ok') {
          loadData();
          projectContext.actions.reloadProjectList(true);
        }
      });
    };

    selected && // eslint-disable-next-line
      selected.map((item, index) => {
        let projectWorkflows = item.project_workflows;
        let alSessions = item.al_sessions;
        let promiseAll;

        if (projectWorkflows.length > 0 || alSessions.length > 0) {
          promiseAll = Promise.all(
            projectWorkflows.length > 0 &&
              projectWorkflows.map(async el => {
                return await ApiPatch(
                  `/api/projects/projects/${item.id}/project-workflows/${el.id}/`,
                  {
                    project_id: mainProject.id,
                  }
                );
              }),
            alSessions.length > 0 &&
              alSessions.map(async el => {
                return await ApiPatch(`/api/activelearning/session/${el.id}/`, {
                  project: mainProject.id,
                });
              })
          );

          promiseAll &&
            promiseAll.then(() => {
              // eslint-disable-next-line
              selected.map(item => {
                if (item.id !== mainProject.id) {
                  ApiPatch(`/api/projects/projects/${item.id}`, {
                    archived: true,
                  }).then(() => {
                    loadData();
                    projectContext.actions.reloadProjectList(true);
                    setProcessing(false);
                    setSelected([]);
                  });
                }
              });
            });

          selected.length - 1 === index && changeProjectName();
        }
      });
  };

  const mergeVisualization = () => {
    return (
      <>
        {selected.length > 1 && (
          <Grid item className={classes.mergeInfoWrapper}>
            Merge direction:{' '}
            {selected.map((item, index) => {
              return (
                <>
                  {item.name}
                  {selected.length - 1 !== index && (
                    <ArrowRightAltIcon className={classes.arrowMergeDir} />
                  )}
                </>
              );
            })}
          </Grid>
        )}
      </>
    );
  };

  /* Table */
  const projectTableHeaders = () => (
    <TableRow>
      <TableCell scope="row">
        <Checkbox
          color="default"
          checked={projects.length === selected.length}
          onChange={selectAllToggle}
          disabled={processing}
        />
      </TableCell>
      <TableCell className={classes.tableCall300}>Name</TableCell>
      <TableCell>Description</TableCell>
      <TableCell className={classes.tableCall105}>Created</TableCell>
      <TableCell className={classes.tableCall105}>Updated</TableCell>
      <TableCell></TableCell>
    </TableRow>
  );

  const projectTableRow = row => {
    return (
      <>
        <TableRow hover key={row.id}>
          <TableCell scope="row">
            <Checkbox
              color="default"
              checked={selected.includes(row)}
              value={row}
              onClick={() => selectHandler(row)}
              disabled={processing}
            />
          </TableCell>
          <TableCell scope="row">
            <Link
              component={MuiLink}
              to={{
                pathname: `${match.url}/${row.id}`,
                state: { from: 'project' },
              }}
            >
              {row.name}
            </Link>
          </TableCell>
          <TableCell scope="row">{row.notes}</TableCell>
          <TableCell>
            {moment(row.created_at).tz('UTC').format('MMM Do, YYYY')}
          </TableCell>
          <TableCell>
            {moment(row.updated_at).tz('UTC').format('MMM Do, YYYY')}
          </TableCell>
          <TableCell align="center">
            <EditIcon
              className={classes.editIcon}
              onClick={() => {
                setEditProjectId(row.id);
                setOpenModal(true);
              }}
            />
          </TableCell>
        </TableRow>
      </>
    );
  };
  /* end: Table */

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <Grid component={Paper} className={classes.projectContainer}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">Projects Management</Typography>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              justifyContent="space-between"
              className={classes.toolbar}
            >
              <Grid item className={classes.toolbarItem}>
                <Tooltip title="select file">
                  <span className={classes.tooltipWrapper}>
                    <Button
                      variant="outlined"
                      startIcon={<ArchiveIcon />}
                      onClick={archiveProject}
                      disabled={selected.length === 0 || processing}
                    >
                      Archive
                    </Button>
                  </span>
                </Tooltip>

                <Tooltip title="select 2 or more items">
                  <span className={classes.tooltipWrapper}>
                    <Button
                      variant="outlined"
                      startIcon={<AccountTreeIcon />}
                      onClick={mergedProjects}
                      disabled={selected.length < 2 || processing}
                    >
                      Merge
                    </Button>
                  </span>
                </Tooltip>
                {processing && (
                  <Button>
                    <Loader size={15} />
                  </Button>
                )}
              </Grid>

              <Grid item className={classes.toolbarItem}>
                <TextField
                  size="small"
                  variant="outlined"
                  value={inputSearch}
                  onChange={e => setInputSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        className={classes.searchClear}
                        position="end"
                        onClick={() => setInputSearch('')}
                      >
                        <CloseIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            {mergeVisualization()}
            <Grid item xs={12}>
              {projects && (
                <ListTable
                  classes={{ marginBot: classes.table }}
                  data={projects}
                  customTableRow={projectTableRow}
                  customTableHeaders={projectTableHeaders}
                  colSpan={6}
                  pagination={PAGINATION}
                />
              )}
            </Grid>
          </Grid>
          <ProjectEditModal
            modalOpen={openModal}
            onClose={closeModal}
            projectId={editProjectId}
          />
        </Grid>
      )}
    </>
  );
}

export default withStyles(styles)(ProjectMain);

import React from 'react';
import {
  Typography,
  Paper,
  Grid,
} from '@material-ui/core';
import {Block} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import CommonStyles from "./CommonStyles";

const styles = theme => ({
  ...CommonStyles(theme),
});

const NotFoundError = ({classes}) => (
  <>
    <Grid container
          justify='center'
          className={classes.root}
    >
      <Grid item
            xs={12}
            md={8}
            component={Paper}
            className={classes.paper}>
        <Grid container
              justify='center'
              alignItems='center'
              style={{padding: '15px 0'}}
        >
          <Block fontSize='large'/>
          <Typography>Page not found.</Typography>
        </Grid>
      </Grid>
    </Grid>
  </>
);

export default withStyles(styles)(NotFoundError);
import React, { useState, useEffect, useContext } from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  Close,
  Delete,
  PersonAdd,
  Save,
} from "@material-ui/icons";
import Loader from "../common/Loader";
import CommonStyles from "../../utils/CommonStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import { ApiGet, ApiPatch } from "../../Api";
import { NotificationContext } from "../../NotificationContext";

const styles = (theme) => ({
  ...CommonStyles(theme),
  error: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "flex-end",
    padding: "20px",
    paddingBottom: "unset",
  },
  levelSelect: {
    paddingLeft: "5px",
  },
  nameSelect: {
    paddingLeft: "5px",
    minWidth: "175px",
  },
});

const LVLS = {
  0: "Banned",
  10: "Viewer",
  20: "User",
  30: "Editor",
  40: "Maintainer",
};

function ProjectUsersModal({ classes, modalOpen, onClose, project_id }) {
  const { notification } = useContext(NotificationContext);
  const [saving, setSaving] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingProjectUsers, setLoadingProjectUsers] = useState(true);
  const [projectUsers, setProjectUsers] = useState(undefined);
  const [users, setUsers] = useState(undefined);
  const [showNewRow, setShowNewRow] = useState(false);
  const [newRow, setNewRow] = useState({
    id: null,
    first_name: null,
    last_name: null,
    level: 10,
  });
  const addPerson = () => {
    setShowNewRow(true);
  };
  const removePerson = (index) => {
    let tempProjectUsers = [...projectUsers];
    tempProjectUsers.splice(index, 1);
    setProjectUsers(tempProjectUsers);
  };
  const changeUserLevel = (index, e) => {
    if (index) {
      let tempProjectUsers = [...projectUsers];
      tempProjectUsers[index] = {
        ...projectUsers[index],
        level: Number(e.target.value),
      };
      setProjectUsers(tempProjectUsers);
    } else {
      let tempNewRow = { ...newRow, level: Number(e.target.value) };
      setNewRow(tempNewRow);
    }
  };
  const setNewUser = (e) => {
    const user = users.find((item) => item.id === e.target.value);
    setNewRow(user);
  };
  const savePerson = () => {
    let tempProjectUsers = [...projectUsers];
    const tempNewUser = {
      level: newRow.level,
      user: {
        id: newRow.id,
        first_name: newRow.first_name,
        last_name: newRow.last_name,
      },
    };
    tempProjectUsers.push(tempNewUser);
    setProjectUsers(tempProjectUsers);
    clearNewRow();
  };
  const clearNewRow = () => {
    const tempNewRow = {
      id: null,
      first_name: null,
      last_name: null,
      level: 10,
    };
    setNewRow(tempNewRow);
    setShowNewRow(false);
  };

  const availableUsers = () => {
    const projectUsersIds = Object.values(projectUsers).map(
      (projectUser) => projectUser.user.id
    );
    users.sort((a, b) => {
      let keyA = a.last_name;
      let keyB = b.last_name;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return Object.values(users).filter(
      (user) => projectUsersIds.indexOf(user.id) < 0
    );
  };

  const save = () => {
    setSaving(true);
    ApiPatch(
      `/api/projects/projects/${project_id}/project-group/`,
      projectUsers
    ).then((res) => {
      if (res && res.status === "ok") {
        notification({
          type: "success",
          message: "Successfully updated users",
        });
        setSaving(false);
      }
    });
  };

  useEffect(() => {
    ApiGet(`/users/users/`).then((res) => {
      setUsers(res);
      setLoadingUsers(false);
    });
    ApiGet(`/api/projects/projects/${project_id}/project-group/`).then(
      (res) => {
        setProjectUsers(res[0].project_users);
        setLoadingProjectUsers(false);
      }
    );
  }, []); // eslint-disable-line
  return (
    <Modal open={modalOpen} onClose={onClose}>
      <Grid container className={classes.modal} direction="column">
        <Grid item xs={12} style={{ display: "grid" }}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography variant="h6">Project users</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => addPerson()}>
                <PersonAdd fontSize="large" color="primary" />
              </IconButton>
              {saving ? (
                <IconButton disabled>
                  <CircularProgress size={26} color="primary" />
                </IconButton>
              ) : (
                <IconButton onClick={() => save()}>
                  <Save fontSize="large" color="primary" />
                </IconButton>
              )}
              <IconButton onClick={() => onClose()}>
                <Close fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        {loadingUsers || loadingProjectUsers ? (
          <Loader />
        ) : (
          <Grid item md={12}>
            <Grid container>
              <Grid item md={12}>
                <Table style={{ marginBottom: "1px" }} size="small">
                  <colgroup>
                    <col style={{ width: "50%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "25%" }} />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell style={{ paddingLeft: "25px" }}>
                        Access Level
                      </TableCell>
                      <TableCell align="center">Remove</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectUsers.map((row, index) => {
                      const user = users.find(
                        (elem) => elem.id === row.user.id
                      );
                      const editDisabled = row.level === 40;
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {user.last_name}, {user.first_name}
                          </TableCell>
                          <TableCell>
                            <Select
                              value={row.level}
                              margin="dense"
                              fullWidth
                              disabled={editDisabled}
                              onChange={(e) => changeUserLevel(index, e)}
                              classes={{ root: classes.levelSelect }}
                            >
                              {Object.keys(LVLS).map((lvl, lvlId) => (
                                <MenuItem value={lvl} key={lvlId}>
                                  {LVLS[lvl]}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>
                          <TableCell align="center">
                            {editDisabled ? (
                              <IconButton disabled>
                                <Delete />
                              </IconButton>
                            ) : (
                              <IconButton onClick={() => removePerson(index)}>
                                <Delete color="primary" />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {showNewRow ? (
                      <TableRow key="new_user">
                        <TableCell>
                          <Select
                            value={newRow.id || ""}
                            margin="dense"
                            onChange={(e) => setNewUser(e)}
                            classes={{ root: classes.nameSelect }}
                          >
                            {availableUsers().map((user) => (
                              <MenuItem value={user.id} key={user.id}>
                                {user.last_name}, {user.first_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Select
                            value={newRow.level}
                            margin="dense"
                            fullWidth
                            onChange={(e) => changeUserLevel(false, e)}
                            classes={{ root: classes.levelSelect }}
                          >
                            {Object.keys(LVLS).map((lvl, lvlId) => (
                              <MenuItem value={lvl} key={lvlId}>
                                {LVLS[lvl]}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell align="center">
                          {newRow.id ? (
                            <IconButton onClick={() => savePerson()}>
                              <Save color="primary" />
                            </IconButton>
                          ) : (
                            <IconButton disabled>
                              <Save />
                            </IconButton>
                          )}
                          <IconButton onClick={() => clearNewRow()}>
                            <Close color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </Grid>
              {/*{this.state.notification && (*/}
              {/*  <Grid item xs={10} className={classes.error}>*/}
              {/*    <PriorityHigh />*/}
              {/*    <span>{this.state.notification.message}</span>*/}
              {/*  </Grid>*/}
              {/*)}*/}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
}

export default withStyles(styles)(ProjectUsersModal);

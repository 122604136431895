import React, { Component } from 'react';
import { ApiGet } from '../Api';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableFooter,
  TablePagination,
  Grid,
} from '@material-ui/core';
import { Check, Launch } from '@material-ui/icons';
import moment from 'moment';
import CommonStyles from '../utils/CommonStyles';
import Loader from './common/Loader';
import NoData from './common/NoData';
import Pagination from './common/Pagination';

const PAGINATION = 10;

const styles = theme => ({
  ...CommonStyles(theme),
  root: {
    ...CommonStyles(theme).root,
    display: 'flex',
    padding: '20px',
  },
  title: {
    textTransform: 'uppercase',
  },
  head: {
    fontWeight: 'bold',
  },
  projectLink: {
    textDecoration: 'underscore',
    color: 'black',
    opacity: '0.6',
    fontWeight: 'bold',
    display: 'inline-block',
    transition: 'color 300ms ease-in-out',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
});

class Alive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      maxPage: 0,
      loading: true,
      datasets: [],
      allDatasets: 0,
    };
  }

  componentDidMount() {
    this.loadDatasets();
  }

  loadDatasets = () => {
    this.setState({ loading: true });
    ApiGet('/api/projects/alive', {
      limit: PAGINATION,
      offset: this.state.page * PAGINATION,
    }).then(res =>
      this.setState(
        {
          datasets: res.results,
          allDatasets: res.count,
          maxPage: Math.floor(res.count / PAGINATION),
        },
        () => this.setState({ loading: false })
      )
    );
  };

  changePage = page => this.setState({ page }, () => this.loadDatasets());

  render() {
    const { classes } = this.props;

    return (
      <Grid container justifyContent="center" className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography
              className={classes.title}
              color="secondary"
              gutterBottom
            >
              Scheduled datasets
            </Typography>
            {this.state.loading ? (
              <Loader />
            ) : this.state.datasets.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.head}>Name</TableCell>
                    <TableCell className={classes.head}>Project</TableCell>
                    <TableCell className={classes.head}>User</TableCell>
                    <TableCell className={classes.head}>Start date</TableCell>
                    <TableCell className={classes.head}>End date</TableCell>
                    <TableCell className={classes.head} align="center">
                      Run interval
                    </TableCell>
                    <TableCell className={classes.head} align="center">
                      Run indefinitely
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.datasets.map((dataset, idx) => (
                    <TableRow
                      key={idx}
                      hover
                      component={Link}
                      to={{
                        pathname: `/project/${dataset.project.id}/dataset/${dataset.id}`,
                        state: { from: 'alive' },
                      }}
                      className={classes.tableRow}
                    >
                      <TableCell>{dataset.name}</TableCell>
                      <TableCell component="td">
                        <Link
                          className={classes.projectLink}
                          to={{
                            pathname: `/project/${dataset.project.id}/`,
                            state: { from: 'alive' },
                          }}
                        >
                          {dataset.project.name}
                          <Launch
                            fontSize="small"
                            style={{ verticalAlign: 'sub' }}
                          />
                        </Link>
                      </TableCell>
                      <TableCell>{dataset.user}</TableCell>
                      <TableCell>
                        {moment(dataset.start_date).format(
                          'MMM Do YYYY h:mm:ss A'
                        )}
                      </TableCell>
                      <TableCell>
                        {dataset.stop_date
                          ? moment(dataset.stop_date).format(
                              'MMM Do YYYY h:mm:ss A'
                            )
                          : 'n/a'}
                      </TableCell>
                      <TableCell align="center">
                        {dataset.run_interval}
                      </TableCell>
                      <TableCell align="center">
                        {dataset.run_indefinitely ? <Check /> : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={7}
                      classes={{ root: classes.pagination }}
                      count={this.state.allDatasets}
                      rowsPerPage={PAGINATION}
                      rowsPerPageOptions={[]}
                      page={this.state.page}
                      onPageChange={newPage => this.changePage(newPage)}
                      ActionsComponent={Pagination}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            ) : (
              <NoData />
            )}
            <div className={classes.spacer} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(Alive));

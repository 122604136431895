import React, { useEffect, useState, useRef, useContext } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  ViewArray as ViewArrayIcon,
  Equalizer as EqualizerIcon,
} from '@material-ui/icons';
import CommonStyles from '../../utils/CommonStyles';
import { ApiGet, ApiPost, ApiPut } from '../../Api';
import Loader from '../common/Loader';
import WidgetInput from '../WidgetInput/WidgetInput';
import DataPreview from './DataPreview';
import Notifications from '../common/Notifications';
import { ProjectContext } from '../Project/ProjectContext';

const styles = theme => ({
  ...CommonStyles(theme),
  select: {
    background: 'red',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: '15px',
    },
  },
  tabs: {
    paddingBottom: '10px',
  },
  tab: {
    marginRight: '20px',
  },
  tabDivider: {
    marginBottom: '10px',
  },
  projectTitle: {
    marginBottom: '20px',
    color: `rgba(0, 0, 0, 0.87)`,
  },
});

const Session = ({ classes }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [previewHeaders, setPreviewHeaders] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [multiselect, setMultiselect] = useState([]);
  const [dataPath, setDataPath] = useState('');

  const { project_id, al_session_id } = useParams();
  const history = useHistory();
  const firstLoading = useRef(true);
  const projectContext = useContext(ProjectContext);

  const fileEndpoint = `/api/projects/projects/${project_id}/project-files/`;

  const classifierTypes = [
    {
      value: 0,
      label: 'Single Label',
    },
    {
      value: 1,
      label: 'Multi Label',
    },
  ];

  const sessionTypes = [
    {
      value: 0,
      label: 'Classifier',
    },
    {
      value: 1,
      label: 'Sentiment',
    },
  ];

  const dataTypes = [
    { value: 0, label: 'Narrative' },
    { value: 1, label: 'Relevance' },
    { value: 2, label: 'News' },
    { value: 3, label: 'Social Media' },
    { value: 4, label: 'Survey' },
  ];

  const neededProjectAttributes = [
    'batch_job_id',
    'classifier_type',
    'data_label_column',
    'data_metadata_column',
    'data_path',
    'data_path_id',
    'data_text_column',
    'data_type',
    'id',
    'name',
    'notes',
    'session_type',
    'status',
    'status_label',
  ];
  const getData = () => {
    if (al_session_id !== 'new') {
      ApiGet(`/api/activelearning/session/${al_session_id}/`).then(response => {
        if (response.classifier_type === 1) {
          let labelTmp = response.data_label_column.split('\n');
          labelTmp = labelTmp?.map(item => parseInt(item));
          response.data_label_column = labelTmp;
        }
        const tmpResponse = {};
        neededProjectAttributes.map(
          attr => (tmpResponse[attr] = response[attr])
        );
        setSession(tmpResponse);
        if (response.classifier_type === 1) {
          let multiselectTmp = response.data_label_column.filter(
            item => !isNaN(item)
          );
          multiselectTmp = [...new Set(multiselectTmp)];
          setMultiselect(multiselectTmp);
        }
      });
    } else {
      setSession({ project: project_id });
    }

    setLoading(false);

    return () => {
      setLoading(true);
      setSession(null);
    };
  };

  const setAttribute = (name, value) => {
    let newSession = { ...session };
    newSession[name] = value;
    setSession(newSession);
  };

  useEffect(getData, [project_id, al_session_id]);

  useEffect(() => {
    multiselect.length > 0 &&
      setAttribute('data_label_column', multiselect.join('\n'));
    // eslint-disable-next-line
  }, [multiselect]);

  /* eslint-disable */
  useEffect(() => {
    session && !firstLoading.current && validateDataFile();
  }, [
    session?.data_label_column,
    session?.data_text_column,
    session?.data_metadata_column,
  ]);
  /* eslint-enable */

  const labelChangeHandler = (value, name = '') =>
    session.classifier_type === 1
      ? setMultiselect(value)
      : setAttribute(name, value.toString());

  const saveSession = () => {
    setSaveLoading(true);
    if (al_session_id !== 'new') {
      ApiPut(`/api/activelearning/session/${al_session_id}/`, session).then(
        () => {
          setSaveLoading(false);
          projectContext.actions.reloadProjectList(true);
        }
      );
    } else {
      ApiPost(`/api/activelearning/session/`, session).then(response => {
        setSaveLoading(false);
        projectContext.actions.reloadProjectList(true);
        history.push(`/project/${project_id}/al/${response.id}/session`);
      });
    }
  };

  const startSession = () =>
    ApiPost('/api/activelearning/session/start/', {
      session_id: al_session_id,
    }).then(r => {
      history.push(`/project/${project_id}/al/${al_session_id}/tagging`);
    });

  const [errors, setError] = useState({
    name: false,
    notes: false,
    validateDataFile: true,
  });

  const errorHandler = (name, value) =>
    value
      ? setError({ ...errors, [name]: false })
      : setError({ ...errors, [name]: true });

  // Label validator
  const validateDataFileErrors = errors => {
    if (errors.length > 0) {
      errors.slice(0, 3).map((error, i) => {
        notification({
          type: 'error',
          message: `${error}`,
        });

        return errors;
      });
      setError({ ...errors, validateDataFile: true });
    } else {
      setError({ ...errors, validateDataFile: false });
    }
    firstLoading.current = false;
  };

  const validateDataFile = () =>
    ApiPost('/api/activelearning/validate_data_file/', {
      s3_path: dataPath || session.data_path.file,
      text_column: session.data_text_column,
      label_column: session.data_label_column,
    }).then(response => {
      if (response && response.errors.length > 0) {
        validateDataFileErrors(response.errors);
      } else {
        setError({ ...errors, validateDataFile: false });
      }
    });

  const changeDataPath = path => setDataPath(path);

  const onFileUploadFinish = file => {
    setDisabled(true);
    ApiPost(fileEndpoint, {
      file: file.urls.s3Url,
    }).then(res => {
      if (res) {
        setDisabled(false);
        setAttribute('data_path_id', res.id);
      }
    });
  };

  const notification = notification => {
    setNotifications(prevState => [...prevState, notification]);
    setTimeout(() => {
      closeNotification(notification);
    }, 5000);
  };

  const closeNotification = item =>
    setNotifications(notifications.filter(value => value !== item));

  return loading || !session ? (
    <Loader />
  ) : (
    <Grid container direction="column" wrap="nowrap">
      <Grid item xs={12}>
        <Notifications items={notifications} handleClose={closeNotification} />
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.projectTitle}>
            {al_session_id === 'new' ? 'New Active Learning' : session.name}
          </Typography>
          {session && session.id && (
            <>
              <div className={classes.tabs}>
                <Button
                  className={classes.tab}
                  to={`/project/${project_id}/al/${al_session_id}/overview`}
                  component={RouterLink}
                  variant="outlined"
                  startIcon={<ViewArrayIcon />}
                >
                  Model Data Overview
                </Button>
                <Button
                  className={classes.tab}
                  to={`/project/${project_id}/al/${al_session_id}/training-stats`}
                  component={RouterLink}
                  variant="outlined"
                  startIcon={<EqualizerIcon />}
                >
                  Training Status
                </Button>
              </div>
              <Divider light className={classes.tabDivider} />
            </>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.inputs}>
              <TextField
                label="Name*"
                defaultValue={session.name}
                name="name"
                variant={'outlined'}
                autoFocus
                error={errors.name}
                onChange={e => setAttribute('name', e.target.value)}
                onBlur={e => errorHandler(e.target.name, e.target.value)}
              />
              <TextField
                multiline
                rows={4}
                label="Notes*"
                defaultValue={session.notes}
                name="notes"
                error={errors.notes}
                onChange={e => setAttribute('notes', e.target.value)}
                variant={'outlined'}
                onBlur={e => errorHandler(e.target.name, e.target.value)}
              />
              <TextField
                select
                label="Classifier type"
                value={session.classifier_type}
                onChange={e => {
                  setAttribute('classifier_type', e.target.value);
                }}
                variant={'outlined'}
              >
                {classifierTypes.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <WidgetInput
                format={'s3file'}
                value={dataPath || session.data_path?.file}
                onChange={changeDataPath}
                onFinish={onFileUploadFinish}
                toggleUploading={setDisabled}
              />
              <Button
                onClick={saveSession}
                variant={'contained'}
                color={'primary'}
                disabled={
                  disabled ||
                  saveLoading ||
                  !session.name ||
                  !session.data_path_id ||
                  session.data_text_column === session.data_label_column ||
                  session.data_text_column === session.data_metadata_column ||
                  session.data_label_column === session.data_metadata_column
                }
              >
                {saveLoading ? <CircularProgress size={15} /> : 'Save'}
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.inputs}>
              <TextField
                select
                label="Type"
                value={session.session_type}
                onChange={e => setAttribute('session_type', e.target.value)}
                variant={'outlined'}
              >
                {sessionTypes.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label="Data type"
                value={session.data_type}
                onChange={e => setAttribute('data_type', e.target.value)}
                variant={'outlined'}
              >
                {dataTypes.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {session.data_path_id && (
                <DataPreview
                  s3Path={dataPath || session.data_path.file}
                  dataTextColumn={session.data_text_column}
                  dataLabelColumn={session.data_label_column}
                  dataHeadCallback={setPreviewHeaders}
                  errorsCallback={errors => validateDataFileErrors(errors)}
                />
              )}
              {previewHeaders.length > 0 && (
                <>
                  <TextField
                    select
                    label="Text"
                    name="text"
                    value={session.data_text_column}
                    onChange={e => {
                      setAttribute(
                        'data_text_column',
                        e.target.value.toString()
                      );
                    }}
                    variant={'outlined'}
                    required
                    error={
                      session.data_text_column || session.data_text_column === 0
                        ? false
                        : true
                    }
                  >
                    {previewHeaders.map((header, index) => (
                      <MenuItem key={`${header}-text-${index}`} value={index}>
                        {header}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    label="Label"
                    name="label"
                    variant="outlined"
                    required={true}
                    error={session.data_label_column ? false : true}
                    SelectProps={{
                      multiple: session.classifier_type === 1 ? true : false,
                      value:
                        session.classifier_type === 1
                          ? multiselect
                          : session.data_label_column
                          ? session.data_label_column
                          : [],
                      onChange: e => {
                        labelChangeHandler(e.target.value, 'data_label_column');
                      },
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                  >
                    {previewHeaders.map((header, index) => (
                      <MenuItem key={`${header}-label-${index}`} value={index}>
                        {header}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    label="Metadata"
                    value={
                      session.data_metadata_column
                        ? `value-${session.data_metadata_column}`
                        : ''
                    }
                    onChange={e =>
                      setAttribute(
                        'data_metadata_column',
                        e.target.value.split('-')[1]
                      )
                    }
                    variant={'outlined'}
                  >
                    <MenuItem value="value-">&nbsp;</MenuItem>
                    {previewHeaders.map((header, index) => (
                      <MenuItem
                        key={`${header}-metadata-${index}`}
                        value={`value-${index}`}
                      >
                        {header}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {previewHeaders.length > 0 && (
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={2} className={classes.inputs}>
                <Typography>Status: {session.status_label}</Typography>
              </Grid>

              <Grid item xs={2} className={classes.inputs}>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={startSession}
                  disabled={errors.validateDataFile || session.status !== 0}
                >
                  Start
                </Button>
              </Grid>

              <Grid item xs={2} className={classes.inputs}>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => {
                    history.push(
                      `/project/${project_id}/al/${al_session_id}/tagging`
                    );
                  }}
                  disabled={session.status !== 2}
                >
                  Tagging
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default withRouter(withStyles(styles)(Session));

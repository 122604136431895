import React, { Component } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import './App.css';
import Routes from './routes';
import { blue, grey, red, pink, green } from '@material-ui/core/colors';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-97462393-2');

const theme = createTheme({
  palette: {
    secondary: {
      main: pink[500],
    },
    primary: {
      main: red[700],
    },
    success: {
      main: blue[700],
    },
    info: {
      main: green[700],
    },
    neutral: {
      main: grey[200],
      text: grey[600],
      medium: grey[400],
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Lato"', 'sans-serif'].join(','),
  },
});

class App extends Component {
  render() {
    return (
      <div style={{ display: 'flex', flexFlow: 'column', height: '100vh' }}>
        <MuiThemeProvider theme={theme}>
          <Routes />
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;

import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  ArrowBackIos as ArrowBackIosIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons/';
import { withStyles } from '@material-ui/core/styles';
import { ApiGet } from '../../Api';
import CommonStyles from '../../utils/CommonStyles';
import Loader from '../common/Loader';
import NoData from '../common/NoData';

const styles = theme => ({
  ...CommonStyles(theme),
  expanded: {
    background: 'rgba(245, 245, 245, .3)',
  },
  table: {
    tableLayout: 'fixed',
  },
  tableTitle: {
    margin: '20px 0',
    fontSize: '16px',
    fontWeight: 600,
  },
  tableCell: {
    color: 'rgba(0, 0, 0, .54)',
  },
  matrixTable: {
    position: 'relative',
    overflow: 'hidden',
    zIndex: 0,

    '& td': {
      padding: '10px',
      position: 'relative',
    },

    '& td:not(:first-child):hover::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: '#f5f5f5',
      left: 0,
      top: '-5000px',
      height: '10000px',
      width: '100%',
      zIndex: '-1',
    },
  },
  rotateTh: {
    position: 'relative',
    height: '140px',
    textAlign: 'center',
    verticalAlign: 'bottom',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  rotateText: {
    position: 'relative',
    width: '30px',
    display: 'inline-block',
    transform: 'rotate(-90deg)',
    verticalAlign: 'bottom',
    zIndex: 10,
  },
  matrixTextDiagonal: {
    fontSize: '18px',
    fontWeight: 600,
    color: theme.palette.info.light,
  },
  matrixWarningDiagonal: {
    fontSize: '18px',
    fontWeight: 600,
    color: theme.palette.error.light,
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',

    '& button': {
      marginRight: '10px',
    },
  },
  statList: {
    position: 'relative',
    flex: 1,
    margin: '0',
    marginRight: '20px',
    padding: 0,
    listStyle: 'none',
    width: '100%',
    marginBottom: '20px',

    '& li': {
      padding: '10px 0',
      marginRight: '20px',
      fontSize: '16px',
      background: 'transparent',
    },
  },
  itemName: {
    display: 'inline-block',
    fontWeight: 600,
    marginRight: '10px',
  },
  flexItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '16px',
    margin: '20px 0',
    padding: '20px 0',
    borderTop: '1px solid',
    borderBottom: '1px solid',
  },
  flexFullWidthLg: {
    width: '100%',
  },
  flexItemName: {
    fontWeight: 600,
    marginBottom: '10px',
  },
  flex1: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  divider: {
    margin: '30px 0',
  },
  classificationWrapper: {
    width: '100%',
  },
  classificationItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '5px 0',
    textAlign: 'center',

    '&:hover': {
      background: '#f5f5f5',
    },

    '& span': {
      display: 'inline-block',
      width: 'auto',
      whiteSpace: 'nowrap',
      flex: 1,
    },
  },
  classificationFirstCol: {
    whiteSpace: 'normal !important',
    textAlign: 'left',
  },
  classificationMatrix: {
    borderTop: '1px solid rgba(0, 0, 0, .1)',
    borderBottom: '1px solid rgba(0, 0, 0, .1)',
    padding: '10px 0',
    margin: '10px 0',
  },
  fullWidth: {
    width: '100%',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',

    '& span': {
      flex: 2,

      '&:last-child': {
        display: 'none',
        flex: 1,
        [theme.breakpoints.up('md')]: {
          display: 'block',
        },
      },
    },
  },
  f1Score: {
    fontSize: '14px',
  },
});

function TrainingStats({ classes }) {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeAccordion, setActiveAccordion] = React.useState(false);

  const { project_id, al_session_id } = useParams();
  const history = useHistory();

  const flexItemMq = useMediaQuery('(min-width:1170px)');

  const activeAccordionHandler = panel => (event, newExpanded) => {
    setActiveAccordion(newExpanded ? panel : false);
  };

  useEffect(() => {
    ApiGet(`/api/activelearning/session/${al_session_id}`).then(res => {
      setResults(res.stats);
      setIsLoading(false);
    });
  }, [al_session_id]);

  const generateMatrix = (matrix, labels) => (
    <>
      <div className={classes.tableTitle}>Confusion matrix</div>
      <Table className={classes.matrixTable}>
        <TableBody>
          <TableRow>
            {labels &&
              labels.map((label, index) => (
                <>
                  {index === 0 && <TableCell></TableCell>}
                  <TableCell className={classes.rotateTh}>
                    <span className={classes.rotateText}>{label}</span>
                  </TableCell>
                </>
              ))}
          </TableRow>
          {matrix &&
            matrix.length > 0 &&
            matrix.map((items, index) => (
              <TableRow hover key={index}>
                <TableCell>{labels && labels[index]}</TableCell>
                {items.map((item, id) => (
                  <>
                    <TableCell
                      key={`${id}-cell`}
                      classes={{ root: classes.tableCell }}
                      className={classNames({
                        [classes.matrixTextDiagonal]: index === id,
                      })}
                      align="center"
                    >
                      {item}
                      {index === id}
                    </TableCell>
                  </>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );

  const getClassificationReport = (item, labels) => {
    const classification = item.split('\n');

    const headers = classification[0].split(' ').filter(item => item);
    const matrix = classification
      .slice(2, 14)
      .map(item => item.split(' ').filter(el => el));
    const footer = classification
      .slice(15, classification.length - 1)
      .map(item =>
        item
          .trim()
          .split('  ')
          .filter(el => el)
      );

    return (
      <div className={classes.classification}>
        <div className={classes.classificationItem}>
          {headers.map((item, index) => (
            <>
              {index === 0 && <span></span>}
              <span>{item}</span>
            </>
          ))}
        </div>

        <div className={classes.classificationMatrix}>
          {matrix.map((item, index) => (
            <div className={classes.classificationItem}>
              {item.map((el, id) => (
                <span
                  className={classNames({
                    [classes.classificationFirstCol]: id === 0,
                  })}
                >
                  {id === 0 && labels ? labels[index] : el}
                  {id !== 0 && !labels && el}
                </span>
              ))}
            </div>
          ))}
        </div>

        {footer.map(item => (
          <div className={classes.classificationItem}>
            {item.map((el, id) => (
              <>
                {el === 'accuracy' && (
                  <>
                    <span className={classes.classificationFirstCol}>{el}</span>
                    <span></span>
                    <span></span>
                  </>
                )}
                {el !== 'accuracy' && (
                  <span
                    className={classNames({
                      [classes.classificationFirstCol]: id === 0,
                    })}
                  >
                    {el}
                  </span>
                )}
              </>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const generateStatsSections = useCallback(
    () => (
      <div>
        {results.map((item, index) => (
          <Accordion
            classes={{
              expanded: classes.expanded,
            }}
            key={index}
            expanded={activeAccordion === `panel-${index}`}
            onChange={activeAccordionHandler(`panel-${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${index}-content`}
              id={`panel-${index}-header`}
            >
              <Typography className={classes.heading}>
                <span className={classes.headingName}>{item.name}</span>
                <span className={classes.f1Score}>
                  F1 Score: <b>{item.f1}</b>
                </span>
                <span></span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.fullWidth}>
                <div
                  className={classNames(classes.flexItem, {
                    [classes.flexItemLg]: flexItemMq,
                  })}
                >
                  <ul
                    className={classNames(classes.statList, {
                      [classes.statListLg]: flexItemMq,
                      [classes.flexFullWidthLg]: flexItemMq,
                    })}
                  >
                    <li className={classes.item}>
                      <span className={classes.itemName}>accuracy:</span>
                      {item.accuracy}
                    </li>
                    <li className={classes.item}>
                      <span className={classes.itemName}>loss:</span>
                      {item.loss}
                    </li>
                  </ul>

                  <div
                    className={classNames(
                      classes.flex2,
                      classes.classificationWrapper,
                      {
                        [classes.flexFullWidthLg]: flexItemMq,
                      }
                    )}
                  >
                    <div className={classes.flexItemName}>
                      classification report:
                    </div>

                    <div className={classes.flex1}>
                      {getClassificationReport(
                        item.classifcation_report,
                        item.labels
                      )}
                    </div>
                  </div>
                </div>
                {item &&
                  item.confusion_matrix &&
                  generateMatrix(item.confusion_matrix, item.labels)}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    ), // eslint-disable-next-line
    [results, activeAccordion, flexItemMq]
  );

  return (
    <Grid className={classes.ProjectContainer} component={Paper}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <div className={classes.title}>
            <Button
              onClick={() => {
                const backUrl = `/project/${project_id}/al/${al_session_id}/session`;
                history.push(backUrl);
              }}
            >
              <ArrowBackIosIcon fontSize="small" /> {'Back'}
            </Button>
            <Typography
              style={{ textTransform: 'uppercase' }}
              color="secondary"
            >
              Training Status
            </Typography>
          </div>
          <Grid container>
            <Grid item xs={12}>
              {isLoading && <Loader />}
              {results && results.length > 0 && generateStatsSections()}
              {!results && <NoData />}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default withStyles(styles)(TrainingStats);

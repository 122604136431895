import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  HashRouter,
  Switch,
  useLocation,
} from 'react-router-dom';
import Login from './components/Login';
import Main from './components/Main';
import ScrollToTop from './components/ScrollTop';
import Project from './components/Project/Project/Project';
import DatasetRun from './components/DatasetRun';
import Profile from './components/Profile';
import Alive from './components/Alive';
import DataSearch from './components/DataSearch/DataSearch';
import DataSearchHistory from './components/DataSearch/DataSearchHistory';
import AllProjects from './components/AllProjects';
import NotFoundError from './utils/NotFoundError';
import Topbar from './components/Topbar';
import Footer from './components/Footer';
import { CssBaseline } from '@material-ui/core';
import API_URL from './Api';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';
import Birthday from './components/Birthday';
import { NotificationContext } from './NotificationContext';
import Notifications from './components/common/Notifications';

const cookies = new Cookies();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      cookies.get('token') ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);

const Admin = () => (window.location.href = `${API_URL}/admin`);

const RouterSwitch = () => {
  const location = useLocation();
  useEffect(
    () => ReactGA.pageview(location.pathname + location.search),
    [location]
  );

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <PrivateRoute exact path="/" component={Main} />
      <PrivateRoute path="/dataset-run/:tableName" component={DatasetRun} />
      <PrivateRoute path="/project" component={Project} />
      <PrivateRoute
        path="/templates"
        component={props => <AllProjects templatesOnly={true} {...props} />}
      />
      <PrivateRoute path="/profile/" component={Profile} />
      <PrivateRoute path="/alive/" component={Alive} />
      <PrivateRoute path="/data-search" component={DataSearch} />
      <PrivateRoute path="/data-search-history" component={DataSearchHistory} />
      <PrivateRoute path="/all-workflows" component={AllProjects} />
      <PrivateRoute path="/admin/" component={Admin} />
      <PrivateRoute path="*" component={NotFoundError} status={404} />
    </Switch>
  );
};

const Router = () => {
  const [notifications, setNotifications] = useState([]);
  const notification = notification => {
    setNotifications(prevState => [...prevState, notification]);
    setTimeout(() => {
      closeNotification(notification);
    }, 5000);
  };
  const closeNotification = item => {
    setNotifications(notifications.filter((value, i) => value !== item));
  };

  return (
    <HashRouter>
      <NotificationContext.Provider value={{ notification }}>
        <Notifications items={notifications} handleClose={closeNotification} />
        <ScrollToTop>
          <Birthday />
          <CssBaseline />
          <Topbar />
          <RouterSwitch />
          <Footer />
        </ScrollToTop>
      </NotificationContext.Provider>
    </HashRouter>
  );
};

export default Router;

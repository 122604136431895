import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ApiGet } from '../Api';
import CommonStyles from '../utils/CommonStyles';
import Loader from './common/Loader';
import NoData from './common/NoData';
import Pagination from './common/Pagination';

const styles = theme => ({
  ...CommonStyles(theme),
  table: {
    overflowX: 'auto',
  },
  json: {
    width: 'fit-content',
    fontWeight: '600',
    color: theme.palette.neutral.text,
    textDecoration: 'underline',
  },
});

const DatasetRun = ({ classes, match }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [columnTypes, setColumnTypes] = useState({});
  const [error, setError] = useState(undefined);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [tableWidth, setTableWidth] = useState('');

  const getData = () => {
    ApiGet(`/api/projects/dataset-current-state/${match.params.tableName}`, {
      limit: itemsPerPage,
      offset: page * itemsPerPage,
    }).then(response => {
      if (response.ok) {
        setData(response.result);
        setDataCount(response.count);
        setColumnTypes(response.column_types);
        setError(undefined);
      } else {
        setData([]);
        setDataCount(0);
        setColumnTypes({});
        setError(response.error);
      }
      setLoading(false);
    });
  };

  const getColumns = () => {
    let keys = [];
    for (const item of data) {
      for (const objectItem in item) {
        const idx = keys.indexOf(objectItem);
        if (idx < 0) keys.push(objectItem);
      }
    }
    setColumns(keys);
    getTableWidth();
  };

  const changePage = newPage => {
    setLoading(true);
    setPage(newPage);
  };

  const changeItemsPerPage = event => {
    setLoading(true);
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setLoading(true);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.tableName, page, itemsPerPage]);

  useEffect(() => {
    getColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderJson = value => (
    <Tooltip
      title={
        <Typography variant="caption">
          {JSON.stringify(value, null, '\n')}
        </Typography>
      }
      placement="right"
    >
      <Typography className={classes.json}>JSON</Typography>
    </Tooltip>
  );

  const renderObjectAsString = value => (
    <Typography className={classes.json}>
      {value.toString().toUpperCase()}
    </Typography>
  );

  const getTableWidth = () => {
    const elem = document.getElementById('container');
    const padding = Number(
      window
        .getComputedStyle(elem, null)
        .getPropertyValue('padding-left')
        .replace('px', '')
    );
    const width = Number(
      window
        .getComputedStyle(elem, null)
        .getPropertyValue('width')
        .replace('px', '')
    );
    setTableWidth(`${width - 2 * padding}px`);
  };

  const renderCell = cell => {
    switch (typeof cell) {
      case 'boolean':
        return renderObjectAsString(cell);
      case 'object':
        if (cell === null) {
          return renderObjectAsString('null');
        } else {
          return renderJson(cell);
        }
      default:
        return cell;
    }
  };

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={12} md={8}>
        <Paper id="container" className={classes.paper}>
          <Grid container alignItems="start" style={{ marginBottom: '15px' }}>
            <Typography
              style={{ textTransform: 'uppercase' }}
              color="secondary"
            >
              Current state of dataset run
            </Typography>
          </Grid>
          {loading ? (
            <Loader />
          ) : (
            <Table size="small">
              <div
                style={{
                  width: tableWidth,
                  overflowX: 'auto',
                  padding: '15px 0',
                }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((item, idx) => (
                      <TableCell key={idx} style={{ textAlign: 'center' }}>
                        <Typography style={{ fontWeight: 'bold' }}>
                          {item}
                        </Typography>
                        <Chip label={columnTypes[item]} variant="outlined" />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length && error === undefined ? (
                    data.map((row, row_idx) => (
                      <TableRow
                        key={`row-${row_idx}`}
                        className={classes.tableRow}
                      >
                        {columns.map((col, col_idx) => (
                          <TableCell
                            component="th"
                            scope="row"
                            key={`${row_idx}-${col_idx}`}
                            style={{
                              wordBreak: 'break-word',
                            }}
                          >
                            <div
                              style={{
                                width: '15em',
                                maxHeight: '4.5em',
                                overflowY: 'hidden',
                                lineHeight: '1.5em',
                              }}
                            >
                              {renderCell(row[col])}
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <>{error ? <>{error}</> : <NoData />}</>
                  )}
                </TableBody>
              </div>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={15}
                    classes={{ root: classes.pagination }}
                    count={dataCount}
                    page={page}
                    onPageChange={newPage => changePage(newPage)}
                    ActionsComponent={Pagination}
                    rowsPerPage={itemsPerPage}
                    rowsPerPageOptions={[10, 50, 100]}
                    onChangeRowsPerPage={changeItemsPerPage}
                  ></TablePagination>
                </TableRow>
              </TableFooter>
            </Table>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(DatasetRun));

import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import LinesEllipsis from 'react-lines-ellipsis';
import {
  Typography,
  Grid,
  Button,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import ListTable from '../Project/Project/ListTable';
import CommonStyles from '../../utils/CommonStyles';

const PAGINATION = 5;

const styles = theme => ({
  ...CommonStyles(theme),
  tooltipWrapper: {
    display: 'block',
  },
  tooltip: {
    fontSize: '14px',
  },
  tableRow: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
});

function Datasets({ classes, datasets, timezone, match, disabled }) {
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleClose = () => setOpenTooltip(false);
  const handleOpen = () => disabled && setOpenTooltip(true);

  const datasetsTableHeaders = () => (
    <TableRow>
      <TableCell>Name</TableCell>
      <TableCell>User</TableCell>
      <TableCell>Input</TableCell>
      <TableCell>Start date</TableCell>
      <TableCell>Stop date</TableCell>
      <TableCell align="center">Run interval</TableCell>
      <TableCell align="center">Run indefinitely</TableCell>
    </TableRow>
  );

  const datasetsTableRow = row => (
    <TableRow
      hover
      key={row.id}
      component={Link}
      to={{
        pathname: `${match.url}/dataset/${row.id}`,
        state: { from: 'project' },
      }}
      className={classes.tableRow}
    >
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.user}</TableCell>
      <TableCell>
        {row.input && (
          <LinesEllipsis
            text={row.input}
            maxLine={1}
            ellipsis={'...'}
            trimRight
          />
        )}
      </TableCell>
      <TableCell>
        {moment(row.start_date).tz(timezone).format('MM/DD/YYYY hh:mm A')}
      </TableCell>
      <TableCell>
        {moment(row.stop_date).tz(timezone).format('MM/DD/YYYY hh:mm A')}
      </TableCell>
      <TableCell align="center">{row.run_interval}</TableCell>
      <TableCell align="center">
        {row.run_indefinitely ? <Check /> : null}
      </TableCell>
    </TableRow>
  );

  return (
    <Grid container className={classes.marginBot}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography style={{ textTransform: 'uppercase' }} color="secondary">
            Datasets
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip
            open={openTooltip}
            onClose={handleClose}
            onOpen={handleOpen}
            classes={{ tooltip: classes.tooltip }}
            title="Save project, then create dataset"
          >
            <span className={classes.tooltipWrapper}>
              <Button
                component={Link}
                to={{
                  pathname: `${match.url}/dataset/new`,
                  state: { from: 'project' },
                }}
                variant="outlined"
                disabled={disabled}
              >
                Create dataset
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <ListTable
        classes={{ marginBot: classes.table }}
        data={datasets}
        customTableRow={datasetsTableRow}
        customTableHeaders={datasetsTableHeaders}
        colSpan={7}
        pagination={PAGINATION}
      />
    </Grid>
  );
}

export default withRouter(withStyles(styles)(Datasets));

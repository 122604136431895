import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import CommonStyles from '../../utils/CommonStyles';

const styles = theme => ({
  ...CommonStyles(theme),
  label: {
    fontSize: 10,
  },
});

const CircularProgressWithLabel = ({ classes, value, size }) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" value={value} size={size} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          className={classes.label}
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(CircularProgressWithLabel);

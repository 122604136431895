import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { HighlightOff } from '@material-ui/icons';
import Widget from './Widget';
import CommonStyles from '../../../utils/CommonStyles';
import WidgetInfo from './WidgetInfo';

const styles = theme => ({
  ...CommonStyles(theme),
  cardsContainer: {
    paddingTop: 0,
    width: '100%',
    height: '80vh',
    overflowY: 'auto',
    borderRadius: 5,
  },
  cardsContainerDrop: {
    position: 'relative',
    padding: '5px',
    border: '1px dashed rgba(0, 0, 0, 0.4)',
  },
  dropError: {
    borderColor: `${theme.palette.error.main}`,
  },
  itemDrop: {
    paddingTop: '50px !important',
  },
  infoDrop: {
    marginBottom: '20px',
  },
  selectedCard: {
    borderRight: `2px solid ${theme.palette.primary.main}`,
    margin: 5,
  },
  margin: {
    margin: 5,
  },
  filterWidgets: {
    width: 'calc(100% - 23px)',
    marginBottom: '20px',
    marginLeft: '6px',

    '& label': {
      paddingLeft: '16px',
    },

    '& input': {
      paddingLeft: '16px',
    },
  },
  relative: {
    position: 'relative',
  },
  dropPlaceholder: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
});

const WidgetDragAndDrop = ({
  classes,
  widgets,
  changeStepName,
  configuredStep,
  setConfiguredStep,
  setSteps,
  steps,
  copy,
  sortWidgets,
  saveNeeded,
}) => {
  const [groupedWidgets, setGroupedWidgets] = useState({
    source: [],
    append: [],
    export: [],
  });
  const [widgetQuery, setWidgetQuery] = useState('');
  const [widgetInfoId, setWidgetInfoId] = useState(null);
  const [widgetInfoOpen, setWidgetInfoOpen] = useState(false);

  useEffect(() => {
    if (steps !== null) {
      setGroupedWidgets({
        source: [],
        append: [],
        export: [],
      });

      steps.forEach((item, idx) => {
        setGroupedWidgets(prevState => {
          return {
            ...prevState,
            [item.type]: [...prevState[item.type], { item: item, idx: idx }],
          };
        });
      });
    }
  }, [steps]);

  const getFilteredWidgets = () => {
    let tempWidgets = [];
    if (!widgets) return tempWidgets;
    let query = widgetQuery.toLowerCase();
    tempWidgets = widgets.map((e, i) => {
      return { ...e, pos: i };
    });
    tempWidgets = tempWidgets.filter(
      e =>
        e.name.toLowerCase().includes(query) ||
        e.description.toLowerCase().includes(query)
    );
    return tempWidgets;
  };

  const remove = (source, index) => {
    const sourceClone = Array.from(source);
    sourceClone.splice(index, 1);
    return sourceClone;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = result => {
    const { source, destination } = result;
    setConfiguredStep(null);
    if (!destination) {
      if (source.droppableId === 'widgets') return null;
      else {
        const result = remove(steps, source.index);
        setSteps(result);
      }
    } else if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'widgets') return null;
      else {
        const result = reorder(steps, source.index, destination.index);
        sortWidgets(result);
        setSteps(result);
      }
    } else {
      if (source.droppableId === 'widgets') {
        let copyDestination = steps;
        if (widgets[source.index].type === 'source') {
          if (
            groupedWidgets.source[0] &&
            widgets[source.index].id !== groupedWidgets.source[0].item.id
          ) {
            const idx = steps.filter(
              elem => elem.type === widgets[source.index].type
            );
            let tempSteps = steps;
            idx.forEach(x => {
              tempSteps = remove(tempSteps, x);
            });
            copyDestination = tempSteps;
          }
        }
        const result = copy(widgets, copyDestination, source, destination);
        sortWidgets(result.steps);
        setSteps(result.steps);
      } else {
        const result = remove(steps, source.index);
        setSteps(result);
      }
    }
    saveNeeded(true);
  };

  const openWidgetInfo = widgetId => {
    setWidgetInfoId(widgetId);
    setWidgetInfoOpen(true);
  };

  const widgetFilter = (
    <TextField
      className={classes.filterWidgets}
      label="Filter widgets"
      onChange={e => setWidgetQuery(e.target.value)}
      value={widgetQuery}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="clear" onClick={() => setWidgetQuery('')}>
              <HighlightOff />
            </IconButton>
          </InputAdornment>
        ),
      }}
      autoFocus
    />
  );

  return (
    <>
      {widgetInfoOpen && (
        <WidgetInfo
          widgetId={widgetInfoId}
          onClose={() => setWidgetInfoOpen(false)}
        />
      )}
      <DragDropContext onDragEnd={e => onDragEnd(e)}>
        <Grid container spacing={5} justifyContent="space-around">
          <Grid item xs={6}>
            <Typography component={'span'}>{widgetFilter}</Typography>
            <Typography component={Droppable} droppableId="widgets">
              {provided => (
                <div ref={provided.innerRef} className={classes.cardsContainer}>
                  {getFilteredWidgets().map(item => (
                    <Draggable
                      key={`${item.name}_widgets_${item.pos}`}
                      draggableId={`${item.name}_widgets_${item.pos}`}
                      index={item.pos}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Card className={classes.margin}>
                            <CardContent>
                              <Widget
                                item={item}
                                openWidgetInfoPage={openWidgetInfo}
                              />
                            </CardContent>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.itemDrop}>
            <Typography component={Droppable} droppableId="steps">
              {provided => (
                <>
                  {(groupedWidgets.source.length === 0 ||
                    groupedWidgets.export.length === 0 ||
                    groupedWidgets.append.length === 0) && (
                    <div className={classes.infoDrop}>
                      Still needed:
                      {groupedWidgets.source.length === 0 && 'source, '}
                      {groupedWidgets.append.length === 0 && 'append, '}
                      {groupedWidgets.export.length === 0 && 'export'}
                    </div>
                  )}
                  <div
                    ref={provided.innerRef}
                    className={classNames(
                      classes.cardsContainer,
                      classes.cardsContainerDrop,
                      {
                        [classes.dropError]:
                          groupedWidgets.source.length === 0 ||
                          groupedWidgets.export.length === 0 ||
                          groupedWidgets.append.length === 0,
                      }
                    )}
                  >
                    {groupedWidgets.source.length === 0 &&
                      groupedWidgets.export.length === 0 &&
                      groupedWidgets.append.length === 0 && (
                        <Typography
                          variant="h6"
                          className={classes.dropPlaceholder}
                        >
                          Drag and Drop cards *
                        </Typography>
                      )}
                    <Card className={classes.margin}>
                      {groupedWidgets.source.map(item => (
                        <Draggable
                          key={`${item.item.name}_steps_${item.idx}`}
                          draggableId={`${item.item.name}_steps_${item.idx}`}
                          index={item.idx}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <CardContent className={classes.relative}>
                                <Widget
                                  idx={item.idx}
                                  onClick={() => setConfiguredStep(item.idx)}
                                  item={item.item}
                                  openWidgetInfoPage={openWidgetInfo}
                                  isStep
                                  isMultiInput={
                                    groupedWidgets.source.length > 1
                                  }
                                  isFirstInput={item.idx === 0}
                                  changeStepNameHandler={changeStepName}
                                />
                              </CardContent>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </Card>
                    {groupedWidgets.append
                      .concat(groupedWidgets.export)
                      .map(item => (
                        <Draggable
                          key={`${item.item.name}_steps_${item.idx}`}
                          draggableId={`${item.item.name}_steps_${item.idx}`}
                          index={item.idx}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Card
                                className={
                                  item.idx === configuredStep
                                    ? classes.selectedCard
                                    : classes.margin
                                }
                              >
                                <CardContent className={classes.relative}>
                                  <Widget
                                    onClick={() => setConfiguredStep(item.idx)}
                                    item={item.item}
                                    openWidgetInfoPage={openWidgetInfo}
                                    isStep
                                  />
                                </CardContent>
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </DragDropContext>
    </>
  );
};

export default withRouter(withStyles(styles)(WidgetDragAndDrop));

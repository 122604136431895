import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Switch, Card } from '@material-ui/core';
import { Line, HorizontalBar } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CommonStyles from '../../utils/CommonStyles';

const generateChartData = (
  label,
  xData,
  yData,
  xLabel,
  yLabel,
  xType = 'linear',
  maintainAspectRatio = true
) => {
  return {
    data: {
      labels: xData,
      datasets: [
        {
          label: label,
          data: yData,
        },
      ],
    },
    options: {
      maintainAspectRatio: maintainAspectRatio,
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: yLabel,
            },
            ticks: {
              callback: function (value) {
                if (value.length > 20) {
                  return value.substr(0, 20) + '...';
                } else {
                  return value;
                }
              },
              lineHeight: 1.2,
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: xLabel,
            },
            type: xType,
          },
        ],
      },
    },
  };
};

const styles = theme => ({
  ...CommonStyles(theme),
  verticalCharts: { height: 700 },
  padding: { padding: 10 },
});

function Statistics({ classes, data, totalEntries }) {
  const [chartTypeSwitch, setChartTypeSwitch] = useState(false);
  const [chartType, setChartType] = useState('linear');
  const [topLineNumbers, setTopLineNumbers] = useState();

  const changeChartType = value => {
    setChartType(value ? 'logarithmic' : 'linear');
    setChartTypeSwitch(value);
  };

  const articlesOverTime = useMemo(
    () =>
      generateChartData(
        'Articles over time',
        data.articles_over_time.date,
        data.articles_over_time.count,
        'Date',
        'Count',
        'time'
      ),
    [data.articles_over_time.date, data.articles_over_time.count]
  );

  const topArticles = useMemo(
    () =>
      generateChartData(
        'Top Sources',
        data.top_sources.source,
        data.top_sources.count,
        'Count',
        'Source',
        chartType,
        false
      ),
    [data.top_sources.source, data.top_sources.count, chartType]
  );

  const topAuthors = useMemo(
    () =>
      generateChartData(
        'Top Authors',
        data.top_authors.author,
        data.top_authors.count,
        'Count',
        'Author',
        chartType,
        false
      ),
    [data.top_authors.author, data.top_authors.count, chartType]
  );

  const topLineNumbersGraph = useMemo(
    () =>
      generateChartData(
        'Top Line Numbers [%]',
        data.top_sources.source,
        topLineNumbers,
        'Count',
        'Source',
        chartType,
        false
      ),
    [data.top_sources.source, topLineNumbers, chartType]
  );

  useEffect(() => {
    setTopLineNumbers(
      data.top_sources.count.map(item =>
        ((item / totalEntries) * 100).toFixed(2)
      )
    );
  }, [data.top_sources.count, totalEntries]);

  return (
    <Grid container alignContent="flex-start">
      <Grid container justify="space-evenly" className={classes.padding}>
        <Grid
          item
          component={Card}
          variant="outlined"
          xs={12}
          className={classes.padding}
        >
          <Line
            data={articlesOverTime.data}
            options={articlesOverTime.options}
          />
        </Grid>
      </Grid>
      <Grid container justify="space-evenly" className={classes.padding}>
        <Grid
          container
          component={Card}
          variant="outlined"
          alignItems="center"
          justify="center"
          spacing={1}
          className={classes.padding}
        >
          <Grid item>Linear</Grid>
          <Grid item>
            <Switch
              checked={chartTypeSwitch}
              onChange={(event, newValue) => changeChartType(newValue)}
            />
          </Grid>
          <Grid item>Logarithmic</Grid>
          <Grid
            container
            justify="space-evenly"
            className={classes.verticalCharts}
          >
            <Grid item xs={4}>
              <HorizontalBar
                data={topArticles.data}
                options={topArticles.options}
              />
            </Grid>
            <Grid item xs={4}>
              <HorizontalBar
                data={topAuthors.data}
                options={topAuthors.options}
              />
            </Grid>
            <Grid item xs={4}>
              <HorizontalBar
                data={topLineNumbersGraph.data}
                options={topLineNumbersGraph.options}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withRouter(withStyles(styles)(Statistics));

import Cookies from 'universal-cookie';

const cookies = new Cookies();

let url = undefined;
if (process.env.NODE_ENV === 'development')
  url = 'https://workflows-api-staging.protagonist-io.com';
else {
  switch (process.env.REACT_APP_STAGE) {
    case 'staging':
      url = 'https://workflows-api-stg.protagonist-io.com';
      break;
    case 'staging-us-east-2':
      url = 'https://workflows-api-staging.protagonist-io.com';
      break;
    case 'testing':
      url = 'https://wf-api.protagonist-io.com';
      break;
    case 'govcloud':
      url = 'https://7kvvdy92uj.execute-api.us-gov-west-1.amazonaws.com/govcloud';
      break;
    case 'production':
    default:
      url = 'https://workflows-api.protagonist-io.com';
      break;
  }
}

export const API_URL = url;

export function ApiRequest(endpoint, method, payload, params, isFile = false) {
  let token = cookies.get('token');
  let url = null;
  if (endpoint.startsWith('http://') || endpoint.startsWith('https://')) {
    url = endpoint;
  } else {
    url = `${API_URL}${endpoint}`;
  }
  if (!url.endsWith('/')) {
    url += '/';
  }
  if (params) {
    let str = [];
    for (let p in params) {
      if (params.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]));
      }
    }
    url += '?' + str.join('&');
  }

  const header = isFile
    ? { Authorization: `Token ${token}` }
    : {
        Authorization: `Token ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

  return fetch(url, {
    method: method,
    headers: header,
    body: payload,
    mode: 'cors',
  })
    .then(r => {
      if (r.ok) {
        return r.json();
      } else {
        throw Error(r.status);
      }
    })
    .then(response => {
      return response;
    })
    .catch(error => console.error(error));
}

export function ApiGet(endpoint, params) {
  return ApiRequest(endpoint, 'GET', undefined, params);
}

export function ApiPatch(endpoint, payload) {
  return ApiRequest(endpoint, 'PATCH', JSON.stringify(payload));
}

export function ApiPost(endpoint, payload) {
  return ApiRequest(endpoint, 'POST', JSON.stringify(payload));
}

// payload should be formData
export function ApiPostFile(endpoint, payload) {
  return ApiRequest(endpoint, 'POST', payload, undefined, true);
}

export function ApiPut(endpoint, payload) {
  return ApiRequest(endpoint, 'PUT', JSON.stringify(payload));
}

export function ApiDelete(endpoint, payload) {
  return ApiRequest(endpoint, 'DELETE', JSON.stringify(payload));
}

export default API_URL;

import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Button,
  IconButton,
  InputAdornment,
  List,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import { ApiGet } from '../../../Api';
import { ProjectContext } from '../ProjectContext';
import CommonStyles from '../../../utils/CommonStyles';
import Loader from '../../common/Loader';
import ProjectListItem from './ProjectListItem';

const styles = theme => ({
  ...CommonStyles(theme),
  projectListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
    transition: 'width .2s linear',
  },
  projectListContainerCollapsed: {
    width: '17px',
    overflow: 'hidden',
  },
  newProjectButton: {
    alignItems: 'center',
    padding: '5px 5px',
    margin: '10px',
    width: '80%',
    opacity: 1,
    transition: 'opacity .1s linear',
  },
  newProjectButtonCollapsed: {
    opacity: 0,
  },
  projectListNav: {
    padding: 0,
    width: '100%',
    overflow: 'auto',
    height: 'calc(100vh - 268px)',
    opacity: 1,
    transition: 'opacity .1s linear',
  },
  projectListNavCollapsed: {
    opacity: 0,
  },
  simpleProjectLink: {
    margin: 0,
  },
  listTitle: {
    padding: 0,
    backgroundColor: 'white',
    lineHeight: '30px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loader: {
    width: 'auto',
    marginLeft: '10px',
  },
  loaderBig: {
    opacity: 1,
    transition: 'opacity .1s linear',
  },
  loaderBigCollapsed: {
    opacity: 0,
  },
  arrowToggle: {
    height: '100%',
    padding: '1px',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
    backgroundColor: 'rgba(245,245,245,.4)',
    borderRadius: 0,
    transition: 'background .2s linear',
    willChange: 'background',

    '&:hover': {
      backgroundColor: 'rgba(245,245,245,.8)',
    },

    '& svg': {
      width: '15px',
      transition: 'transform .2s linear',
      willChange: 'transform',
    },
  },
  arrowToggleActive: {
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
  search: {
    alignItems: 'center',
    padding: '5px 5px',
    margin: '0 10px 10px',
    width: 'calc(80% + 8px)',
    opacity: 1,
    transition: 'opacity .1s linear',
  },
  searchCollapsed: {
    opacity: 0,
  },
  searchClear: {
    cursor: 'pointer',
  },
});

const PAGINATION = 15;

function ProjectList({ classes, match }) {
  const [projects, setProjects] = useState(undefined);
  const [projectsAll, setProjectsAll] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [inputText, setInputText] = useState('');

  const history = useHistory();
  const projectContext = useContext(ProjectContext);

  const handleOpen = useCallback(
    index => {
      setProjects(prevState => {
        let temp = [...prevState];
        temp.map(i => (i.open = false));
        temp[index].open = true;
        return temp;
      });
    }, // eslint-disable-next-line
    [projects]
  );

  const handleClose = useCallback(() => {
    setProjects(prevState => {
      let temp = [...prevState];
      temp.map(i => (i.open = false));
      return temp;
    }); // eslint-disable-next-line
  }, [projects]);

  const loadData = () => {
    ApiGet('/api/projects/projects', {
      limit: PAGINATION,
      offset: 0,
    }).then(response => {
      const projectId = history.location.pathname.split('/')[2];
      setHasMore(!!response.next);
      const temp = response.results.map(x => {
        if (x.id === parseInt(projectId)) {
          return { ...x, open: true };
        }
        return x;
      });
      setProjects(temp);
      setProjectsAll(temp);
      projectContext.actions.reloadProjectList(false);
      projectContext.actions.pageLoading(false);
    });
  };

  useEffect(() => {
    loadData();
    setInputText(''); // eslint-disable-next-line
  }, [projectContext.store.reloadProjectList]);

  const search = useCallback(() => {
    projects && setProjects(() => projects.map(item => (item.open = false))); // close all open project in tree
    setProjects(
      projectsAll?.filter(item =>
        item.name.toLowerCase().includes(inputText.toLowerCase())
      )
    );
  }, [inputText, projects, projectsAll]);

  // eslint-disable-next-line
  useEffect(() => search(), [inputText]);

  const fetchMoreData = () => {
    ApiGet('/api/projects/projects', {
      limit: PAGINATION,
      offset: projects.length,
    }).then(response => {
      const projectId = history.location.pathname.split('/')[2];
      setHasMore(!!response.next);
      const temp = response.results.map(x => {
        if (x.id === parseInt(projectId)) {
          return { ...x, open: true };
        }
        return x;
      });
      const temp2 = [...projects, ...temp];
      setProjects(temp2);
    });
  };

  const menuToggle = () =>
    projectContext.actions.menuCollapsed(!projectContext.store.menuCollapsed);

  return (
    <div
      className={classNames(classes.projectListContainer, {
        [classes.projectListContainerCollapsed]:
          projectContext.store.menuCollapsed,
      })}
    >
      <IconButton
        className={classNames(classes.arrowToggle, {
          [classes.arrowToggleActive]: projectContext.store.menuCollapsed,
        })}
        onClick={menuToggle}
      >
        <ChevronLeftIcon />
      </IconButton>
      <Button
        className={classNames(classes.simpleProjectLink, {
          [classes.newProjectButtonCollapsed]:
            projectContext.store.menuCollapsed,
        })}
        startIcon={<HomeIcon />}
        onClick={() => history.push(`${match.url}`)}
      >
        Projects
      </Button>
      <Button
        className={classNames(classes.newProjectButton, {
          [classes.newProjectButtonCollapsed]:
            projectContext.store.menuCollapsed,
        })}
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => history.push(`${match.url}/new`)}
      >
        New Project
      </Button>
      {projects ? (
        <>
          <TextField
            size="small"
            variant="outlined"
            value={inputText}
            className={classNames(classes.search, {
              [classes.searchCollapsed]: projectContext.store.menuCollapsed,
            })}
            onChange={e => setInputText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  className={classes.searchClear}
                  position="end"
                  onClick={() => setInputText('')}
                >
                  <CloseIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
          <List
            id="project-list-nav"
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classNames(classes.projectListNav, {
              [classes.projectListNavCollapsed]:
                projectContext.store.menuCollapsed,
            })}
          >
            <InfiniteScroll
              dataLength={projects.length}
              next={fetchMoreData}
              hasMore={hasMore}
              style={{ overflowX: 'hidden' }}
              loader={
                <h4>
                  <Loader />
                </h4>
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              scrollableTarget="project-list-nav"
            >
              {projects.map((project, index) => {
                return (
                  <ProjectListItem
                    key={index}
                    project={project}
                    index={index}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    match={match}
                    history={history}
                  />
                );
              })}
            </InfiniteScroll>
          </List>
        </>
      ) : (
        <div
          className={classNames(classes.loaderBig, {
            [classes.loaderBigCollapsed]: projectContext.store.menuCollapsed,
          })}
        >
          <Loader />
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(ProjectList);

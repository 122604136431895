import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { withStyles } from '@material-ui/core/styles';
import NoData from '../../common/NoData';
import CommonStyles from '../../../utils/CommonStyles';

const styles = theme => ({
  ...CommonStyles(theme),
  tableTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',

    '& > div': {
      flex: 1,
    },

    '& button': {
      marginRight: '10px',
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      fontSize: '12px',
      marginLeft: '10px',
    },
  },
});

const itemsPerPage = 100; //default numbers items per page

function ListTable({
  classes,
  data,
  title,
  actions,
  customTableRow,
  customTableHeaders,
  colSpan,
  pagination = itemsPerPage,
}) {
  const [page, setPage] = useState(0);
  const history = useHistory();
  const { project_id, al_session_id } = useParams();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const backUrl = () => {
    let url;
    al_session_id
      ? (url = `/project/${project_id}/al/${al_session_id}/session`)
      : (url = `/project/${project_id}`);
    console.log(project_id);
    console.log(al_session_id);
    history.push(url);
  };

  return (
    <>
      {title && (
        <div className={classes.tableTitle}>
          <div className={classes.titleWrapper}>
            <Button onClick={backUrl}>
              <ArrowBackIosIcon fontSize="small" /> {'Back'}
            </Button>
            <Typography
              style={{ textTransform: 'uppercase' }}
              color="secondary"
            >
              {title}
            </Typography>
          </div>
          {actions && <div className={classes.actionsWrapper}>{actions}</div>}
        </div>
      )}
      <Table className={classes.marginBot}>
        <TableHead>{customTableHeaders()}</TableHead>
        <TableBody>
          {data && data.length ? (
            data
              .slice(page * pagination, page * pagination + pagination)
              .map(row => customTableRow(row))
          ) : (
            <TableRow>
              <TableCell colSpan={colSpan}>
                <NoData />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {pagination && (
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={7}
                classes={{ root: classes.pagination }}
                count={data.length}
                rowsPerPage={pagination}
                rowsPerPageOptions={[]}
                page={page}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </>
  );
}

export default withStyles(styles)(ListTable);

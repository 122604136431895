import React from 'react';
import CommonStyles from '../../../utils/CommonStyles';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import moment from 'moment-timezone';
import WidgetOptionAutocomplete from './WidgetOptionAutocomplete';

const styles = theme => ({
  ...CommonStyles(theme),

  selectForm: {
    margin: '8px 0',
    width: '100%',
  },
  multiselectForm: {
    margin: '8px 0',
    width: '100%',
    maxWidth: '100%',
  },
  checkbox: {
    padding: '0 9px 0 0',
  },
});

function WidgetOptions({
  classes,
  options,
  field,
  changeStepOption,
  error,
  key,
}) {
  let element = null;
  switch (field.type) {
    case 'select':
      element = (
        <FormControl className={classes.selectForm}>
          <InputLabel>{field.name.replace(/_/g, ' ')}</InputLabel>
          <Select
            value={options[field.name] || field.default || ''}
            onChange={e => changeStepOption(field.name, e)}
            fullWidth
          >
            {field.options.map((o, idx) => (
              <MenuItem key={idx} value={o}>
                {o}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
      break;
    case 'multiselect':
      let val = options[field.name] || field.default || [];
      element = (
        <FormControl className={classes.multiselectForm}>
          <InputLabel>{field.name.replace(/_/g, ' ')}</InputLabel>
          <Select
            value={Array.isArray(val) ? val : [val]}
            onChange={e => changeStepOption(field.name, e)}
            renderValue={selected =>
              Array.isArray(selected) ? selected.join(', ') : selected
            }
            fullWidth
            multiple
          >
            {field.options.map((o, idx) => (
              <MenuItem key={idx} value={o}>
                <Checkbox
                  checked={val.indexOf(o) > -1}
                  className={classes.checkbox}
                />
                {o}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
      break;
    case 'textarea':
      element = (
        <TextField
          value={options[field.name] || field.default || ''}
          onChange={e => changeStepOption(field.name, e)}
          label={field.name.replace(/_/g, ' ')}
          multiline={true}
          fullWidth
          error={error}
          helperText={error}
        />
      );
      break;
    case 'checkbox':
      element = (
        <FormControlLabel
          label={field.name.replace(/_/g, ' ')}
          control={
            <Checkbox
              checked={
                options[field.name] !== null
                  ? options[field.name]
                  : field.default
              }
              type={field.type}
              onChange={e => changeStepOption(field.name, e.target.checked)}
            />
          }
        />
      );
      break;
    case 'text':
      element = (
        <TextField
          value={options[field.name] || field.default || ''}
          onChange={e => changeStepOption(field.name, e)}
          label={field.name.replace(/_/g, ' ')}
          fullWidth
          error={error}
          helperText={error}
        />
      );
      break;
    case 'date':
      const now = moment().format('YYYY-MM-DD');
      element = (
        <TextField
          label={field.name.replace(/_/g, ' ')}
          type="date"
          margin="normal"
          value={options[field.name] || now}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => changeStepOption(field.name, e.target.value)}
          fullWidth
          error={error}
          helperText={error}
        />
      );
      break;
    case 'autocomplete':
      element = (
        <WidgetOptionAutocomplete
          label={field.name.replace(/_/g, ' ')}
          provider={field.provider}
          onChange={e => changeStepOption(field.name, e)}
          error={error}
          value={options[field.name] || field.default || ''}
        />
      );
      break;
    default:
      element = (
        <FormControl fullWidth>
          <InputLabel>{field.name.replace(/_/g, ' ')}</InputLabel>
          <Input
            value={options[field.name] || field.default || ''}
            type={field.type}
            onChange={e => changeStepOption(field.name, e)}
            fullWidth
            error={error}
          />
        </FormControl>
      );
  }
  return (
    <Grid container direction="column" key={key}>
      <Grid item className={classes.inputField}>
        {element}
      </Grid>
      <Grid item>{field.description}</Grid>
    </Grid>
  );
}

export default withRouter(withStyles(styles)(WidgetOptions));

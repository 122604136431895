import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { IconButton } from '@material-ui/core';
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons';

const styles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.secondary,
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2),
  },
  iconButton: {
    padding: '5px',
    borderRadius: '4px',
  },
});

const Pagination = ({ count, onPageChange, page, rowsPerPage, classes }) => {
  return (
    <div className={classes.root}>
      <IconButton
        onClick={() => onPageChange(0)}
        disabled={page === 0}
        aria-label="first page"
        className={classes.iconButton}
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={() => onPageChange(page - 1)}
        disabled={page === 0}
        aria-label="previous page"
        className={classes.iconButton}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={() => onPageChange(page + 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className={classes.iconButton}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={() =>
          onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1))
        }
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        className={classes.iconButton}
      >
        <LastPage />
      </IconButton>
    </div>
  );
};

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Pagination));

import React, { useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { CallSplit } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import CommonStyles from '../../../utils/CommonStyles';
import ProjectListModal from '../../common/ProjectListModal';
import { ApiGet } from '../../../Api';
import { ProjectContext } from '../../Project/ProjectContext';

const styles = theme => ({
  ...CommonStyles(theme),
  exportButton: {
    margin: '10px',
  },
});

const ForkProjectWorkflow = ({ classes, disabled, small }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState(undefined);
  const history = useHistory();
  const params = useParams();
  const projectContext = useContext(ProjectContext);

  const forkProject = () => {
    setLoading(true);
    ApiGet(
      `/api/projects/projects/${params.project_id}/project-workflows/${params.project_workflow_id}/fork/${projectId}`
    ).then(res => {
      setLoading(false);
      history.push(
        `/project/${res.project_id}/project-workflow/${res.project_workflow_id}/`
      );
      setModalOpen(false);
      setProjectId(undefined);
      projectContext.actions.reloadProjectList(true);
    });
  };

  const smallButton = (
    <Tooltip title="Fork project" aria-label="fork">
      <IconButton
        color="primary"
        size="small"
        disabled={disabled || loading}
        onClick={() => setModalOpen(true)}
      >
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        <CallSplit className={classes.forkIcon} />
      </IconButton>
    </Tooltip>
  );

  const regularButton = (
    <Button
      variant={'outlined'}
      disabled={disabled || loading}
      startIcon={<CallSplit className={classes.forkIcon} />}
      onClick={() => setModalOpen(true)}
    >
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      Fork workflow
    </Button>
  );

  return (
    <>
      {small ? smallButton : regularButton}
      {modalOpen && (
        <ProjectListModal
          setProjectId={setProjectId}
          projectId={projectId}
          handleClick={forkProject}
          onClose={() => setModalOpen(false)}
          modalOpen={modalOpen}
          buttonName="fork workflow"
        />
      )}
    </>
  );
};

export default withStyles(styles)(ForkProjectWorkflow);

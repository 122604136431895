import React from 'react';
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Description,
  ExpandMore,
  Folder,
  FolderOpen,
  Settings,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import CommonStyles from '../../../utils/CommonStyles';

const styles = theme => ({
  ...CommonStyles(theme),
  nestedList: {
    padding: 0,
    paddingLeft: '10px',
    width: 'calc(100% - 35px)',
  },
  ListItem: {
    padding: '0 0 0 22px',
    justifyContent: 'space-between',
    transition: 'background-color .2s linear',
    cursor: 'pointer',
    position: 'relative',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& button:hover': {
      backgroundColor: 'transparent',
    },
    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      whiteSpace: 'nowrap',
    },
  },
  ListSubItem: {
    marginBottom: '10px',
    marginLeft: '7px',

    '&:last-child': {
      marginBottom: 0,
    },
    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      whiteSpace: 'nowrap',
    },
  },
  projectFolder: {
    alignItems: 'center',
    display: 'flex',
    width: 'calc(100% - 60px)',

    '& + button': {
      transition: 'transform .2s linear',
      willChange: 'transform',
    },
  },
  ListItemIcon: {
    minWidth: 'auto',
  },
  ListSubItemIcon: {
    marginRight: '10px',
  },
  Open: {
    '&::before': {
      position: 'absolute',
      top: 0,
      left: '17px',
      backgroundColor: 'rgba(0, 0, 0, .04)',
      width: '100%',
      height: '100%',
      content: '""',
    },
    '& span': {
      color: '#333',
    },
    '& + button': {
      transform: 'rotate(180deg)',
    },
  },
  button: {
    width: '100%',
    textAlign: 'left',
  },
});

function ProjectListItem({
  project,
  index,
  handleOpen,
  handleClose,
  match,
  history,
  classes,
}) {
  return (
    <div key={index}>
      <ListItem
        className={classes.ListItem}
        onClick={() => (project.open ? handleClose() : handleOpen(index))}
      >
        <div
          className={`${classes.projectFolder} ${
            project.open ? classes.Open : ''
          }`}
          onClick={() => {
            history.push(`${match.url}/${project.id}`);
          }}
        >
          <ListItemIcon className={classes.ListItemIcon}>
            {project.open ? <FolderOpen /> : <Folder />}
          </ListItemIcon>
          <IconButton className={classes.button}>
            <ListItemText primary={project.name} />
          </IconButton>
        </div>
        <IconButton>
          <ExpandMore />
        </IconButton>
      </ListItem>
      <Collapse key={index} in={project.open} timeout="auto" unmountOnExit>
        <List component="div" className={classes.nestedList}>
          {project.project_workflows.map((workflow, index) => {
            return (
              <ListItem
                key={index}
                button
                className={`${classes.ListItem} ${classes.ListSubItem}`}
                onClick={() => {
                  history.push(
                    `${match.url}/${project.id}/project-workflow/${workflow.id}`
                  );
                }}
              >
                <ListItemIcon
                  className={`${classes.ListItemIcon} ${classes.ListSubItemIcon}`}
                >
                  <Description />
                </ListItemIcon>
                <ListItemText primary={workflow.name} />
              </ListItem>
            );
          })}
          {project.al_sessions.map((session, index) => {
            return (
              <ListItem
                key={index}
                button
                className={`${classes.ListItem} ${classes.ListSubItem}`}
                onClick={() =>
                  history.push(
                    `${match.url}/${project.id}/al/${session.id}/session`
                  )
                }
              >
                <ListItemIcon
                  className={`${classes.ListItemIcon} ${classes.ListSubItemIcon}`}
                >
                  <Settings />
                </ListItemIcon>
                <ListItemText primary={session.name} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </div>
  );
}

export default withStyles(styles)(ProjectListItem);

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Grid,
  Paper,
  TableCell,
  TableRow,
  Link as MuiLink,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CommonStyles from '../../utils/CommonStyles';
import { ApiGet } from '../../Api';
import Loader from '../common/Loader';
import { NotificationContext } from '../../NotificationContext';
import ListTable from '../Project/Project/ListTable';

const styles = theme => ({
  ...CommonStyles(theme),
  checkboxCell: {
    width: '75px',
  },
});

function ProjectWorkflowList({ classes, match }) {
  const { notification } = useContext(NotificationContext);
  const [projectWorkflows, setProjectWorkflows] = useState();

  const projectWorkflowTableRow = row => {
    return (
      <TableRow hover key={row.id} className={classes.tableRow}>
        <TableCell scope="row">
          <Link
            component={MuiLink}
            to={{
              pathname: `/project/${row.project.id}/project-workflow/${row.id}`,
              state: { from: 'internal' },
            }}
          >
            {row.name}
          </Link>
        </TableCell>
        <TableCell scope="row">
          {row.created_by
            ? `${row.created_by.first_name} ${row.created_by.last_name}`
            : 'Unknown'}
        </TableCell>
        <TableCell align="right">
          {moment(row.updated_at).format('MMM Do YYYY h:mm A')}
        </TableCell>
      </TableRow>
    );
  };

  const projectWorkflowTableHeaders = () => {
    return (
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Created by</TableCell>
        <TableCell align="right">Updated at</TableCell>
      </TableRow>
    );
  };

  useEffect(() => {
    ApiGet(
      `/api/projects/projects/${match.params.project_id}/project-workflows/`
    ).then(res => {
      if (res) {
        setProjectWorkflows(res);
      } else {
        notification({
          type: 'error',
          message: 'There was some problem when fetching Workflows',
        });
      }
    });
  }, []); // eslint-disable-line
  return (
    <Grid className={classes.ProjectContainer} component={Paper}>
      {projectWorkflows ? (
        <ListTable
          data={projectWorkflows}
          title="Workflows List"
          customTableHeaders={projectWorkflowTableHeaders}
          customTableRow={projectWorkflowTableRow}
          colSpan={3}
        />
      ) : (
        <Loader />
      )}
    </Grid>
  );
}

export default withStyles(styles)(ProjectWorkflowList);

import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  NativeSelect,
  Paper,
  Typography,
} from '@material-ui/core';
import API_URL, { ApiGet, ApiPatch } from '../Api';
import CommonStyles from '../utils/CommonStyles';
import Loader from './common/Loader';

const styles = theme => ({
  ...CommonStyles(theme),
  root: {
    ...CommonStyles(theme).root,
    display: 'flex',
    padding: '20px',
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  select: {
    background: 'red',
  },
});

const Profile = ({ classes }) => {
  const [loading, setLoading] = useState(true);
  const [timezone, setTimezone] = useState('');
  const [availableTimezones, setAvailableTimezones] = useState([]);
  const [projectRunNotification, setProjectRunNotification] = useState(false);
  const [twitterAccountConnected, setTwitterAccountConnected] = useState(false);

  useEffect(() => {
    setLoading(true);
    getProfileData();
  }, []);

  const getProfileData = () => {
    ApiGet('/users/timezones')
      .then(timezones => {
        setAvailableTimezones(timezones.timezones);
      })
      .then(() => {
        ApiGet('/users/me')
          .then(profile => {
            setTimezone(profile.settings.timezone);
            setProjectRunNotification(
              profile.settings.project_run_notification
            );
            setTwitterAccountConnected(
              profile.settings.twitter_account_connected
            );
          })
          .then(() => setLoading(false));
      });
  };

  const handleTimezoneChange = event => {
    setLoading(true);
    let newTimezone = event.target.value;
    setTimezone(newTimezone);
    ApiPatch('/users/me', { settings: { timezone: newTimezone } }).then(() => {
      setLoading(false);
    });
  };

  const handleNotificationChange = event => {
    setLoading(true);
    let checked = event.target.checked;
    setProjectRunNotification(checked);
    ApiPatch('/users/me', {
      settings: { project_run_notification: checked },
    }).then(() => {
      setLoading(false);
    });
  };

  const createPopup = () => {
    let w = 600;
    let h = 400;
    let left = window.screen.width / 2 - w / 2;
    let top = window.screen.height / 2 - h / 2;

    return window.open(
      '',
      '',
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
        w +
        ', height=' +
        h +
        ', top=' +
        top +
        ', left=' +
        left
    );
  };

  const initiateTwitterAuth = () => {
    ApiGet('/users/signature/').then(response => {
      let signature = response.signature;
      let popup = createPopup();
      popup.location = `${API_URL}/login/twitter/?workflows-auth-signature=${signature}`;
      let polling = setInterval(() => {
        if (!popup || popup.closed || popup.closed === undefined) {
          clearInterval(polling);
          setLoading(true);
          getProfileData();
        }
      }, 500);
    });
  };

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography
            style={{ textTransform: 'uppercase' }}
            color="secondary"
            gutterBottom
          >
            Profile
          </Typography>
          {loading ? (
            <Loader />
          ) : (
            <form autoComplete="off">
              <FormControl
                className={classes.formControl}
                margin="normal"
                fullWidth
              >
                <InputLabel htmlFor="timezone">Timezone</InputLabel>
                <NativeSelect
                  value={timezone}
                  onChange={e => handleTimezoneChange(e)}
                >
                  {availableTimezones.map((timezone, idx) => (
                    <option key={idx} value={timezone}>
                      {timezone}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
              <FormControl
                className={classes.formControl}
                margin="normal"
                fullWidth
              >
                <FormControlLabel
                  label="Send Email about project runs"
                  control={
                    <Checkbox
                      id="project_run_notification"
                      value="project_run_notification"
                      color="primary"
                      onChange={e => handleNotificationChange(e)}
                      checked={projectRunNotification}
                    />
                  }
                />
              </FormControl>
              <FormControl
                className={classes.formControl}
                margin="normal"
                fullWidth
              >
                {twitterAccountConnected ? (
                  <Typography>Twitter account connected.</Typography>
                ) : (
                  <>
                    <Typography>Twitter account not connected.</Typography>
                    <Link onClick={initiateTwitterAuth}>
                      Connect twitter account
                    </Link>
                  </>
                )}
              </FormControl>
            </form>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(Profile));

import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  InputAdornment,
  Modal,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import CommonStyles from '../../utils/CommonStyles';
import Loader from '../common/Loader';
import NoData from '../common/NoData';

const styles = theme => ({
  ...CommonStyles(theme),
  actionLink: {
    color: theme.palette.primary.main,
  },
  searchWrapper: {
    padding: '20px 0',

    '& input': {
      paddingRight: '45px',
    },
  },
  searchResults: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 270px)',
  },
  btnSearch: {
    width: '60px',
    minWidth: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',

    '&::before': {
      position: 'absolute',
      left: 0,
      top: 0,
      height: '75%',
      width: '1px',
      content: '""',
      background: theme.palette.grey['50'],
    },
    '&::after': {
      position: 'absolute',
      left: '1px',
      top: '50%',
      transform: 'translate(0, -50%)',
      height: '75%',
      width: '1px',
      content: '""',
      background: 'rgba(0, 0, 0, 0.2)',
    },
  },
  modal: {
    ...CommonStyles(theme).modal,
    width: '95vw',
    maxHeight: '95vh',
    [theme.breakpoints.up('sm')]: {
      width: '95vw',
    },
  },
  modalFooter: {
    padding: '20px 0',
    textAlign: 'right',
  },
  table: {
    tableLayout: 'fixed',
  },
  tdShort: {
    width: '74px',
  },
  tableClickable: {
    cursor: 'pointer',
  },
  cancelIcon: {
    position: 'absolute',
    top: '10px',
    right: '20px',
    cursor: 'pointer',
    fill: '#d32f2f',
  },
});

function TaggingSearchModal({
  classes,
  modalOpen,
  onClose,
  websocket,
  buttonName,
  queryCallback,
}) {
  const [searchVal, setSearchVal] = useState('');
  const [searchSentence, setSearchSentence] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const getDataSearch = useCallback(() => {
    setLoading(true);
    if (websocket.current) {
      websocket.current.onmessage = e => {
        let data = JSON.parse(e.data);
        switch (data.type) {
          case 'search':
            setResults(data.message);
            setLoading(false);
            setSearchSentence(searchVal);
            setSearchVal('');
            break;
          default:
            break;
        }
      };
      websocket.current.send(
        JSON.stringify({
          type: 'search',
          message: { query: searchVal },
        })
      );
    }
  }, [searchVal, websocket]);

  const keyHandler = e => e.key === 'Enter' && getDataSearch();

  const selectHandler = useCallback(
    value => {
      const selectedItem = parseInt(value);

      if (selected.includes(selectedItem)) {
        const filtered = selected.filter(item => item !== selectedItem);
        setSelected(filtered);
      } else {
        setSelected(selected => [...selected, selectedItem]);
      }
    },
    [selected]
  );

  const selectAllToggle = useCallback(() => {
    results.length === selected.length
      ? setSelected([])
      : setSelected(results.map(item => item.idx));
  }, [results, selected]);

  const globalKeyPressHandler = useCallback(
    e => {
      if (e.ctrlKey && e.key === 'a') {
        e.preventDefault();
        selectAllToggle();
      }
    },
    [selectAllToggle]
  );

  useEffect(() => {
    document.addEventListener('keydown', globalKeyPressHandler);
    return () => {
      document.removeEventListener('keydown', globalKeyPressHandler);
    };
  }, [globalKeyPressHandler]);

  const save = useCallback(
    () => queryCallback(results.filter(item => selected.includes(item.idx))), // eslint-disable-next-line
    [results, selected]
  );

  const generateTable = useCallback(() => {
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.tdShort}>
              <Checkbox
                color="default"
                checked={results.length === selected.length}
                onChange={selectAllToggle}
              />
            </TableCell>
            <TableCell align="left">Text</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.length > 0 &&
            results.map(item => (
              <TableRow
                hover
                key={item.idx}
                className={classes.tableClickable}
                onClick={() => selectHandler(item.idx)}
              >
                <TableCell className={classes.tdShort}>
                  <Checkbox
                    color="default"
                    checked={selected.includes(item.idx)}
                    value={item.idx}
                  />
                </TableCell>
                <TableCell className={classes.tdShort}>{item.value}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    ); // eslint-disable-next-line
  }, [results, selected]);

  return (
    <Modal className={classes.modalSearch} open={modalOpen} onClose={onClose}>
      <Grid className={classes.modal}>
        <div onClick={onClose}>
          <CancelIcon className={classes.cancelIcon} />
        </div>
        <Typography variant="h6">Search the training dataset</Typography>
        <div className={classes.searchWrapper}>
          <TextField
            fullWidth
            autoFocus
            variant="outlined"
            className={classes.inputSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Divider orientation="vertical" />
                  <Button className={classes.btnSearch} onClick={getDataSearch}>
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
            }}
            value={searchVal}
            onKeyUp={keyHandler}
            onChange={e => setSearchVal(e.target.value)}
          />
        </div>
        <Typography component="p">
          {searchSentence && (
            <>
              Results for: <b>{searchSentence}</b>
            </>
          )}
        </Typography>
        <div className={classes.searchResults}>
          {isLoading && <Loader size={20} />}
          {!isLoading && results.length === 0 && <NoData />}
          {results.length > 0 && generateTable()}
        </div>
        <div className={classes.modalFooter}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={save}
          >
            {buttonName}
          </Button>
        </div>
      </Grid>
    </Modal>
  );
}

export default withStyles(styles)(TaggingSearchModal);

import React, { useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import CommonStyles from '../../utils/CommonStyles';
import { ApiGet } from '../../Api';
import { Link } from 'react-router-dom';
import Loader from '../common/Loader';
import moment from 'moment';
import {
  CheckCircleOutline,
  ErrorOutline,
  ArrowForward,
} from '@material-ui/icons';
import NoData from '../common/NoData';
import Pagination from '../common/Pagination';
import DOMPurify from 'dompurify';

const styles = theme => ({
  ...CommonStyles(theme),
  root: {
    ...CommonStyles(theme).root,
    display: 'flex',
    padding: '20px',
  },
});

const PAGINATION = 10;

const truncateString = (string, length) => {
  if (string.length < length) {
    return string;
  }
  return string.substring(0, length) + '...';
};

const syntaxHighlight = json => {
  if (typeof json != 'string') {
    json = JSON.stringify(json, undefined, '\t');
  }
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function (match) {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    }
  );
};

const DataSearchHistory = ({ classes }) => {
  const queryString = require('qs');
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState(undefined);
  const [order, setOrder] = useState('asc');
  const [allRows, setAllRows] = useState(0);
  const [expanded, setExpanded] = useState({});

  const changePage = page => {
    setPage(page);
  };

  const handleSort = columnId => {
    setPage(0);
    setOrder(orderBy === columnId && order === 'asc' ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const getData = () => {
    setLoading(true);
    ApiGet('/api/datasearch/history/', {
      limit: PAGINATION,
      offset: page * PAGINATION,
      ordering: `${order === 'desc' ? '-' : ''}${orderBy}`,
    }).then(response => {
      setData(response.results);
      setAllRows(response.count);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, [page, order, orderBy]); //eslint-disable-line

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          ></Grid>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      key={'query'}
                      sortDirection={orderBy === 'query' ? order : false}
                      align={'left'}
                      style={{ width: '15%' }}
                    >
                      <TableSortLabel
                        active={orderBy === 'query'}
                        direction={order}
                        onClick={() => handleSort('query')}
                      >
                        <span style={{ fontWeight: 'bold' }}>Query</span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      key={'dateFrom'}
                      align={'left'}
                      style={{ width: '5%' }}
                    >
                      <span style={{ fontWeight: 'bold' }}>Date From</span>
                    </TableCell>
                    <TableCell
                      key={'dateTo'}
                      align={'left'}
                      style={{ width: '5%' }}
                    >
                      <span style={{ fontWeight: 'bold' }}>Date To</span>
                    </TableCell>
                    <TableCell
                      key={'parameters'}
                      sortDirection={orderBy === 'parameters' ? order : false}
                      align={'left'}
                      style={{ width: '25%' }}
                    >
                      <TableSortLabel
                        active={orderBy === 'parameters'}
                        direction={order}
                        onClick={() => handleSort('parameters')}
                      >
                        <span style={{ fontWeight: 'bold' }}>Parameters</span>
                      </TableSortLabel>
                    </TableCell>

                    <TableCell
                      key={'successful'}
                      sortDirection={orderBy === 'successful' ? order : false}
                      align={'right'}
                      style={{ width: '2%' }}
                    >
                      <TableSortLabel
                        active={orderBy === 'successful'}
                        direction={order}
                        onClick={() => handleSort('successful')}
                      >
                        <span style={{ fontWeight: 'bold' }}>Status</span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      key={'user'}
                      sortDirection={orderBy === 'user' ? order : false}
                      align={'right'}
                      style={{ width: '10%' }}
                    >
                      <TableSortLabel
                        active={orderBy === 'user'}
                        direction={order}
                        onClick={() => handleSort('user')}
                      >
                        <span style={{ fontWeight: 'bold' }}>User</span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      key={'datetime'}
                      sortDirection={orderBy === 'datetime' ? order : false}
                      align={'right'}
                      style={{ width: '15%' }}
                    >
                      <TableSortLabel
                        active={orderBy === 'datetime'}
                        direction={order}
                        onClick={() => handleSort('datetime')}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          Execution Date
                        </span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right" style={{ width: '2%' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data ? (
                    data.map(row => (
                      <TableRow
                        hover
                        key={row.id}
                        className={classes.tableRow}
                        onClick={() =>
                          setExpanded(prev => {
                            return { ...prev, [row.id]: !prev[row.id] };
                          })
                        }
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          key={'query-' + row.id}
                          align="left"
                          style={{ wordBreak: 'break-word' }}
                        >
                          {expanded[row.id]
                            ? row['query']
                            : truncateString(row['query'], 20)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          key={'dateFrom-' + row.id}
                          align="left"
                        >
                          {moment(row['parameters'].date_from).format(
                            'MM/DD/YYYY'
                          )}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          key={'dateTo-' + row.id}
                          align="left"
                        >
                          {moment(row['parameters'].date_to).format(
                            'MM/DD/YYYY'
                          )}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          key={'parameters-' + row.id}
                          align="left"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                expanded[row.id]
                                  ? syntaxHighlight(row['parameters'])
                                  : truncateString(
                                      JSON.stringify(row['parameters'], 1, 2),
                                      50
                                    )
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          key={'successful-' + row.id}
                          align="right"
                          style={{ wordBreak: 'break-word' }}
                        >
                          {row['successful'] ? (
                            <CheckCircleOutline style={{ color: 'green' }} />
                          ) : (
                            <ErrorOutline style={{ color: 'red' }} />
                          )}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          key={'user-' + row.id}
                          align="right"
                          style={{ wordBreak: 'break-word' }}
                        >
                          {row['user'].first_name + ' ' + row['user'].last_name}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          key={'datetime-' + row.id}
                          align="right"
                          style={{ wordBreak: 'break-word' }}
                        >
                          {moment(row['datetime']).format(
                            'MMM Do YYYY h:mm:ss A'
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Tooltip title="Go to Data Search" aria-label="fork">
                            <IconButton
                              component={Link}
                              onClick={e => e.stopPropagation()}
                              to={{
                                pathname: `/data-search`,
                                search: `?${queryString.stringify({
                                  query: row['query'],
                                  ...row['parameters'],
                                  date_to: moment(
                                    row['parameters'].date_to
                                  ).format('YYYY-MM-DD'),
                                  date_from: moment(
                                    row['parameters'].date_from
                                  ).format('YYYY-MM-DD'),
                                })}`,
                              }}
                              color="primary"
                              size="small"
                            >
                              <ArrowForward />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <NoData />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={5}
                      classes={{ root: classes.pagination }}
                      count={allRows}
                      rowsPerPage={PAGINATION}
                      rowsPerPageOptions={[]}
                      page={page}
                      onPageChange={newPage => changePage(newPage)}
                      ActionsComponent={Pagination}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(DataSearchHistory);

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import {
  Paper,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { ApiGet } from '../Api';
import Pagination from './common/Pagination';
import moment from 'moment';
import CommonStyles from '../utils/CommonStyles';
import Loader from './common/Loader';
import NoData from './common/NoData';

const PAGINATION = 10;

const styles = theme => ({
  ...CommonStyles(theme),
  root: {
    ...CommonStyles(theme).root,
    display: 'flex',
    padding: '20px',
  },
  templatesCheckbox: {
    marginLeft: 'unset',
  },
});

class AllProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      learnMoredialog: false,
      getStartedDialog: false,
      projects: null,
      loading: true,
      page: 0,
      maxPage: 0,
      allRows: 0,
      templatesOnly: props.templatesOnly,
    };
  }

  componentDidMount() {
    ApiGet('/users/me').then(profile => {
      if (profile.is_staff) this.loadProjects();
      else this.setState({ redirect: true });
    });
  }

  loadProjects() {
    this.setState({ loading: true });
    ApiGet('/api/projects/all-projectworkflows', {
      limit: PAGINATION,
      offset: this.state.page * PAGINATION,
      template: this.state.templatesOnly ? true : '',
    }).then(
      response =>
        response &&
        this.setState(
          {
            projects: response.results,
            allRows: response.count,
            maxPage: Math.floor(response.count / PAGINATION),
          },
          () => this.setState({ loading: false })
        )
    );
  }

  changePage(page) {
    this.setState({ page: page }, this.loadProjects.bind(this));
  }

  cells = [
    {
      id: 'name',
      label: 'Name',
      date: false,
      width: '15%',
      align: 'left',
    },
    {
      id: 'description',
      label: 'Description',
      date: false,
      width: '20%',
      align: 'left',
    },
    {
      id: 'user',
      label: 'User',
      date: false,
      width: '10%',
      align: 'left',
    },
    {
      id: 'created_at',
      label: 'Created',
      date: true,
      width: '15%',
      align: 'right',
    },
    {
      id: 'updated_at',
      label: 'Updated',
      date: true,
      width: '15%',
      align: 'right',
    },
  ];

  render() {
    const { classes } = this.props;
    if (this.state.redirect) return <Redirect to={{ pathname: '/error' }} />;
    return (
      <Grid
        container
        spacing={2}
        justifyContent="center"
        className={classes.root}
      >
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  style={{ textTransform: 'uppercase' }}
                  color="secondary"
                >
                  Projects
                </Typography>
              </Grid>
            </Grid>
            {this.state.loading ? (
              <Loader />
            ) : (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      {this.cells.map(item => (
                        <TableCell
                          key={item.id}
                          align={item.align}
                          style={{ width: item.width }}
                        >
                          <span style={{ fontWeight: 'bold' }}>
                            {item.label}
                          </span>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.projects.length ? (
                      this.state.projects.map(row => (
                        <TableRow
                          hover
                          key={row.id}
                          className={classes.tableRow}
                          component={Link}
                          to={{
                            pathname: `/project/${row.project_id}/project-workflow/${row.id}`,
                            state: { from: 'internal' },
                          }}
                        >
                          {this.cells.map((item, idx) => (
                            <TableCell
                              component="td"
                              scope="row"
                              key={idx}
                              align={item.align}
                              style={{ wordBreak: 'break-word' }}
                            >
                              {item.date
                                ? moment(row[item.id]).format(
                                    'MMM Do YYYY h:mm:ss A'
                                  )
                                : row[item.id]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <NoData />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        colSpan={5}
                        classes={{ root: classes.pagination }}
                        count={this.state.allRows}
                        rowsPerPage={PAGINATION}
                        rowsPerPageOptions={[]}
                        page={this.state.page}
                        onPageChange={newPage => this.changePage(newPage)}
                        ActionsComponent={Pagination}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(AllProjects));

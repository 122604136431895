import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography, IconButton } from '@material-ui/core';
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import ArchiveIcon from '@material-ui/icons/Archive';
import { withStyles } from '@material-ui/core/styles';
import { ApiDelete, ApiGet, ApiPost, ApiPut, ApiPatch } from '../../../Api';
import { NotificationContext } from '../../../NotificationContext';
import { ProjectContext } from '../ProjectContext';
import CommonStyles from '../../../utils/CommonStyles';
import Loader from '../../common/Loader';
import ProjectProperties from './ProjectProperties';
import Tooltip from '../../common/Tooltip';

const styles = theme => ({
  ...CommonStyles(theme),
  projectContainer: {
    padding: '10px',
  },
  titleContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  favouriteIcon: {
    marginLeft: 0,
  },
  modalGrid: {
    width: '100% !important',
    maxWidth: '100% !important',
    flexBasis: '100% !important',
  },
});

const EditProject = ({ classes, match, isModal = false, reloadData }) => {
  const { notification } = useContext(NotificationContext);
  const [project, setProject] = useState(undefined);
  const [isFavourite, setIsFavourite] = useState(undefined);
  const [disableFavouriteButton, setDisableFavouriteButton] = useState(false);
  const projectContext = useContext(ProjectContext);

  const history = useHistory();

  const projectId = match.hasOwnProperty('params')
    ? match.params.project_id
    : match;

  useEffect(() => {
    ApiGet(`/api/projects/projects/${projectId}`).then(res => {
      setProject({
        ...res,
        project_groups: res.project_groups.map(x => x.id),
      });
      setIsFavourite(res.is_favourite);
    });
  }, [projectId]);

  const onSubmit = data => {
    ApiPut(`/api/projects/projects/${projectId}`, data).then(res => {
      if (res && res.status === 'ok') {
        notification({
          type: 'success',
          message: 'Successfully saved changes',
        });

        !isModal && history.push(`/project/${projectId}`);
        isModal && reloadData({ reloadData: true });
        projectContext.actions.reloadProjectList(true);
      } else {
        notification({
          type: 'error',
          message: 'There was some problem when saving changes to the project',
        });
      }
    });
  };

  const handleFavourite = () => {
    setDisableFavouriteButton(true);
    if (isFavourite) {
      ApiDelete(`/users/favourite-projects/delete/${projectId}`).then(res => {
        setIsFavourite(res.favourite_projects.includes(Number(projectId)));
      });
    } else {
      ApiPost(`/users/favourite-projects/add/${projectId}`).then(res => {
        setIsFavourite(res.favourite_projects.includes(Number(projectId)));
      });
    }
    setDisableFavouriteButton(false);
  };

  const handleArchive = () => {
    ApiPatch(`/api/projects/projects/${projectId}`, {
      archived: true,
    }).then(res => {
      if (res.status === 'ok') {
        if (!isModal) {
          projectContext.actions.reloadProjectList(true);
          history.push(`/project/`);
        }
        isModal && reloadData({ reloadData: true });
      }
    });
  };

  return (
    <Grid
      container
      spacing={2}
      component={Paper}
      elevation={isModal ? 0 : 1}
      className={classes.projectContainer}
    >
      <Grid
        className={classNames({ [classes.modalGrid]: isModal })}
        item
        xs={12}
        xl={6}
      >
        <Grid className={classes.titleContainer}>
          <Typography variant="h5">Edit Project</Typography>
          <div>
            <Tooltip
              title={isFavourite ? 'remove from favourite' : 'add to favourite'}
            >
              <IconButton
                className={classes.favouriteIcon}
                onClick={() => handleFavourite()}
                disabled={disableFavouriteButton}
              >
                {isFavourite ? <Favorite /> : <FavoriteBorder />}
              </IconButton>
            </Tooltip>
            {!isModal && (
              <Tooltip title="archive">
                <IconButton
                  className={classes.favouriteIcon}
                  onClick={() => handleArchive()}
                >
                  <ArchiveIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Grid>

        {project ? (
          <ProjectProperties
            project={project}
            setProject={setProject}
            onSubmit={onSubmit}
          />
        ) : (
          <Loader />
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(EditProject);

EditProject.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
  isModal: PropTypes.bool,
};

import React, { useState, useEffect, useRef, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  Button,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ApiGet, ApiPatch, ApiPost } from '../../Api';
import { ProjectContext } from '../Project/ProjectContext';
import CommonStyles from '../../utils/CommonStyles';
import DataDictionaryTable from './Modal/DataDictionaryTable';
import Datasets from './Datasets';
import ForkProjectWorkflow from './Modal/ForkProjectWorkflow';
import Loader from '../common/Loader';
import Notifications from '../common/Notifications';
import ProjectToolbar from '../Project/ProjectToolbar';
import Selector from '../common/Selector';
import WidgetDragAndDrop from './Widget/WidgetDragAndDrop';
import WidgetFilter from './Widget/WidgetFilter';
import WidgetInput from '../WidgetInput/WidgetInput';
import WidgetOptions from './Widget/WidgetOptions';
import WorkflowVersionPicker from './Modal/WorkflowVersionPicker';

const styles = theme => ({
  ...CommonStyles(theme),
  container: {
    display: 'flex',
    flex: '1',
  },
  paper: {
    ...CommonStyles(theme).paper,
  },
  configured: {
    borderRight: '5px red solid',
  },
  headRow: {
    marginBottom: '10px',
    width: '100%',
  },
  button: {
    width: '100%',
    color: 'white',
    background: theme.palette.primary.main,
    transition: theme.transitions.create('background', { duration: '0.5s' }),
    '&:disabled': {
      background: 'lightgray !important',
    },
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  tooltip: {
    fontSize: '1.2em',
  },
  optionsContainer: {
    height: '100%',
  },
  divider: {
    margin: '15px 16px',
  },
  sectionTitle: {
    fontWeight: 600,
    fontSize: '1.2em',
  },
  modalItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  modal: {
    ...CommonStyles(theme).modal,
    width: 'fit-content',
  },
  saveProgress: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
  },
  popperWrapper: {
    zIndex: 100,
  },
  popperItem: {
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  ddlIcon: {
    marginRight: '10px',
    maxWidth: '20px',
  },
});

const checkStepsValidity = steps => {
  return (
    steps.some(step => step.type === 'source') &&
    steps.some(step => step.type === 'export') &&
    steps.some(step => step.type === 'append')
  );
};

const copy = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);

  let field_mapping = {};
  let widget = sourceClone[droppableSource.index];
  if (widget.config.columns) {
    [widget.config.columns.required, widget.config.columns.optional].forEach(
      columns => {
        if (columns)
          for (let i = 0; i < columns.length; i += 1)
            field_mapping[columns[i]] = columns[i];
      }
    );
  }
  let config = {};
  widget.config.options.forEach(option => {
    if (option.default && !config[option.name]) {
      config[option.name] = option.default;
    }
  });

  destClone.splice(droppableDestination.index, 0, {
    ...sourceClone[droppableSource.index],
    config: { ...config, field_mapping },
    configErrors: {},
    mappingErrors: {},
    generalErrors: [],
  });

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  return result;
};

const sortWidgets = widgets => {
  let order = ['source', 'append', 'export'];
  widgets.sort((a, b) => {
    return Math.sign(order.indexOf(a.type) - order.indexOf(b.type));
  });
};

const PAGINATION = 5;

function ProjectWorkflow({ classes, match, fork }) {
  const [loading, setLoading] = useState(true);
  const [widgets, setWidgets] = useState(null);
  const [steps, setSteps] = useState(null);
  const [configuredStep, setConfiguredStep] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [allWidgets, setAllWidgets] = useState(null);
  const [project, setProject] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saveNeeded, setSaveNeeded] = useState(false);
  const [workflow, setWorkflow] = useState(null);
  const [loadingDatasets, setLoadingDatasets] = useState(true);
  const [datasetPage, setDatasetPage] = useState(0);
  const [datasets, setDatasets] = useState(null);
  const [datasetsCount, setDatasetsCount] = useState(0);
  const didMountRef = useRef(false);
  const history = useHistory();
  const projectContext = useContext(ProjectContext);
  const timezone = 'UTC';

  const getAllWidgets = () => {
    setLoading(true);
    setLoadingDatasets(true);
    ApiGet('/api/services/widgets/').then(responseWidgets => {
      sortWidgets(responseWidgets);
      setWidgets(Array.from(responseWidgets));
      setAllWidgets(Array.from(responseWidgets));
    });
  };

  useEffect(getAllWidgets, []);

  useEffect(() => {
    projectContext.actions.pageLoading(true);
    didMountRef.current && update();
  }, [allWidgets, match.url, fork]); // eslint-disable-line

  const update = () => {
    if (match.params.project_workflow_id === 'new') {
      setSteps([]);
      setProject({
        name: '',
        description: '',
        id: null,
      });
      setWorkflow({});
      setLoading(false);
      projectContext.actions.pageLoading(false);
    } else {
      ApiGet(
        `/api/projects/projects/${match.params.project_id}/project-workflows/${match.params.project_workflow_id}`
      )
        .then(responseProject => {
          setProject(responseProject);
          setWorkflow(responseProject.workflow);
          loadDatasets(responseProject);
          return responseProject.workflow;
        })
        .then(responseWorkflow => {
          setSteps(getWidgetsFromSteps(responseWorkflow.steps));
          setLoading(false);
          for (const key in responseWorkflow.steps) {
            if (responseWorkflow.steps.hasOwnProperty(key)) {
              mapErrorsToStates(
                responseWorkflow.steps[key].errors,
                getWidgetsFromSteps(responseWorkflow.steps)
              );
            }
          }
        });
    }
  };

  const closeNotificationTime = 5000;
  const notification = notification => {
    setNotifications(prevState => [...prevState, notification]);
    setTimeout(() => {
      closeNotification(notification);
    }, closeNotificationTime);
  };

  const closeNotification = item =>
    setNotifications(notifications.filter(value => value !== item));

  const changeStepName = (idx, name) => {
    let tempSteps = [...steps];
    tempSteps[idx].multi_input_name = name;
    setSteps(tempSteps);
  };

  const getFieldsFromWidget = () => {
    let widget_id = steps[configuredStep].id;
    let tempWidget = allWidgets.find(function (el) {
      return el.id === widget_id;
    });
    let widget_config = tempWidget.config;
    if (widget_config == null) return { options: [], field_mapping: [] };
    let options = [];
    let field_mapping = [];
    if (widget_config.options) options = widget_config.options;
    if (widget_config.columns) {
      if (widget_config.columns.required) {
        field_mapping = field_mapping.concat(widget_config.columns.required);
      }
      if (widget_config.columns.optional) {
        field_mapping = field_mapping.concat(widget_config.columns.optional);
      }
    }
    return {
      options: options,
      field_mapping: field_mapping,
      input_format: widget_config.input_format,
    };
  };

  const collectReturnFields = () => {
    let fields = [];
    for (let i = 0; i < configuredStep; i += 1) {
      let widget_id = steps[i].id;
      let tempWidget = allWidgets.find(el => el.id === widget_id);
      if (tempWidget.config.columns.returning)
        fields = fields.concat(
          tempWidget.config.columns.returning.map(item => {
            let re = /\[([a-zA-Z0-9_-]*)\]/g;
            item = `${item}`;

            Array.from(item.matchAll(re), m => m[1]).forEach(match => {
              let value =
                steps[i].config[match] || steps[i].config.field_mapping[match];
              item = item.replace(`[${match}]`, value);
            });
            return item;
          })
        );
    }
    return fields;
  };

  const getWidgetsFromSteps = tempSteps => {
    let newSteps = [];
    if (allWidgets) {
      for (let step in tempSteps) {
        let tempWidget = allWidgets.find(el => {
          return el.id === tempSteps[step].widget;
        });
        if (!tempWidget) continue;

        let stepObject = {
          ...tempWidget,
          configErrors: {},
          mappingErrors: {},
          generalErrors: [],
          config: tempSteps[step].config ? tempSteps[step].config : {},
          multi_input_name: tempSteps[step].multi_input_name
            ? tempSteps[step].multi_input_name
            : null,
        };

        if (!stepObject.config.field_mapping) {
          stepObject.config.field_mapping = {};
          if (tempWidget.config.columns) {
            [
              tempWidget.config.columns.required,
              tempWidget.config.columns.optional,
            ].forEach(columns => {
              if (columns)
                for (let i = 0; i < columns.length; i += 1)
                  stepObject.config.field_mapping[columns[i]] = columns[i];
            });
          }
        }
        tempWidget.config.options.forEach(option => {
          if (option.default && !stepObject.config[option.name]) {
            stepObject.config[option.name] = option.default;
          }
        });
        newSteps.push(stepObject);
      }
      sortWidgets(newSteps);
    }
    return newSteps;
  };

  const loadDatasets = tempProject => {
    tempProject = tempProject || project;
    ApiGet(`${tempProject.url}datasets/`, {
      ordering: '-id',
      limit: PAGINATION,
      offset: datasetPage * PAGINATION,
    }).then(response => {
      setDatasets(response.results);
      setDatasetsCount(response.count);
      setLoadingDatasets(false);
    });
    projectContext.actions.pageLoading(false);
  };

  useEffect(() => {
    didMountRef.current && loadDatasets();
  }, [datasetPage]); // eslint-disable-line

  const changeProject = (field, e) => {
    setProject({
      ...project,
      [field]: e.target.value,
    });
    setSaveNeeded(true);
  };

  const changeStepOption = (option, e) => {
    let tempSteps = [...steps];
    tempSteps[configuredStep] = {
      ...steps[configuredStep],
      config: {
        ...steps[configuredStep].config,
        [option]: e.target ? e.target.value : e,
      },
    };
    setSteps(tempSteps);
    setSaveNeeded(true);
  };

  const changeFieldMapping = (field, value) => {
    let tempSteps = [...steps];
    tempSteps[configuredStep].config.field_mapping[field] = value;
    setSteps(tempSteps);
    setSaveNeeded(true);
  };

  const changeFilterState = (field, value, index = 0) => {
    let tempSteps = [...steps];
    tempSteps[configuredStep] = {
      ...steps[configuredStep],
      config: {
        ...steps[configuredStep].config,
        filters: Object.assign([], steps[configuredStep].config.filters || [], {
          [index]: {
            ...((steps[configuredStep].config.filters || [])[index] || []),
            [field]: value,
          },
        }),
      },
    };
    setSteps(tempSteps);
    setSaveNeeded(true);
  };

  const mapErrorsToStates = (errors, passedSteps) => {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        let tempSteps = [...passedSteps];
        if (errors[key].length > 0) {
          errors[key].forEach(e => {
            if (key === 'config') {
              tempSteps[e.step].configErrors[e.field] = e.message;
            } else if (key === 'mapping') {
              tempSteps[e.step].mappingErrors[e.field] = e.message;
            } else {
              tempSteps[e.step].generalErrors.push(e.message);
            }
          });
        }
        setSteps(tempSteps);
      }
    }
  };

  const save = (template = false) => {
    if (saving) return;

    let tempSteps = steps.map(step => {
      step.configErrors = {};
      step.mappingErrors = {};
      step.generalErrors = [];
      return step;
    });

    setSteps(tempSteps);
    setNotifications([]);

    notification({
      type: 'warning',
      message: 'Saving...',
    });
    let projectWorkflows = new Promise(resolve => {
      setSaving(true);
      resolve();
    });
    if (project.id === null) {
      projectWorkflows = ApiPost(
        `/api/projects/projects/${match.params.project_id}/project-workflows/`,
        {
          name: project.name,
          description: project.description,
          template: template,
        }
      ).then(responseProject => {
        setProject(responseProject);
        return responseProject;
      });
    }
    projectWorkflows.then(responseProject => {
      ApiPost(
        `${responseProject ? responseProject.url : project.url}workflows/`,
        {
          order: workflow.order ? workflow.order + 1 : 0,
        }
      ).then(responseWorkflow => {
        const steps = Promise.all(
          tempSteps.map(
            async (item, index) =>
              await ApiPost(`${responseWorkflow.url}steps/`, {
                widget: item.id,
                order: index,
                config: item.config,
                multi_input_name: item.multi_input_name
                  ? item.multi_input_name
                  : null,
              })
          )
        );
        steps.then(() => {
          ApiPatch(responseProject ? responseProject.url : project.url, {
            name: project.name,
            description: project.description,
            workflow: responseWorkflow.url,
            template: template,
          }).then(responseProject => {
            notification({
              type: 'success',
              message: 'Saved',
            });
            notification({
              type: 'warning',
              message: 'Validating...',
            });
            ApiGet(`/api/projects/validate/${responseProject.id}`).then(
              response => {
                let errorsLength = 0;
                for (const key in response.errors) {
                  if (response.errors.hasOwnProperty(key)) {
                    errorsLength += response.errors[key].length;
                  }
                }
                if (errorsLength > 0) {
                  notification({
                    type: 'error',
                    message: `Project has ${errorsLength} error(s)`,
                  });
                } else {
                  notification({
                    type: 'success',
                    message: 'Project valid',
                  });
                }
                mapErrorsToStates(response.errors, tempSteps);
                setSaving(false);
                setSaveNeeded(false);
                const newProjectUrl = `/project/${responseProject.project_id}/project-workflow/${responseProject.id}/`;
                match.params.project_workflow_id === 'new' &&
                  history.push(newProjectUrl);
              }
            );
            projectContext.actions.reloadProjectList(true);
          });
        });
      });
    });
  };

  const changeWorkflowVersion = newWorkflowUrl => {
    setLoading(true);
    ApiPatch(project.url, { new_workflow: newWorkflowUrl }).then(r => {
      notification({
        type: 'success',
        message: 'Version changed',
      });
      update();
    });
  };

  const [widgetConfig, setWidgetConfig] = useState(null);
  const [fieldMapping, setFieldMapping] = useState(null);
  const [filters, setFilters] = useState(null);
  const [previousReturnFields, setPreviousReturnFields] = useState(null);

  useEffect(() => {
    if (configuredStep !== null) {
      const fields = getFieldsFromWidget();
      setWidgetConfig(fields);
      setFieldMapping(fields.field_mapping);
      setFilters(
        steps[configuredStep].config.filters
          ? steps[configuredStep].config.filters
          : null
      );
      setPreviousReturnFields(collectReturnFields());
    }
  }, [configuredStep, steps]); // eslint-disable-line

  useEffect(() => {
    didMountRef.current = true;
  }, []); // eslint-disable-line

  const saveButtons = () => {
    let disabled = true;
    let error = null;

    if (project.template) {
      error = 'This is a read only template';
    } else if (!(project.name && project.description)) {
      error = 'Name and description are required';
    } else if (!checkStepsValidity(steps)) {
      error = 'Steps are invalid';
    } else {
      disabled = false;
    }

    const errorTooltip = child => (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={error}
        placement="top"
      >
        <div>{child}</div>
      </Tooltip>
    );

    const saveButton = (template = false) => (
      <Button
        disabled={disabled || saving}
        className={classes.button}
        onClick={() => save(template)}
      >
        {template
          ? ''
          : saving && (
              <LinearProgress className={classNames(classes.saveProgress)} />
            )}
        {template ? 'save as template' : 'save'}
      </Button>
    );

    return (
      <>
        {error ? errorTooltip(saveButton()) : saveButton()}
        <Divider className={classes.divider} />
        {error ? errorTooltip(saveButton(true)) : saveButton(true)}
      </>
    );
  };

  const [errors, setError] = useState({ name: false, description: false });
  const errorHandler = (name, value) => {
    value
      ? setError({ ...errors, [name]: false })
      : setError({ ...errors, [name]: true });
  };

  return (
    <>
      <Notifications items={notifications} handleClose={closeNotification} />
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
      >
        <Grid item component={Paper} className={classes.paper}>
          {loading || projectContext.store.pageLoading ? (
            <Loader />
          ) : (
            <Grid container direction="column">
              <ProjectToolbar project={project}>
                <DataDictionaryTable
                  projectId={project.id}
                  disabled={!project.id}
                />
                <ForkProjectWorkflow />
                {project.id && project.active_workflow ? (
                  <WorkflowVersionPicker
                    projectId={project.id}
                    workflows={project.workflow_versions}
                    current={project.active_workflow.id}
                    onChange={changeWorkflowVersion}
                  />
                ) : (
                  <WorkflowVersionPicker disabled />
                )}
              </ProjectToolbar>

              <Grid container spacing={5} className={classes.marginBot}>
                <Grid item xs={8}>
                  <WidgetDragAndDrop
                    projectId={project.id}
                    widgets={widgets}
                    changeStepName={changeStepName}
                    configuredStep={configuredStep}
                    setConfiguredStep={setConfiguredStep}
                    setSteps={setSteps}
                    steps={steps}
                    copy={copy}
                    sortWidgets={sortWidgets}
                    saveNeeded={setSaveNeeded}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    className={classes.optionsContainer}
                  >
                    <Grid
                      item
                      style={{
                        display: 'grid',
                        maxHeight: '75vh',
                        overflow: 'auto',
                      }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography className={classes.sectionTitle}>
                            Workflow options
                          </Typography>
                        </Grid>
                      </Grid>
                      <TextField
                        label="Project name*"
                        value={project && project.name}
                        onChange={e => changeProject('name', e)}
                        className={classes.inputField}
                        name="name"
                        error={errors.name}
                        onBlur={e =>
                          errorHandler(e.target.name, e.target.value)
                        }
                      />
                      <TextField
                        label="Project description*"
                        value={project && project.description}
                        onChange={e => changeProject('description', e)}
                        multiline={true}
                        className={classes.inputField}
                        name="description"
                        error={errors.description}
                        onBlur={e =>
                          errorHandler(e.target.name, e.target.value)
                        }
                      />
                      {(configuredStep || configuredStep === 0) && (
                        <>
                          {widgetConfig && widgetConfig.options && (
                            <div>
                              <Divider className={classes.divider} />
                              <Typography className={classes.sectionTitle}>
                                {
                                  allWidgets.find(
                                    item => item.id === steps[configuredStep].id
                                  ).name
                                }
                                {steps[configuredStep].multi_input_name
                                  ? ` [${steps[configuredStep].multi_input_name}] `
                                  : ' '}
                                widget options
                              </Typography>
                              <Grid item>
                                {steps[configuredStep].generalErrors.map(e => {
                                  return (
                                    <Typography color="error">{e}</Typography>
                                  );
                                })}
                              </Grid>
                              <WidgetInput
                                value={steps[configuredStep].config.input}
                                notification={notification}
                                onChange={e => changeStepOption('input', e)}
                                format={widgetConfig.input_format}
                                toggleUploading={setSaving}
                              />
                            </div>
                          )}
                          {widgetConfig && (
                            <>
                              {widgetConfig.options.map((field, fieldIdx) => {
                                const options = steps[configuredStep].config;
                                let error =
                                  steps[configuredStep].configErrors[
                                    field.name
                                  ];

                                return (
                                  <WidgetOptions
                                    key={fieldIdx}
                                    options={options}
                                    field={field}
                                    error={error}
                                    changeStepOption={changeStepOption}
                                  />
                                );
                              })}
                            </>
                          )}
                          {fieldMapping && fieldMapping.length > 0 && (
                            <>
                              <Divider className={classes.divider} />
                              <Typography className={classes.sectionTitle}>
                                Column mappings
                              </Typography>
                              {fieldMapping.map((field, fieldIdx) => (
                                <Selector
                                  key={fieldIdx}
                                  id={fieldIdx}
                                  field={field}
                                  value={
                                    steps[configuredStep].config.field_mapping[
                                      field
                                    ]
                                  }
                                  onChange={changeFieldMapping}
                                  choices={previousReturnFields}
                                  error={
                                    steps[configuredStep].mappingErrors[field]
                                  }
                                  helperText={
                                    steps[configuredStep].mappingErrors[field]
                                  }
                                />
                              ))}
                            </>
                          )}
                          {steps[configuredStep].type !== 'source' && (
                            <Grid>
                              <Divider className={classes.divider} />
                              <WidgetFilter
                                filters={filters}
                                changeFilterState={changeFilterState}
                                previousReturnFields={previousReturnFields}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid>{saveButtons()}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {project && project.id && !projectContext.store.pageLoading && (
          <Grid
            item
            component={Paper}
            className={classes.paper}
            style={{ marginTop: '15px' }}
          >
            {loadingDatasets ? (
              <Loader />
            ) : (
              <Datasets
                changeDatasetPage={setDatasetPage}
                datasets={datasets}
                datasetsCount={datasetsCount}
                projectWorkflowId={project.id}
                datasetPage={datasetPage}
                timezone={timezone}
                disabled={saveNeeded || saving}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default withRouter(withStyles(styles)(ProjectWorkflow));

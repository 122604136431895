import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import {
  Paper,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import { ApiGet } from '../Api';
import CommonStyles from '../utils/CommonStyles';
import Loader from './common/Loader';
import NoData from './common/NoData';

const styles = theme => ({
  ...CommonStyles(theme),
});

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      learnMoredialog: false,
      getStartedDialog: false,
      recentProjects: null,
      activityProjects: null,
      loadingRecent: true,
      loadingActivity: true,
    };
  }

  componentDidMount() {
    ApiGet('/api/projects/projects/', {
      ordering: '-created_at',
      limit: 25,
    }).then(response => {
      this.setState({
        recentProjects: response.results,
        loadingRecent: false,
      });
    });
    ApiGet('/users/favourite-projects/', {
      ordering: '-updated_at',
      limit: 25,
    }).then(response => {
      this.setState({
        activityProjects: response.favourite_projects,
        loadingActivity: false,
      });
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        justifyContent="center"
        spacing={2}
        className={classes.root}
        style={{ padding: '24px' }}
      >
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography
              style={{ textTransform: 'uppercase' }}
              color="secondary"
              gutterBottom
            >
              Recent projects
            </Typography>
            {this.state.loadingRecent ? (
              <Loader />
            ) : (
              <Table className={classes.marginBot}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Created at</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.recentProjects.length ? (
                    this.state.recentProjects.map(row => (
                      <TableRow
                        hover
                        key={row.id}
                        component={Link}
                        to={{
                          pathname: `/project/${row.id}`,
                          state: { from: 'internal' },
                        }}
                        className={classes.tableRow}
                      >
                        <TableCell scope="row">{row.name}</TableCell>
                        <TableCell align="right">
                          {moment(row.created_at).format('MMM Do YYYY h:mm A')}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <NoData />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography
              style={{ textTransform: 'uppercase' }}
              color="secondary"
              gutterBottom
            >
              Favourite projects
            </Typography>
            {this.state.loadingActivity ? (
              <Loader />
            ) : (
              <Table className={classes.marginBot}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Updated at</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.activityProjects &&
                  this.state.activityProjects.length ? (
                    this.state.activityProjects.map(row => (
                      <TableRow
                        hover
                        key={row.id}
                        component={Link}
                        to={{
                          pathname: `/project/${row.id}`,
                          state: { from: 'internal' },
                        }}
                        className={classes.tableRow}
                      >
                        <TableCell scope="row">{row.name}</TableCell>
                        <TableCell align="right">
                          {moment(row.updated_at).format('MMM Do YYYY h:mm A')}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <NoData />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(Main));

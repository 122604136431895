import React, { useState, useEffect, useCallback } from "react";
import {
  List,
  ListItem,
  Collapse,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import CommonStyles from "../../utils/CommonStyles";

const checkBox = (name, value, handler) => (
  <ListItem key={name}>
    <FormControlLabel
      key={name}
      control={
        <Checkbox key={name} checked={value} onChange={handler} name={name} />
      }
      label={name.split(/(?=[A-Z])/).join(" ")}
    />
  </ListItem>
);

const styles = (theme) => ({
  ...CommonStyles(theme),
});

function CheckboxList({ classes, onChange, name, checkboxObject }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(checkboxObject);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleCheckbox = (field, value) => {
    setData({
      ...data,
      options: {
        ...data.options,
        [field]: !value,
      },
    });
  };

  const handleOther = (field, value) => {
    setData({
      ...data,
      [field]: !value,
    });
  };

  const checkIfAllSelected = useCallback(() => {
    return (Object.values(data.options).every((v) => v) && data.allOther);
  }, [data.options, data.allOther]);

  useEffect(() => {
    //mutate directly to prevent infinite re-renders
    data.selectAll = checkIfAllSelected()
    onChange(data);
  }, [data, checkIfAllSelected, onChange]);


  const handelSelectAll = () => {
    setData({
      ...data,
      selectAll: !data.selectAll,
      allOther: !data.selectAll,
    });
    Object.keys(data.options).forEach(function (key) {
      data.options[key] = !data.selectAll;
    });
  };

  return (
    <List>
      <ListItem button onClick={handleClick}>
        <Typography style={{ width: "100%" }}>{name}</Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {checkBox("Select All", data.selectAll, handelSelectAll)}
        {Object.keys(data.options).map((key, value) => {
          return checkBox(key, data.options[key], () =>
            handleCheckbox(key, data.options[key])
          );
        })}
        {checkBox("All Other", data.allOther, () =>
          handleOther("allOther", data.allOther)
        )}
      </Collapse>
    </List>
  );
}

export default withRouter(withStyles(styles)(CheckboxList));
import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { ApiGet } from '../Api';

const styles = theme => ({
  footer: {
    position: 'absolute',
    bottom: '2px',
    right: '45px',
    fontSize: '10px',
    zIndex: '0',
    opacity: '.5',
  },
});

const Footer = ({ classes }) => {
  const [version, setVersion] = useState(false);

  useEffect(() => {
    ApiGet('/system').then(res => res && setVersion(res.version));
  }, []);
  return (
    <div className={classes.footer}>{version && <>ver.: {version}</>}</div>
  );
};

export default withStyles(styles)(Footer);

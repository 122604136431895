import React, {useState} from "react";
import CommonStyles from "../../utils/CommonStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import {Person} from "@material-ui/icons";
import ProjectUsersModal from "./ProjectUsersModal";

const styles = theme => ({
  ...CommonStyles(theme),
});

const ProjectUsers = ({classes, projectId, disabled}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        variant={"outlined"}
        onClick={() => setModalOpen(true)}
        disabled={disabled}
        startIcon={<Person/>}
      >
        Users
      </Button>
      {modalOpen &&
      <ProjectUsersModal
        project_id={projectId}
        onClose={() => setModalOpen(false)}
        modalOpen={modalOpen}
      />
      }
    </>
  );
};

export default withStyles(styles)(ProjectUsers)
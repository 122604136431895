import React, { useState } from 'react';
import CommonStyles from '../../utils/CommonStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import ProjectListModal from '../common/ProjectListModal';

const styles = theme => ({
  ...CommonStyles(theme),
  exportButton: {
    margin: '14px',
  },
});

const ExportDataSearch = ({
  classes,
  setProjectId,
  projectId,
  exportToNewProject,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        className={classes.exportButton}
        variant="contained"
        color="primary"
        onClick={() => setModalOpen(true)}
      >
        export to new project
      </Button>
      {modalOpen && (
        <ProjectListModal
          setProjectId={setProjectId}
          projectId={projectId}
          handleClick={exportToNewProject}
          onClose={() => setModalOpen(false)}
          modalOpen={modalOpen}
          buttonName="export"
        />
      )}
    </>
  );
};

export default withStyles(styles)(ExportDataSearch);

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { TableCell, TableHead, Typography } from '@material-ui/core';
import CommonStyles from '../../utils/CommonStyles';
import { ApiPost } from '../../Api';
import Loader from '../common/Loader';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

const styles = theme => ({
  ...CommonStyles(theme),
});

const DataPreview = ({
  s3Path,
  dataTextColumn,
  dataLabelColumn,
  dataHeadCallback,
  errorsCallback,
}) => {
  const [loading, setLoading] = useState(false);
  const [dataColumns, setDataColumns] = useState([]);
  const [dataHead, setDataHead] = useState([]);
  const [dataError, setDataError] = useState(null);

  const getData = () => {
    setLoading(true);
    setDataColumns([]);
    setDataHead([]);
    setDataError(null);

    dataLabelColumn =
      dataLabelColumn && typeof dataLabelColumn === 'object'
        ? dataLabelColumn.join('\n')
        : dataLabelColumn;

    ApiPost('/api/activelearning/validate_data_file/', {
      s3_path: s3Path,
      text_column: dataTextColumn,
      label_column: dataLabelColumn,
    }).then(response => {
      if (!response) {
        setDataError('Internal server error');
        dataTextColumn = null;
        dataLabelColumn = null;
        setLoading(false);
        getData();
        return false;
      }

      if (response.errors) {
        errorsCallback(response.errors);
      }

      if (response.error) {
        setDataError(response.error);
      } else {
        setDataHead(response.head);
        setDataColumns(response.columns);
      }

      setLoading(false);
    });
  };

  useEffect(() => {
    getData(); // eslint-disable-next-line
  }, [s3Path]);

  useEffect(
    () => dataHeadCallback(dataColumns),
    [dataHeadCallback, dataColumns]
  );

  return loading ? (
    <Loader />
  ) : (
    <Grid item style={{ overflow: 'auto', maxWidth: '100%' }}>
      {dataError ? (
        dataError
      ) : (
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              {dataColumns.map((column, i) => (
                <TableCell key={i} style={{ textAlign: 'center' }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {column}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataHead.map((row, rowIdx) => (
              <TableRow key={`r${rowIdx}`}>
                {dataColumns.map((cell, cellIdx) => (
                  <TableCell key={`c${cellIdx}`}>{row[cell]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Grid>
  );
};

export default withRouter(withStyles(styles)(DataPreview));
